import { useContext, useEffect, useState } from 'react';
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import toastr from 'toastr';
import { AuthContext } from '../../../context/JWTContext';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { instance } from '../../../events/event-objects';
import { Controller, useForm } from 'react-hook-form';
import { TransactionLimit, TransactionLimitInput } from '../../../@types/transaction-limits';
import { fetchTransactionLimitsByProductId, updateTransactionLimits } from '../../../api';
import { useParams } from 'react-router-dom';
import Spinner from '../../../components/Spinner';
import { errorMessageTextStyleOverride } from '../../../@types/errorMessages';
import { messageService } from '../../../events/create.events';

const schema = yup
  .object({
    product_id: yup.number().required('Display Name is required'),
    max_card_value: yup.string().required('Display Name is required'),
    min_amount_value: yup.string().required('Display Name is required'),
    max_cash_value: yup.string().required('Display Name is required'),
    effective_start: yup.date().required('Display Name is required'),
    effective_end: yup.date().required('Display Name is required'),
  })
  .required();

const TransactionLimitsStep = () => {
  const { id } = useParams();
  const userContext = useContext(AuthContext);

  const [data, setData] = useState<TransactionLimit>();
  const [effectiveStartDate, setEffectiveStartDate] = useState<Date>();
  const [effectiveEndDate, setEffectiveEndDate] = useState<Date>();
  const [saving, setSaving] = useState<boolean>(false);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = useForm<TransactionLimitInput>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      product_id: +id!,
    },
  });

  useEffect(() => {
    console.log(errors);
  }, [errors]);

  useEffect(() => {
    if (id) {
      fetchTransactionLimitsByProductId(+id)
        .then((transaction_limit) => {
          // console.log(product_fees);

          setData(transaction_limit);
          setEffectiveStartDate(new Date(transaction_limit.effective_start!));
          setEffectiveEndDate(new Date(transaction_limit.effective_end!));
        })
        .catch((error) => console.log(error));
    }
  }, [id]);

  const backEvent = () => {
    const event = {
      key: 'back',
    };

    instance().back(event);
  };

  const nextEvent = () => {
    const event = {
      key: 'next',
    };

    instance().next(event);
  };

  toastr.options = {
    closeButton: false,
    debug: false,
    newestOnTop: false,
    progressBar: true,
    positionClass: 'toast-top-left',
    preventDuplicates: false,
    toastClass: 'success',
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
  };

  const onSubmit = (formData: TransactionLimitInput) => {
    if (!isDirty) {
      nextEvent();
      return;
    }

    if (formData && userContext?.user?.email) {
      formData = { ...data, ...formData };
      formData.id = data!.id;
      formData.effective_start = effectiveStartDate!;
      formData.effective_end = effectiveEndDate!;
      formData.updated_username = userContext.user.email;

      console.log(formData);

      setSaving(true);

      updateTransactionLimits(formData)
        .then(() => {
          toastr.success('Message Updated Successfully!');
          messageService.sendMessage('transactionLimitsUpdated');
          nextEvent();
        })
        .catch((error) => {
          setSaving(false);
          console.log(error);
        });
    }
  };

  return (
    <div className="ms-wizard-step">
      <h4>Transaction Limits</h4>
      {!data ? <Spinner /> : null}
      {data ? (
        <div className="row">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="col mb-3" controlId="id">
              <Form.Label>Id</Form.Label>
              <InputGroup>
                <Form.Control
                  disabled={true}
                  required
                  type="text"
                  defaultValue={data.id}
                  {...register('id', { required: true })}
                />
              </InputGroup>
            </Form.Group>

            <Row className="mb-3">
              <Form.Group as={Col} md="6" className="col" controlId="max_card_value">
                <Form.Label>Max Card Value</Form.Label>
                <InputGroup>
                  <div className="input-group-append">
                    <span className="input-group-text">R</span>
                  </div>
                  <Form.Control
                    className={errors.max_card_value?.message ? 'is-invalid' : ''}
                    style={{ textAlign: 'right' }}
                    required
                    type="text"
                    defaultValue={Number(data.max_card_value).toFixed(2)}
                    {...register('max_card_value', { required: true })}
                  />
                </InputGroup>
                <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                  {errors.max_card_value?.message ? 'Max Card Value is required' : null}
                </div>
              </Form.Group>

              <Form.Group as={Col} md="6" className="col" controlId="min_amount_value">
                <Form.Label>Min Amount Value</Form.Label>
                <InputGroup>
                  <div className="input-group-append">
                    <span className="input-group-text">R</span>
                  </div>
                  <Form.Control
                    className={errors.min_amount_value?.message ? 'is-invalid' : ''}
                    style={{ textAlign: 'right' }}
                    required
                    type="text"
                    defaultValue={Number(data.min_amount_value).toFixed(2)}
                    {...register('min_amount_value', { required: true })}
                  />
                </InputGroup>
                <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                  {errors.min_amount_value?.message ? 'Min Amount is required' : null}
                </div>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} md="6" className="col" controlId="max_cash_value">
                <Form.Label>Max Cash Value</Form.Label>
                <InputGroup>
                  <div className="input-group-append">
                    <span className="input-group-text">R</span>
                  </div>
                  <Form.Control
                    className={errors.max_cash_value?.message ? 'is-invalid' : ''}
                    style={{ textAlign: 'right' }}
                    required
                    type="text"
                    defaultValue={Number(data.max_cash_value).toFixed(2)}
                    {...register('max_cash_value', { required: true })}
                  />
                </InputGroup>
                <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                  {errors.max_cash_value?.message ? 'Max Cash Value is required' : null}
                </div>
              </Form.Group>
            </Row>

            <Row>
              <Form.Group className="col mb-3" controlId="effective_start">
                <Form.Label>Effective start date</Form.Label>
                <Controller
                  control={control}
                  name={'effective_start'}
                  defaultValue={effectiveStartDate}
                  render={({ field }) => (
                    <DatePicker
                      selected={effectiveStartDate}
                      placeholderText="Select date"
                      onChange={(date) => {
                        setEffectiveStartDate(date!);
                        field.onChange(date);
                      }}
                      timeInputLabel="Time:"
                      dateFormat="yyyy/MM/dd h:mm aa"
                      showTimeInput
                      className={errors.effective_start?.message ? 'is-invalid no-border' : 'no-border'}
                    />
                  )}
                />
                <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                  {errors.effective_start?.message ? 'Effective start date is required' : null}
                </div>
              </Form.Group>
            </Row>

            <Row>
              <Form.Group className="col mb-3" controlId="effective_end">
                <Form.Label>Effective end date</Form.Label>
                <Controller
                  control={control}
                  name={'effective_end'}
                  defaultValue={effectiveEndDate}
                  render={({ field }) => (
                    <DatePicker
                      selected={effectiveEndDate}
                      placeholderText="Select date"
                      onChange={(date) => {
                        setEffectiveEndDate(date!);
                        field.onChange(date);
                      }}
                      timeInputLabel="Time:"
                      dateFormat="yyyy/MM/dd h:mm aa"
                      showTimeInput
                      className={errors.effective_end?.message ? 'is-invalid no-border' : 'no-border'}
                    />
                  )}
                />
                <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                  {errors.effective_start?.message ? 'Effective start date is required' : null}
                </div>
              </Form.Group>
            </Row>

            <div className="d-flex justify-content-end">
              <Button onClick={backEvent} variant="outline-secondary" className="me-2">
                Back
              </Button>

              <Button type="submit" disabled={saving || !isValid}>
                {saving ? <Spinner size="sm" /> : 'Continue'}
              </Button>
            </div>
          </Form>
        </div>
      ) : null}
    </div>
  );
};

export default TransactionLimitsStep;
