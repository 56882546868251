import { CSSProperties, useContext, useEffect, useState } from 'react';
import { Button, Form, Alert, InputGroup } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Spinner from '../../components/Spinner';
import { Configuration, Country, createConfiguration } from '../../api/';
import { fetchOne } from '../../api/Configurations';
import { fetchAll as getCountries } from '../../api/country';
import { Channel, fetchAll as getChannels } from '../../api/Channels';
import { Company, fetchAll as getCompanies } from '../../api/Companies';
import { AuthContext } from '../../context/JWTContext';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

type ConfigurationCreateInput = {
  country_id: number;
  company_id: number;
  channel_id: number;
  created_username: string;
};

const schema = yup
  .object({
    country_id: yup.number().required('Please Select a Country'),
    company_id: yup.number().required('Please Select a Company'),
    channel_id: yup.number().required('Please Select a Channel'),
  })
  .required();

const ConfigurationCreate = () => {
  const userContext = useContext(AuthContext);

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ConfigurationCreateInput>({
    mode: 'onChange',

    resolver: yupResolver(schema),
  });

  const [countries, setCountries] = useState<Country[]>([]);
  const [channels, setChannels] = useState<Channel[]>([]);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [saving, setSaving] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    getCountries()
      .then((countries) => setCountries(countries))
      .catch((error) => setError(error.message));
    getChannels()
      .then((channels) => setChannels(channels))
      .catch((error) => setError(error.message));
    getCompanies()
      .then((companies) => setCompanies(companies))
      .catch((error) => setError(error.message));
  }, []);

  const onSubmit = (formData: ConfigurationCreateInput) => {
    // console.log(formData);
    setSaving(true);
    if (userContext?.user?.email) {
      formData.created_username = userContext.user.email;
    }

    createConfiguration(formData)
      .then((response) => {
        console.log('the response', response);
        if (!response) {
          setError('what');
          setSaving(false);
        } else {
          // Success!
          navigate('/configurations');
        }
      })
      .catch((error: any) => {
        console.log('this error', error);
        setSaving(false);
        if (error.status === 409) {
          setError('Error: Duplicate Configurations not allowed');
        } else {
          console.log('this');
          setError('Something went wrong');
        }
      });
  };

  const errorMessageTextStyleOverride: CSSProperties = {
    position: 'relative',
    textAlign: 'left',
  };

  return (
    <div className="ms-content-wrapper">
      <div className="ms-panel col-xl-4 col-lg-6 col-md-8 col-sm-12">
        <div className="ms-panel-header">
          <h6>Configuration | Add</h6>
        </div>
        <div className="ms-panel-body">
          {/* not creating but no data loaded yet, show spinner */}
          {(!countries.length || !channels.length || !companies.length) && <Spinner />}
          {/* data has been loaded */}
          {/* create new configuration */}
          {countries.length && channels.length && companies.length ? (
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group className="col mb-3" controlId="country_id">
                <Form.Select
                  className={errors.country_id?.message ? 'is-invalid' : ''}
                  aria-label="Select Country"
                  defaultChecked={false}
                  placeholder="Please Select Country"
                  {...register('country_id', { required: true, valueAsNumber: true })}
                >
                  <option>Please select a Country</option>
                  {countries?.map((country, index) => (
                    <option key={index} value={country.id}>
                      {country.name}{' '}
                    </option>
                  ))}
                </Form.Select>
                {
                  <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                    {errors.country_id ? 'please select a country' : ''}
                  </div>
                }
              </Form.Group>
              <Form.Group className="col mb-3" controlId="company_id">
                <Form.Select
                  className={errors.company_id?.message ? 'is-invalid' : ''}
                  aria-label="Select Company"
                  defaultChecked={true}
                  placeholder="Please Select Country"
                  {...register('company_id', { required: true, valueAsNumber: true })}
                >
                  <option>Please select a Company</option>
                  {companies?.map((company, index) => (
                    <option key={index} value={company.id}>
                      {company.name}{' '}
                    </option>
                  ))}
                </Form.Select>
                {
                  <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                    {errors.company_id ? 'please select company' : ''}
                  </div>
                }
              </Form.Group>

              <Form.Group className="col mb-3" controlId="channel_id">
                <Form.Select
                  className={errors.channel_id?.message ? 'is-invalid' : ''}
                  aria-label="Select Channel"
                  defaultChecked={true}
                  {...register('channel_id', { required: true, valueAsNumber: true })}
                >
                  <option value={'test'}>Please select a Channel</option>
                  {channels?.map((channel, index) => (
                    <option key={index} value={channel.id}>
                      {channel.name}{' '}
                    </option>
                  ))}
                </Form.Select>
                {
                  <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                    {errors.channel_id ? 'please select a channel' : ''}
                  </div>
                }
              </Form.Group>

              <Alert variant="danger" show={!!error}>
                {error}
              </Alert>
              <div className="d-flex justify-content-end">
                <Link to="/configurations">
                  <Button variant="outline-secondary" className="me-2">
                    Back
                  </Button>
                </Link>
                <Button type="submit" disabled={saving || !isValid}>
                  {saving ? <Spinner size="sm" /> : 'Save'}
                </Button>
              </div>
            </Form>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ConfigurationCreate;
