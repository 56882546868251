import { useEffect, useState } from 'react';
import Spinner from '../../components/Spinner';
import { fetchAllProducts } from '../../api';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { Product } from '../../@types/product';

const Products = () => {
  const [data, setData] = useState<Array<Product> | null>(null);

  useEffect(() => {
    fetchAllProducts('').then((data: any) => {
      setData(data);
    });
  }, []);

  // const columns = [
  //   { name: 'ID', selector: (row: Product) => row.id ?? '', sortable: true, maxWidth: '40px' },
  //   { name: 'Name', selector: (row: Product) => row.display_name ?? '', sortable: true, maxWidth: '180px' },
  //   { name: 'Prefix Code', selector: (row: Product) => row.prefix_code ?? '', sortable: true, maxWidth: '120px' },
  //   { name: 'Min Card Value', selector: (row: Product) => row.min_card_value ?? '', sortable: true, maxWidth: '150px' },
  //   { name: 'Max Card Value', selector: (row: Product) => row.max_card_value ?? '', sortable: true, maxWidth: '150px' },
  //   { name: 'Max Cash Value', selector: (row: Product) => row.max_cash_value ?? '', sortable: true, maxWidth: '150px' },
  //   {
  //     name: 'Transaction Type',
  //     selector: (row: Product) => row.transactions?.name ?? "",
  //     sortable: true,
  //     maxWidth: '180px',
  //   },
  //   { sortable: false, button: true, cell: (row: Product) => <OverflowMenu type="product" id={row.id} /> },
  // ];

  return (
    <div className="ms-content-wrapper">
      <div className="ms-panel">
        <div className="ms-panel-header d-flex justify-content-between align-items-center">
          <h6>Products</h6>
          <Link to={`/product/add`}>
            <Button variant="primary">Add</Button>
          </Link>
        </div>
        <div className="ms-panel-body">
          {!data && <Spinner />}
          {data && (
            <div className="table-responsive datatables row">
              {/* <DataTableExtensions {...{ columns, data }} export={false} print={false}>
                <DataTable columns={columns} data={data} pagination responsive={true} highlightOnHover noHeader />
              </DataTableExtensions> */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Products;
