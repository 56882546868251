import { AxiosResponse } from 'axios';
import { VatTransactionType } from '../@types/vat_transaction_type';
import { ServerError } from '../@types/server_error';
import axios from '../utils/axios';

export type { VatTransactionType } from '../@types/vat_transaction_type';

export const fetchAll = async (): Promise<VatTransactionType[]> => {
  const response = await axios.get('vat-transaction-types');
  return response.data;
};

export const fetchOne = async (id: number | undefined): Promise<VatTransactionType | null> => {
  try {
    const response = await axios.get(`vat-transaction-types/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const updateOne = async (data: VatTransactionType): Promise<AxiosResponse | ServerError> => {
  try {
    const response = await axios.put(`vat-transaction-types/${data.id}`, data);
    return response;
  } catch (error) {
    return error as ServerError;
  }
};

export const insertOne = async (data: VatTransactionType) => {
  try {
    const response = await axios.post(`vat-transaction-types`, data);
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};
