import { CSSProperties, useContext, useEffect, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { AuthContext } from '../../../context/JWTContext';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Institution,
  Product,
  Switch,
  Provider,
  fetchProduct,
  fetchAllSwitches,
  fetchAllInstitutions,
  fetchAllProviders,
  VatTransactionType,
  fetchTransactionTypes,
  fetchAllVatTransactionTypes,
  fetchAllDenominationTypes,
  updateProduct,
  createProduct,
} from '../../../api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import toastr from 'toastr';
import { instance } from '../../../events/event-objects';
import SwitchModal from '../../switches/SwitchModal';
import InstitutionModal from '../../institutions/InstitutionModal';
import { Controller, useForm } from 'react-hook-form';
import { createSearchParams, Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { TransactionType } from '../../../@types/transaction_type';
import { DenominationType } from '../../../@types/denomination_type';
import Spinner from '../../../components/Spinner';
import { errorMessageTextStyleOverride } from '../../../@types/errorMessages';

type ProductCreateInput = {
  id?: number;
  display_name: string;
  provider_id: number;
  switch_id: number;
  institution_id: number;
  configuration_id: number;
  provider_product_code: string;
  active_ind: number;
  visible_ind: number;
  pilot_ind: number;
  display_sequence: number;
  vat_transaction_type_id: number;
  transaction_type_id: number;
  denomination_type_id: number;
  print_barcode_on_slip: number;
  print_airline_till_slip: number;
  partial_payment_allowed: number;
  reference_label: string;
  effective_start: Date;
  effective_end: Date;
  created: Date;
  created_username: string;
  last_updated_at: Date;
  updated_username: string;
};

const schema = yup
  .object({
    display_name: yup.string().required('Product Name is required'),
    provider_id: yup.number().required('Please Select a Provider'),
    provider_product_code: yup.string().required('Provider Product Code is required'),
    switch_id: yup.number().required('Please Select a Switch'),
    institution_id: yup.number().required('Please Select a Institution'),
    transaction_type_id: yup.number().required('Please Select a Transaction Type'),
  })
  .required();

const ProductCreateStep = () => {
  const { configurationId } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const userContext = useContext(AuthContext);

  const [data, setData] = useState<Product>();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = useForm<ProductCreateInput>({
    mode: 'onChange',

    resolver: yupResolver(schema),
  });
  // const { errors } = form.formState;
  const [loading, setLoading] = useState<boolean>(false);

  const [showSwitch, setShowSwitch] = useState<boolean>(false);
  const [showInstitution, setShowInstitution] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [productId, setProductId] = useState<number>();
  const [providers, setProviders] = useState<Provider[]>([]);
  const [switchData, setSwitches] = useState<Switch[]>([]);
  const [institutionData, setInstitutions] = useState<Institution[]>([]);
  const [transactionTypes, setTransactionTypes] = useState<TransactionType[]>([]);
  const [vatTransactionTypes, setVatTransactionTypes] = useState<VatTransactionType[]>([]);
  const [denominationTypes, setDenominationTypes] = useState<DenominationType[]>([]);
  const [effectiveStartDate, setEffectiveStartDate] = useState<Date>();
  const [effectiveEndDate, setEffectiveEndDate] = useState<Date>();

  useEffect(() => {
    console.log(errors);
  }, [errors]);

  // PRODUCT ID
  useEffect(() => {
    fetchAllProviders()
      .then((providers) => setProviders(providers))
      .catch((err) => console.log(err));

    fetchAllSwitches()
      .then((switches) => setSwitches(switches))
      .catch((err) => console.log(err));

    fetchAllInstitutions().then((institutions) => {
      setInstitutions(institutions);
    });

    fetchTransactionTypes()
      .then((transactions) => setTransactionTypes(transactions))
      .catch((err) => console.log(err));

    fetchAllVatTransactionTypes()
      .then((vatTransactionTypes) => {
        setVatTransactionTypes(vatTransactionTypes);
      })
      .catch((err) => console.log(err));

    fetchAllDenominationTypes()
      .then((denominationTypes) => setDenominationTypes(denominationTypes))
      .catch((err) => console.log(err));
  }, []);

  const fireEvent = () => {
    const event = {
      key: 'navigate',
      payload: 'next',
    };

    instance().next(event);
  };

  const onSubmit = (formData: ProductCreateInput) => {
    if (formData && userContext?.user?.email) {
      formData.created_username = userContext.user.email;
      formData.configuration_id = +configurationId!;

      formData.effective_start = effectiveStartDate!;
      formData.effective_end = effectiveEndDate!;
      formData.provider_product_code.toString();
      setSaving(true);
      createProduct(formData)
        .then((response) => {
          // Success!
          navigate(`/configuration-products/${response.id}`, { state: 'created' });
          toastr.success('Product Created Successfully!');
        })
        .catch((error: any) => {
          setSaving(false);
          console.log(error);
        });
    }

    // if (formData && userContext?.user?.email && data) {
    //   formData = { ...data, ...formData };
    //   formData.updated_username = userContext.user.email;
    //   formData.configuration_id = data!.configuration_id!;
    //   formData.updated_username = userContext.user.email!;
    //   formData.id = data!.id!;
    //   formData.effective_start = effectiveStartDate!;
    //   formData.effective_end = effectiveEndDate!;
    //   formData.provider_product_code.toString();
    //   setSaving(true);
    //   updateProduct(formData)
    //     .then((response) => {
    //       // Success!
    //       navigate({ search: createSearchParams({ product_id: response.id!.toString() }).toString() });
    //       toastr.success('Product Updated Successfully!');
    //       fireEvent();
    //     })
    //     .catch((error: any) => {
    //       setSaving(false);
    //       console.log(error);
    //     });
    // }
  };

  toastr.options = {
    closeButton: false,
    debug: false,
    newestOnTop: false,
    progressBar: true,
    positionClass: 'toast-top-left',
    preventDuplicates: false,
    toastClass: 'success',
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
  };

  // subscriber.pipe(take(1)).subscribe({
  //   next(value) {
  //     if (value) {
  //       fetchAllSwitches().then((newData) => {
  //         setSwitches(newData!);
  //         console.log(switches);
  //       });
  //       fetchAllInstitutions().then((newData) => {
  //         setInstitutions(newData!);
  //       });
  //     }
  //   },
  //   complete() {
  //     dataStoredService.send(false);
  //   },
  // });

  return (
    <div className="ms-wizard-step">
      {!denominationTypes.length && switchData.length && institutionData.length ? (
        <Spinner />
      ) : (
        <div className="row">
          {/* {!data  ? <Spinner /> : null} */}

          <Form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <Form.Label>Product Name</Form.Label>
              <Form.Group className="col mb-3" controlId="display_name">
                <InputGroup>
                  <Form.Control
                    required
                    type="text"
                    defaultValue={''}
                    placeholder="Display Name"
                    {...register('display_name', { required: true })}
                  />
                  <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                    {errors.display_name?.message}
                  </div>
                </InputGroup>
              </Form.Group>
            </div>

            <div>
              <Form.Label>Provider</Form.Label>
              <Form.Group className="col mb-3" controlId="display_name">
                <Form.Select
                  className={errors.provider_id?.message ? 'is-invalid' : ''}
                  aria-label="Select provider"
                  defaultChecked={true}
                  defaultValue={''}
                  {...register('provider_id', { required: true, valueAsNumber: true })}
                >
                  <option defaultValue={0} value={0}>
                    Please select an option
                  </option>
                  {providers?.map((provider, index) => (
                    <option key={index} value={provider.id}>
                      {provider.display_name}{' '}
                    </option>
                  ))}
                </Form.Select>
                <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                  {errors.provider_id?.message}
                </div>
              </Form.Group>
            </div>

            <div>
              <Form.Label>Provider Product Code</Form.Label>
              <Form.Group className="col mb-3" controlId="provider_product_code">
                <InputGroup>
                  <Form.Control
                    required
                    type="text"
                    defaultValue={''}
                    placeholder="Provider Product Code"
                    {...register('provider_product_code', { required: true })}
                  />
                  <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                    {errors.provider_product_code?.message}
                  </div>
                </InputGroup>
              </Form.Group>
            </div>

            <div>
              <Form.Label>Reference Label</Form.Label>
              <Form.Group className="col mb-3" controlId="reference_label">
                <InputGroup>
                  <Form.Control
                    type="text"
                    defaultValue={''}
                    placeholder="Reference Label"
                    {...register('reference_label', { required: false })}
                  />
                  <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                    {errors.reference_label?.message}
                  </div>
                </InputGroup>
              </Form.Group>
            </div>

            <div>
              <Form.Label>Switch</Form.Label>
              <Form.Group className="col mb-3" controlId="switch_id">
                <div className="d-flex justify-content-between">
                  <Form.Select
                    className={errors.switch_id?.message ? 'is-invalid col-sm-10' : 'col-sm-10'}
                    aria-label="Select switch"
                    defaultChecked={true}
                    defaultValue={0}
                    {...register('switch_id', { required: true, valueAsNumber: true })}
                  >
                    <option defaultValue={0} value={0}>
                      Please select an option
                    </option>
                    {switchData?.map((confiSwitch, index) => (
                      <option key={index} value={confiSwitch.id}>
                        {confiSwitch.name}{' '}
                      </option>
                    ))}
                  </Form.Select>
                  <div className="col-sm-2">
                    <button
                      onClick={() => setShowSwitch(true)}
                      type="button"
                      className="ms-btn-icon-outline btn-pill btn-light float-end"
                    >
                      <FontAwesomeIcon icon={solid('add')} />
                    </button>
                  </div>
                </div>
                <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                  {errors.switch_id?.message}
                </div>
              </Form.Group>
            </div>
            <SwitchModal showSwitch={showSwitch} setShowSwitch={setShowSwitch} />

            <div>
              <Form.Label>Institution</Form.Label>
              <Form.Group className="col mb-3" controlId="institution_id">
                <div className="d-flex justify-content-between">
                  <Form.Select
                    className={errors.institution_id?.message ? 'is-invalid col-sm-10' : 'col-sm-10'}
                    aria-label="Select switch"
                    defaultChecked={true}
                    defaultValue={0}
                    {...register('institution_id', { required: true, valueAsNumber: true })}
                  >
                    <option defaultValue={0} value={0}>
                      Please select an option
                    </option>
                    {institutionData?.map((institution, index) => (
                      <option key={index} value={institution.id}>
                        {institution.display_name}{' '}
                      </option>
                    ))}
                  </Form.Select>
                  <div className="col-sm-2">
                    <button
                      onClick={() => setShowInstitution(true)}
                      type="button"
                      className="ms-btn-icon-outline btn-pill btn-light float-end"
                    >
                      <FontAwesomeIcon icon={solid('add')} />
                    </button>
                  </div>
                  <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                    {errors.institution_id?.message}
                  </div>
                </div>
              </Form.Group>
            </div>
            <InstitutionModal showInstitution={showInstitution} setShowInstitution={setShowInstitution} />

            <div>
              <Form.Label>Transaction Type</Form.Label>
              <Form.Group className="col mb-3" controlId="transaction_type_id">
                <Form.Select
                  className={errors.transaction_type_id?.message ? 'is-invalid' : ''}
                  aria-label="Select Transaction Type"
                  defaultChecked={true}
                  defaultValue={0}
                  {...register('transaction_type_id', { required: true, valueAsNumber: true })}
                >
                  <option defaultValue={0} value={0}>
                    Please select an option
                  </option>
                  {transactionTypes?.map((transactionTypes, index) => (
                    <option key={index} value={transactionTypes.id}>
                      {transactionTypes.name}{' '}
                    </option>
                  ))}
                </Form.Select>
                <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                  {errors.transaction_type_id?.message}
                </div>
              </Form.Group>
            </div>

            <div>
              <Form.Label>Vat Transaction Type</Form.Label>
              <Form.Group className="col mb-3" controlId="vat_transaction_type_id">
                <Form.Select
                  className={errors.vat_transaction_type_id?.message ? 'is-invalid' : ''}
                  aria-label="Select Vat Transaction Type"
                  defaultChecked={true}
                  defaultValue={0}
                  {...register('vat_transaction_type_id', { required: true, valueAsNumber: true })}
                >
                  <option defaultValue={0} value={0}>
                    Please select an option
                  </option>
                  {vatTransactionTypes?.map((vatTransactionTypes, index) => (
                    <option key={index} value={vatTransactionTypes.id}>
                      {vatTransactionTypes.code}{' '}
                    </option>
                  ))}
                </Form.Select>
                <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                  {errors.vat_transaction_type_id?.message}
                </div>
              </Form.Group>
            </div>

            <div>
              <Form.Group className="col mb-3" controlId="denomination_type_id">
                <Form.Label>Denomination Type</Form.Label>
                <Form.Select
                  className={errors.denomination_type_id?.message ? 'is-invalid' : ''}
                  aria-label="Select Vat Transaction Type"
                  defaultChecked={true}
                  defaultValue={0}
                  {...register('denomination_type_id', { required: true, valueAsNumber: true })}
                >
                  <option defaultValue={0} value={0}>
                    Please select an option
                  </option>
                  {denominationTypes?.map((denominationTypes, index) => (
                    <option key={index} value={denominationTypes.id}>
                      {denominationTypes.name}{' '}
                    </option>
                  ))}
                </Form.Select>
                <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                  {errors.vat_transaction_type_id?.message}
                </div>
              </Form.Group>
            </div>

            <div>
              <Form.Group className="col mb-3" controlId="effective_start">
                <Form.Label>Effective start date</Form.Label>
                <Controller
                  control={control}
                  name="effective_start"
                  render={({ field }) => (
                    <DatePicker
                      selected={effectiveStartDate}
                      placeholderText="Select date"
                      onChange={(date) => {
                        setEffectiveStartDate(date!);
                        field.onChange(date);
                      }}
                      timeInputLabel="Time:"
                      dateFormat="yyyy/MM/dd h:mm aa"
                      showTimeInput
                      className={errors.effective_start?.message ? 'is-invalid no-border' : 'no-border'}
                    />
                  )}
                />
                <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                  {errors.effective_start?.message}
                </div>
              </Form.Group>
            </div>

            <div>
              <Form.Group className="col mb-3" controlId="effective_end">
                <Form.Label>Effective end date</Form.Label>
                <Controller
                  control={control}
                  name="effective_end"
                  render={({ field }) => (
                    <DatePicker
                      selected={effectiveEndDate}
                      placeholderText="Select date"
                      onChange={(date) => {
                        setEffectiveEndDate(date!);
                        field.onChange(date);
                      }}
                      timeInputLabel="Time:"
                      dateFormat="yyyy/MM/dd h:mm aa"
                      showTimeInput
                      className={errors.effective_end?.message ? 'is-invalid no-border' : 'no-border'}
                    />
                  )}
                />
                <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                  {errors.effective_end?.message}
                </div>
              </Form.Group>
            </div>

            <Form.Group className="pt-3 pb-3" controlId="active_ind">
              <label className="ms-switch">
                <input type="checkbox" defaultChecked={false} {...register('active_ind')} />
                <span className="ms-switch-slider ms-switch-primary round" />
              </label>
              <span className="text-dark p-2">Active</span>
            </Form.Group>

            <Form.Group className="pb-3" controlId="visible_ind">
              <label className="ms-switch">
                <input type="checkbox" defaultChecked={false} {...register('visible_ind')} />
                <span className="ms-switch-slider ms-switch-primary round" />
              </label>
              <span className="text-dark p-2">Visible</span>
            </Form.Group>

            <Form.Group className="pb-3" controlId="pilot_ind">
              <label className="ms-switch">
                <input type="checkbox" defaultChecked={false} {...register('pilot_ind')} />
                <span className="ms-switch-slider ms-switch-primary round" />
              </label>
              <span className="text-dark p-2">Pilot</span>
            </Form.Group>

            <Form.Group className="pb-3" controlId="print_barcode_on_slip">
              <label className="ms-switch">
                <input type="checkbox" defaultChecked={false} {...register('print_barcode_on_slip')} />
                <span className="ms-switch-slider ms-switch-primary round" />
              </label>
              <span className="text-dark p-2">Print Barcode On Slip</span>
            </Form.Group>

            <Form.Group className="pb-3" controlId="print_airline_till_slip">
              <label className="ms-switch">
                <input type="checkbox" defaultChecked={false} {...register('print_airline_till_slip')} />
                <span className="ms-switch-slider ms-switch-primary round" />
              </label>
              <span className="text-dark p-2">Print Airline Till Slip</span>
            </Form.Group>

            <Form.Group className="pb-3" controlId="partial_payment_allowed">
              <label className="ms-switch">
                <input type="checkbox" defaultChecked={false} {...register('partial_payment_allowed')} />
                <span className="ms-switch-slider ms-switch-primary round" />
              </label>
              <span className="text-dark p-2">Partial Payment Allowed</span>
            </Form.Group>

            <div className="d-flex justify-content-end">
              {/* <Link to="/configurations">
                <Button variant="outline-secondary" className="me-2">
                  Back
                </Button>
              </Link> */}
              {/* <Button onClick={fireEvent} variant="outline-secondary" className="me-2">
                next
              </Button> */}
              <Button type="submit" disabled={saving || !isValid}>
                {saving ? <Spinner size="sm" /> : 'Continue'}
              </Button>
            </div>
          </Form>
        </div>
      )}
    </div>
  );
};

export default ProductCreateStep;
