import { InstitutionCategory } from '../@types/institution';
import axios from '../utils/axios';

export type { InstitutionCategory } from '../@types/institution'

export const fetchAll = async ():Promise<Array<InstitutionCategory> | null> => {
  try {
    const response = await axios.get('institution-categories');
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export const fetchOne = async (id:string | undefined):Promise<InstitutionCategory | null> => {
  try {
    const response = await axios.get(`institution-categories/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export const updateOne = async (data:InstitutionCategory) => {
  try {
    const response = await axios.put(`institution-categories/${data.id}`, data);
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export const insertOne = async (data:InstitutionCategory) => {
  try {
    const response = await axios.post(`institution-categories`, data);
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
}

