import { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import DataTableExtensions from 'react-data-table-component-extensions';
import OverflowMenu from '../../components/OverflowMenu';
import Spinner from '../../components/Spinner';
import { fetchAll } from '../../api/Configurations';
import { Link } from 'react-router-dom';
import { Button, Form, InputGroup, Row } from 'react-bootstrap';
import { Configuration } from '../../@types/configuration';
import { from, Subject } from 'rxjs';

const Configurations = () => {
  const [data, setData] = useState<Configuration[] | null>(null);
  const searchString$ = new Subject<string>();

  useEffect(() => {
    fetchAll('').then((data: Configuration[]) => {
      setData(data);
    });
  }, []);

  useEffect(() => {
    searchString$
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((val) =>
          fetchAll(val).then((data: Configuration[]) => {
            setData(data);
          })
        )
      )
      .subscribe();

    return () => searchString$.complete();
  });

  const handleSearch = (searchString: string) => searchString$.next(searchString);

  const columns = [
    { name: 'ID', selector: (row: Configuration) => row.id ?? '', sortable: true, maxWidth: '40px' },
    {
      name: 'Company',
      selector: (row: Configuration) => row?.company?.name ?? '',
      sortable: true,
    },
    {
      name: 'Channel',
      selector: (row: Configuration) => row?.channel?.name ?? '',
      sortable: true,
    },
    {
      name: 'Country',
      selector: (row: Configuration) => row?.country?.name ?? '',
      sortable: true,
    },
    {
      sortable: false,
      button: true,
      cell: (row: Configuration) => <OverflowMenu type="configuration" id={row.id} />,
    },
  ];

  return (
    <div className="ms-content-wrapper">
      <div className="ms-panel">
        <div className="ms-panel-header d-flex justify-content-between align-items-center">
          <h6>Configurations</h6>
          <Link to={`/configuration`}>
            <Button variant="primary">Add</Button>
          </Link>
        </div>
        <div className="ms-panel-body">
          {!data && <Spinner />}
          {data && (
            <Fragment>
              <Row className="mb-0" style={{ maxWidth: '30%' }}>
                <InputGroup>
                  <Form.Control placeholder="Filter Data" type="text" onChange={(e) => handleSearch(e.target.value)} />
                </InputGroup>
              </Row>
              <div className="table-responsive datatables row">
                <DataTableExtensions {...{ columns, data }} export={false} print={false} filter={false}>
                  <DataTable columns={columns} data={data} pagination responsive={true} highlightOnHover noHeader />
                </DataTableExtensions>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default Configurations;
