import { createContext, useState, Dispatch, ReactNode } from "react";

export const LoadingContext = createContext<{ loading: boolean; setLoading: Dispatch<React.SetStateAction<boolean>> }>({
  loading: false,
  setLoading: (loading) => loading,
});

type LoadingProps = {
  children: ReactNode;
};

export const LoadingProvider = ({ children }: LoadingProps) => {
  const [loading, setLoading] = useState(false);
  const value = { loading, setLoading };
  return <LoadingContext.Provider value={value}>{children}</LoadingContext.Provider>;
};
