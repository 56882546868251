import axios from '../utils/axios';

import { ProductFees, ProductFeesInput } from '../@types/product-fees';

export const fetchFeesByProductId = async (product_id: number): Promise<ProductFees> => {
  const response = await axios.get<ProductFees>(`product-fees/product/${product_id}`);
  return response.data;
};

export const updateProductFees = async (productFee: ProductFeesInput): Promise<ProductFees> => {
  const response = await axios.put<ProductFees>(`product-fees/${productFee.id}`, productFee);
  return response.data;
};

export const createProductFees = async (productFee: ProductFeesInput): Promise<ProductFees> => {
  const response = await axios.post<ProductFees>(`product-fees`, productFee);
  return response.data;
};
