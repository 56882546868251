import { Dispatch, SetStateAction, useState } from 'react';

import { Form, Alert, InputGroup, Modal, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Spinner from '../../components/Spinner';
import * as yup from 'yup';
import { createSwitch } from '../../api';
import { yupResolver } from '@hookform/resolvers/yup';
import { messageService } from '../../events/create.events';
import toastr from 'toastr';

toastr.options = {
  closeButton: false,
  debug: false,
  newestOnTop: false,
  progressBar: true,
  positionClass: 'toast-top-left',
  preventDuplicates: false,
  toastClass: 'success',
  showEasing: 'swing',
  hideEasing: 'linear',
  showMethod: 'fadeIn',
  hideMethod: 'fadeOut',
};

type SwitchCreateInput = {
  name: string;
};

const schema = yup
  .object({
    name: yup.string().required('Name is a required field'),
  })
  .required();

const SwitchModal = (props: { showSwitch: boolean; setShowSwitch: Dispatch<SetStateAction<boolean>> }) => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<SwitchCreateInput>({
    defaultValues: {
      name: '',
    },
    resolver: yupResolver(schema),
  });

  const [saving, setSaving] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);

  const fireEvent = () => {
    messageService.sendMessage('newSwitch');
  };

  const onCreateSwitch = (formData: SwitchCreateInput) => {
    setSaving(true);

    // Merge new data with old data
    const newData = { ...formData };

    createSwitch(newData)
      .then((response) => {
        if (!response) {
          setError('Something went wrong.');
          setSaving(false);
        } else if (response.status !== 200) {
          setError('Error: ' + response.status);
          setSaving(false);
        } else {
          // Close Modal!
          toastr.info('Switch Created');
          setSaving(false);
          reset({ name: '' });
          props.setShowSwitch(false);
          fireEvent();
        }
      })
      .catch((error: any) => {
        setSaving(false);
        setError('Error: ' + error.message);
      });
  };

  return (
    <Modal
      show={props.showSwitch}
      onHide={() => props.setShowSwitch(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="bg-primary">
        <h3 className="modal-title has-icon text-white">Add New Switch</h3>

        <button type="button" className="close" onClick={() => props.setShowSwitch(false)}>
          <span>×</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Name</Form.Label>
            <InputGroup>
              <Form.Control type="text" placeholder="Switch Name" {...register('name', { required: true })} />
              <Form.Control.Feedback type="invalid">Name is required</Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Alert variant="danger" show={error != null}>
            {error}
          </Alert>
          <div className="d-flex justify-content-end">
            <Button type="button" className="btn btn-light" onClick={() => props.setShowSwitch(false)}>
              Cancel
            </Button>
            <Button onClick={handleSubmit(onCreateSwitch)} className="btn btn-primary shadow-none">
              {saving ? <Spinner size="sm" /> : 'Save'}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default SwitchModal;
