import axios from '../utils/axios';
import { Country } from '../@types/country';

export type { Country } from '../@types/country';

export const fetchAll = async (): Promise<Country[]> => {
  const response = await axios.get<Country[]>('/countries');
  return response.data;
};

export const fetchOne = async (id: number): Promise<Country | null> => {
  try {
    const response = await axios.get(`/countries/${id}`);
    return response.data;
  } catch (error) {
    return null;
  }
};

export const updateOne = async (data: Country) => {
  try {
    const countryData = {
      name: data.name,
    };
    const response = await axios.put(`/countries/${data.id}`, countryData);
    return response.data;
  } catch (error) {
    return null;
  }
};

export const insertOne = async (data: Country) => {
  const countryData = {
    name: data.name,
  };
  try {
    const response = await axios.post(`/countries`, countryData);
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};
