import { AxiosResponse } from 'axios';
import { Provider, ProviderCreateInput } from '../@types/provider';
import axios from '../utils/axios';

export type { Provider } from '../@types/provider';

export const fetchAll = async (): Promise<Provider[]> => {
  const response = await axios.get('providers');
  return response.data;
};

export const fetchOne = async (id: number): Promise<Provider> => {
  const response = await axios.get(`providers/${id}`);
  return response.data;
};

export const updateOne = async (data: Provider) => {
  try {
    const response = await axios.put(`providers/${data.id}`, data);
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const insertOne = async (data: ProviderCreateInput): Promise<AxiosResponse<Provider>> => {
  const response = await axios.post(`providers`, data);
  return response;
};
