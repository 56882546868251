import { Fragment, useContext, useEffect } from 'react';

import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import Navbar from './Navbar';
import SideNav from './SideNav';

/* This is a wrapper for all pages, containing the top nav bar and sidebar */

// Pages
import Home from '../pages/Home';
import ConfigurationProducts from '../pages/configuration_products/ConfigurationProducts';
import Channels from '../pages/channels/Channels';
import Channel from '../pages/channels/Channel';
import Categories from '../pages/categories/Categories';
import Category from '../pages/categories/Category';
import Products from '../pages/products/Products';
import Institutions from '../pages/institutions/Institutions';
import Institution from '../pages/institutions/Institution';
import Providers from '../pages/providers/Providers';
import Switches from '../pages/switches/Switches';
import Switch from '../pages/switches/Switch';
import Dashboard from '../pages/Dashboard';
import Product from '../pages/products/Product';

import Configurations from '../pages/configuration/Configurations';
import ConfigurationEdit from '../pages/configuration/ConfigurationEdit';
import { AuthContext } from '../context/JWTContext';
import ConfigurationCreate from '../pages/configuration/ConfigurationCreate';
import Steps from '../pages/configuration_products/Steps';
import StepsCreate from '../pages/configuration_products_create/StepsCreate';
import useAuth from '../hooks/useAuth';
import { auth } from '../firebase';

const Wrapper = () => {
  const userContext = useContext(AuthContext);
  const { login } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  if (location.pathname.length > 1) {
    localStorage.setItem('path', location.pathname);
  }
  useEffect(() => {
    const path = localStorage.getItem('path');

    if (userContext?.isAuthenticated && path) {
      localStorage.removeItem('path');
      navigate(path);
      return;
    }
    if (!userContext?.isAuthenticated) {
      navigate('/login');
    }
  }, [userContext]);

  useEffect(() => {
    auth.onIdTokenChanged((user) => {
      if (user) {
        login(user);
      }
    });
  }, []);

  return (
    <Fragment>
      <div className="body ms-body ms-aside-left-open ms-primary-theme" id="body">
        <SideNav />
        <main className="body-content">
          <Navbar />
          {userContext?.isAuthenticated && (
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/configuration-products" element={<ConfigurationProducts />} />
              <Route path="/configuration-products/:configurationId/create" element={<StepsCreate />} />
              <Route path="/configuration-products/:id" element={<Steps />} />
              <Route path="/channels" element={<Channels />} />
              <Route path="/channel/:id" element={<Channel />} />
              <Route path="/categories" element={<Categories />} />
              <Route path="/category/:id" element={<Category />} />
              <Route path="/products" element={<Products />} />
              <Route path="/product/:id" element={<Product />} />
              <Route path="/institutions" element={<Institutions />} />
              <Route path="/institution/:id" element={<Institution />} />
              <Route path="/providers" element={<Providers />} />
              <Route path="/switches" element={<Switches />} />
              <Route path="/switch/:id" element={<Switch />} />
              <Route path="/configurations" element={<Configurations />} />
              <Route path="/configuration" element={<ConfigurationCreate />} />
              <Route path="/configuration/:configurationId" element={<ConfigurationEdit />} />
              <Route path="/configuration/:configurationId/products" element={<ConfigurationProducts />} />
            </Routes>
          )}
        </main>
      </div>
    </Fragment>
  );
};

export default Wrapper;
