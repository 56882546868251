import axios from 'axios';

//https://create-react-app.dev/docs/adding-custom-environment-variables/

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_ROUTE || '',
});

//axiosInstance.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error.response || 'Something went wrong')
);

export default axiosInstance;
