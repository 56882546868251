import React, { Component } from 'react';

import MultiStep from '../../components/MultiStep';
import FeesStep from './steps/FeesStep';
import MessageStep from './steps/MessageStep';
import ProductStep from './steps/ProductStep';
import TransactionLimitsStep from './steps/TransactionLimitsStep';
import ValidationStep from './steps/ValidationStep';

const steps = [
  { component: <ProductStep />, text: 'Product' },
  { component: <FeesStep />, text: 'Fees' },
  { component: <TransactionLimitsStep />, text: 'Transaction Limits' },
  { component: <MessageStep />, text: 'Messages' },
  { component: <ValidationStep />, text: 'Validation' },
];

const prevStyle = {};
const nextStyle = {};
class Steps extends Component {
  //handleStepChange = (currentStep) => {};
  render() {
    return <MultiStep steps={steps} prevStyle={prevStyle} nextStyle={nextStyle} />;
  }
}

export default Steps;
