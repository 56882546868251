import React, { Fragment, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { css, styled, setup } from 'goober';
import { instance } from '../events/event-objects';
import { UseFormReturn } from 'react-hook-form';
import { take } from 'rxjs';
import { stepEventType } from '../events/steps.events';
import { Product } from '../@types/product';
import {
  fetchFeesByProductId,
  fetchMessagesByProductId,
  fetchProduct,
  fetchTransactionLimitsByProductId,
  fetchValidationByProductId,
} from '../api';
import { ProductFees } from '../@types/product-fees';
import { TransactionLimit } from '../@types/transaction-limits';
import { Message } from '../@types/message';
import { Validation } from '../@types/validation';
import { messageService } from '../events/create.events';
import { fetchAll } from '../api/Products';
setup(React.createElement);

export interface Step {
  component: JSX.Element;
  text: string;
}

interface MultiStepProps {
  steps: Step[];
  showNavigation?: boolean;
  prevStyle?: any;
  nextStyle?: any;
}

const LiClass = (props: any) => css`
  color: ${props.state === 'todo' ? 'silver' : 'black'}; ;
`;

const Ol = styled('ul')`
  margin: 0;
  list-style-type: none;
`;

const getTopNavStyles = (indx: number, length: number) => {
  const styles = [];
  for (let i = 0; i < length; i++) {
    if (i < indx) {
      styles.push('done');
    } else if (i === indx) {
      styles.push('doing');
    } else {
      styles.push('todo');
    }
  }
  return styles;
};

const getButtonsState = (indx: number, length: number) => {
  if (indx > 0 && indx < length - 1) {
    return {
      showPreviousBtn: true,
      showNextBtn: true,
    };
  } else if (indx === 0) {
    return {
      showPreviousBtn: false,
      showNextBtn: true,
    };
  } else {
    return {
      showPreviousBtn: true,
      showNextBtn: false,
    };
  }
};

export default function MultiStep(props: MultiStepProps) {
  const { id } = useParams();
  const [productData, setProductData] = useState<Product>();
  const [feesData, setFeesData] = useState<ProductFees>();
  const [transactionLimitData, setTransactionLimitData] = useState<TransactionLimit>();
  const [messageData, setMessageData] = useState<Message[]>();
  const [validationData, setValidationData] = useState<Validation>();

  useEffect(() => {
    if (id) {
      displayData();
    }
  }, [id]);

  const displayData = () => {
    fetchProduct(+id!)
      .then((product) => {
        setProductData(product);
      })
      .catch((err) => console.log(err));

    fetchFeesByProductId(+id!)
      .then((product_fees) => {
        setFeesData(product_fees);
      })
      .catch((error) => console.log(error));

    fetchTransactionLimitsByProductId(+id!)
      .then((transaction_limit) => {
        // console.log(product_fees);
        setTransactionLimitData(transaction_limit);
      })
      .catch((error) => console.log(error));

    fetchMessagesByProductId(+id!)
      .then((messages) => {
        setMessageData(messages);
      })
      .catch((error) => console.log(error));

    fetchValidationByProductId(+id!)
      .then((validation) => {
        setValidationData(validation);
      })
      .catch((error) => console.log(error));
  };
  let showNav = true;
  if (props.showNavigation) showNav = props.showNavigation;

  let prevStyle = {};
  if (props.prevStyle) prevStyle = props.prevStyle;

  let nextStyle = {};
  if (props.nextStyle) nextStyle = props.nextStyle;

  const [stylesState, setStyles] = useState(getTopNavStyles(0, props.steps.length));
  const [compState, setComp] = useState(0);
  const [buttonsState, setButtons] = useState(getButtonsState(0, props.steps.length));

  const [stepIndex, setStepIndex] = useState(0);

  const setStepState = (index: number) => {
    setStyles(getTopNavStyles(index, props.steps.length));
    setComp(index < props.steps.length ? index : compState);
    setButtons(getButtonsState(index, props.steps.length));
    setStepIndex(index);
  };

  instance()
    .listen('next')
    .pipe(take(1))
    .subscribe((e: stepEventType) => {
      setStepState(compState + 1);
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    });
  instance()
    .listen('back')
    .pipe(take(1))
    .subscribe((e: stepEventType) => {
      setStepState(compState > 0 ? compState - 1 : compState);
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    });

  useEffect(() => {
    const dataSubscription = messageService.getMessage().subscribe({
      next: (val) => {
        switch (val.key) {
          case 'productUpdated':
            fetchProduct(+id!)
              .then((product) => {
                setProductData(product);
              })
              .catch((err) => console.log(err));
            break;
          case 'feesUpdated':
            fetchFeesByProductId(+id!)
              .then((product_fees) => {
                setFeesData(product_fees);
              })
              .catch((error) => console.log(error));

            break;
          case 'transactionLimitsUpdated':
            fetchTransactionLimitsByProductId(+id!)
              .then((transaction_limit) => {
                // console.log(product_fees);
                setTransactionLimitData(transaction_limit);
              })
              .catch((error) => console.log(error));
            break;
          case 'messagesUpdated':
            fetchMessagesByProductId(+id!)
              .then((messages) => {
                setMessageData(messages);
              })
              .catch((error) => console.log(error));
            break;
          case 'validationUpdated':
            fetchValidationByProductId(+id!)
              .then((validation) => {
                setValidationData(validation);
              })
              .catch((error) => console.log(error));

            break;
          default:
            break;
        }
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => {
        messageService.clearMessages();
      },
    });

    return () => {
      dataSubscription.unsubscribe();
    };
  }, []);

  const next = () => setStepState(compState + 1);
  const previous = () => setStepState(compState > 0 ? compState - 1 : compState);
  const handleKeyDown = (evt: any) => (evt.which === 13 ? next() : {});

  const handleOnClick = (evt: any) => {
    if (evt.currentTarget.value === props.steps.length - 1 && compState === props.steps.length - 1) {
      setStepState(props.steps.length);
    } else {
      setStepState(evt.currentTarget.value);
    }
  };

  const inactiveStyles = 'm-0 p-5 pt-3 pb-3 d-inline';
  const activeStyles = inactiveStyles + ' rounded-pill bg-white text-primary';

  const renderSteps = () =>
    props.steps.map((step: any, index: number) => (
      <li
        className={LiClass({ state: stylesState[index] })}
        style={{ textAlign: 'left' }}
        onClick={handleOnClick}
        key={`index_${index}`}
        value={index}
      >
        {index < props.steps.length - 1 && (
          <div
            style={{
              top: '34px',
              left: '18px',
              position: 'absolute',
              width: '5px',
              height: '100%',
              backgroundColor: 'white',
            }}
          ></div>
        )}
        <Link
          onClick={() => {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
          }}
          to=""
        >
          <span className="number">{index + 1}</span>
          <div className={stepIndex == index ? activeStyles : inactiveStyles}>{step.text}</div>
        </Link>
        <ul className="ms-list summary mt-3 mb-3">
          {/* Product Data */}
          {productData && step.text === 'Product' ? (
            <Fragment>
              <li className="summary-text">
                <span>
                  Id: <span style={{ fontWeight: 'bolder' }}>{productData.id}</span>
                </span>
              </li>
              <li className="summary-text">
                <span>
                  Product Name: <span style={{ fontWeight: 'bolder' }}>{productData.display_name}</span>
                </span>
              </li>
              <li className="summary-text">
                <span>
                  Provider: <span style={{ fontWeight: 'bolder' }}>{productData.provider?.display_name}</span>
                </span>
              </li>
              <li className="summary-text">
                <span>
                  Switch: <span style={{ fontWeight: 'bolder' }}>{productData.switch?.name}</span>
                </span>
              </li>
              <li className="summary-text">
                <span>
                  Institution: <span style={{ fontWeight: 'bolder' }}>{productData.institution?.display_name}</span>
                </span>
              </li>
              <li className="summary-text">
                <span>
                  Provider Product Code:{' '}
                  <span style={{ fontWeight: 'bolder' }}>{productData.provider_product_code}</span>
                </span>
              </li>
              <li className="summary-text">
                <span>
                  Reference Label: <span style={{ fontWeight: 'bolder' }}>{productData.reference_label}</span>
                </span>
              </li>
              <li className="summary-text">
                <span>
                  Transaction Type: <span style={{ fontWeight: 'bolder' }}>{productData.transaction_type?.name}</span>
                </span>
              </li>
              <li className="summary-text">
                <span>
                  Vat Transaction Type:{' '}
                  <span style={{ fontWeight: 'bolder' }}>{productData.vat_transaction_type?.code}</span>
                </span>
              </li>
              <li className="summary-text">
                <span>
                  Denomination Type: <span style={{ fontWeight: 'bolder' }}>{productData.denomination_type?.name}</span>
                </span>
              </li>
              <li className="summary-text">
                <span>
                  Active: <span style={{ fontWeight: 'bolder' }}>{productData.active_ind ? 'true' : 'false'}</span>
                </span>
              </li>
              <li className="summary-text">
                <span>
                  Visible: <span style={{ fontWeight: 'bolder' }}>{productData.visible_ind ? 'true' : 'false'}</span>
                </span>
              </li>
              <li className="summary-text">
                <span>
                  Pilot: <span style={{ fontWeight: 'bolder' }}>{productData.pilot_ind ? 'true' : 'false'}</span>
                </span>
              </li>
              <li className="summary-text">
                <span>
                  Print Barcode On Slip:{' '}
                  <span style={{ fontWeight: 'bolder' }}>{productData.visible_ind ? 'true' : 'false'}</span>
                </span>
              </li>
              <li className="summary-text">
                <span>
                  Visible: <span style={{ fontWeight: 'bolder' }}>{productData.visible_ind ? 'true' : 'false'}</span>
                </span>
              </li>
              <li className="summary-text">
                <span>
                  Pilot: <span style={{ fontWeight: 'bolder' }}>{productData.pilot_ind ? 'true' : 'false'}</span>
                </span>
              </li>
              <li className="summary-text">
                <span>
                  Print Barcode On Slip:{' '}
                  <span style={{ fontWeight: 'bolder' }}>{productData.print_barcode_on_slip ? 'true' : 'false'}</span>
                </span>
              </li>
              <li className="summary-text">
                <span>
                  Print Airline Till Slip:{' '}
                  <span style={{ fontWeight: 'bolder' }}>{productData.print_airline_till_slip ? 'true' : 'false'}</span>
                </span>
              </li>
              <li className="summary-text">
                <span>
                  Partial Payment Allowed:{' '}
                  <span style={{ fontWeight: 'bolder' }}>{productData.partial_payment_allowed ? 'true' : 'false'}</span>
                </span>
              </li>
              <li className="summary-text">
                <span>
                  <>
                    Effective Start Date:{' '}
                    <span style={{ fontWeight: 'bolder' }}>{productData.effective_start?.toString()}</span>
                  </>
                </span>
              </li>
              <li className="summary-text">
                <span>
                  <>
                    Effective End Date:{' '}
                    <span style={{ fontWeight: 'bolder' }}>{productData.effective_end?.toString()}</span>
                  </>
                </span>
              </li>
            </Fragment>
          ) : null}
          {/* Fees Data */}
          {feesData && step.text === 'Fees' ? (
            <Fragment>
              <li className="summary-text">
                <span>
                  Id: <span style={{ fontWeight: 'bolder' }}>{feesData.id}</span>
                </span>
              </li>

              <li className="summary-text">
                <span>
                  Lex Fee Amount:{' '}
                  <span style={{ fontWeight: 'bolder' }}>R{Number(feesData.lex_fee_amount).toFixed(2)}</span>
                </span>
              </li>
              <li className="summary-text">
                <span>
                  Cash Handeling Fee Amount:{' '}
                  <span style={{ fontWeight: 'bolder' }}>R{Number(feesData.cash_handling_fee_amount).toFixed(2)}</span>
                </span>
              </li>
              <li className="summary-text">
                <span>
                  Card Handeling Fee Amount:{' '}
                  <span style={{ fontWeight: 'bolder' }}>R{Number(feesData.card_handling_fee_amount).toFixed(2)}</span>
                </span>
              </li>
              <li className="summary-text">
                <span>
                  3rd Party Cash Handeling Fee Amount:{' '}
                  <span style={{ fontWeight: 'bolder' }}>
                    R{Number(feesData.third_party_cash_handling_fee_amount).toFixed(2)}
                  </span>
                </span>
              </li>
              <li className="summary-text">
                <span>
                  3rd Party Card Handeling Fee Amount:{' '}
                  <span style={{ fontWeight: 'bolder' }}>
                    R{Number(feesData.third_party_card_handling_fee_amount).toFixed(2)}
                  </span>
                </span>
              </li>
              <li className="summary-text">
                <span>
                  Local Min Fee Amount:{' '}
                  <span style={{ fontWeight: 'bolder' }}>R{Number(feesData.local_min_fee_amount).toFixed(2)}</span>
                </span>
              </li>
              <li className="summary-text">
                <span>
                  Local Max Fee Amount:{' '}
                  <span style={{ fontWeight: 'bolder' }}>R{Number(feesData.local_max_fee_amount).toFixed(2)}</span>
                </span>
              </li>
              <li className="summary-text">
                <span>
                  Local Fee Percentage:{' '}
                  <span style={{ fontWeight: 'bolder' }}>{Number(feesData.local_max_fee_amount).toFixed(2)}%</span>
                </span>
              </li>
              <li className="summary-text">
                <span>
                  Cash Handeling Fee Percentage:{' '}
                  <span style={{ fontWeight: 'bolder' }}>
                    {Number(feesData.cash_handling_fee_percentage).toFixed(2)}%
                  </span>
                </span>
              </li>
              <li className="summary-text">
                <span>
                  Card Handeling Fee Percentage:{' '}
                  <span style={{ fontWeight: 'bolder' }}>
                    {Number(feesData.card_handling_fee_percentage).toFixed(2)}%
                  </span>
                </span>
              </li>
              <li className="summary-text">
                <span>
                  3rd Party Cash Handeling Fee Percentage:{' '}
                  <span style={{ fontWeight: 'bolder' }}>
                    {Number(feesData.third_party_cash_handling_fee_amount).toFixed(2)}%
                  </span>
                </span>
              </li>
              <li className="summary-text">
                <span>
                  3rd Party Card Handeling Fee Percentage:{' '}
                  <span style={{ fontWeight: 'bolder' }}>
                    {Number(feesData.third_party_card_handling_fee_amount).toFixed(2)}%
                  </span>
                </span>
              </li>
              <li className="summary-text">
                <span>
                  Customer Service Value:{' '}
                  <span style={{ fontWeight: 'bolder' }}>
                    R{Number(feesData.customer_service_fee_value).toFixed(2)}
                  </span>
                </span>
              </li>
              <li className="summary-text">
                <span>
                  Commission Percentage:{' '}
                  <span style={{ fontWeight: 'bolder' }}>{Number(feesData.commission_percentage).toFixed(2)}%</span>
                </span>
              </li>

              <li className="summary-text">
                <span>
                  <>
                    Effective Start Date:{' '}
                    <span style={{ fontWeight: 'bolder' }}>{feesData.effective_start?.toString()}</span>
                  </>
                </span>
              </li>
              <li className="summary-text">
                <span>
                  <>
                    Effective End Date:{' '}
                    <span style={{ fontWeight: 'bolder' }}>{feesData.effective_end?.toString()}</span>
                  </>
                </span>
              </li>
            </Fragment>
          ) : null}
          {/* Transactional Reports */}
          {transactionLimitData && step.text === 'Transaction Limits' ? (
            <Fragment>
              <li className="summary-text">
                <span>
                  Id: <span style={{ fontWeight: 'bolder' }}>{transactionLimitData.id}</span>
                </span>
              </li>

              <li className="summary-text">
                <span>
                  Max Card Value:{' '}
                  <span style={{ fontWeight: 'bolder' }}>
                    R{Number(transactionLimitData.max_card_value).toFixed(2)}
                  </span>
                </span>
              </li>
              <li className="summary-text">
                <span>
                  Min Amount Value:{' '}
                  <span style={{ fontWeight: 'bolder' }}>
                    R{Number(transactionLimitData.min_amount_value).toFixed(2)}
                  </span>
                </span>
              </li>
              <li className="summary-text">
                <span>
                  Max Cash Value:{' '}
                  <span style={{ fontWeight: 'bolder' }}>
                    R{Number(transactionLimitData.max_cash_value).toFixed(2)}
                  </span>
                </span>
              </li>

              <li className="summary-text">
                <span>
                  <>
                    Effective Start Date:{' '}
                    <span style={{ fontWeight: 'bolder' }}>{transactionLimitData.effective_start?.toString()}</span>
                  </>
                </span>
              </li>
              <li className="summary-text">
                <span>
                  <>
                    Effective End Date:{' '}
                    <span style={{ fontWeight: 'bolder' }}>{transactionLimitData.effective_end?.toString()}</span>
                  </>
                </span>
              </li>
            </Fragment>
          ) : null}
          {/* message Data */}
          {messageData && step.text === 'Messages' ? (
            <Fragment>
              {messageData.map((message) => {
                return (
                  <li className="summary-text" key={message.id}>
                    <span>
                      <span style={{ fontWeight: 'bolder' }}>
                        {message.message_type ? message.message_type.name : 'Default Message Type'}
                      </span>
                    </span>
                  </li>
                );
              })}
            </Fragment>
          ) : null}
          {/* validation data */}
          {validationData && step.text === 'Validation' ? (
            <Fragment>
              <li className="summary-text">
                <span>
                  Id: <span style={{ fontWeight: 'bolder' }}>{validationData!.id}</span>
                </span>
              </li>
              <li className="summary-text">
                <span>
                  Prefix Code: <span style={{ fontWeight: 'bolder' }}>{validationData!.prefix_code}</span>
                </span>
              </li>
              <li className="summary-text">
                <span>
                  Reference Code Length:{' '}
                  <span style={{ fontWeight: 'bolder' }}>{validationData!.reference_code_length}</span>
                </span>
              </li>
              <li className="summary-text">
                <span>
                  CDV Start Position: <span style={{ fontWeight: 'bolder' }}>{validationData!.cdv_start_position}</span>
                </span>
              </li>
              <li className="summary-text">
                <span>
                  Check Digit Number: <span style={{ fontWeight: 'bolder' }}>{validationData!.check_digit_number}</span>
                </span>
              </li>
            </Fragment>
          ) : null}
        </ul>
      </li>
    ));

  return (
    <div
      id="hook-form"
      // onSubmit={props.onSubmit}
      onKeyDown={handleKeyDown}
      className="ms-form-wizard ms-wizard-vertical style4-wizard"
      style={{ margin: '30px' }}
    >
      <div
        className="row"
        style={{
          margin: '20px',
        }}
      >
        <Ol
          style={{
            justifyContent: 'flex-start',
            height: 'auto',
            position: 'relative',
            margin: '10px',
          }}
          className="col-lg-3 mb-3"
          role="tablist"
        >
          {/*menu*/}
          {renderSteps()}
        </Ol>
        <div
          key={compState}
          className="content col-lg-8"
          style={{
            margin: '30px',

            overflowY: 'scroll',
          }}
        >
          {/* forms  */}
          {props.steps[compState].component}
        </div>
      </div>
    </div>
  );
}
