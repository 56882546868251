import { AxiosResponse } from 'axios';
import { MessageType } from '../@types/message_type';
import { ServerError } from '../@types/server_error';
import axios from '../utils/axios';

export type { MessageType } from '../@types/message_type';

export const fetchAll = async (): Promise<MessageType[]> => {
  const response = await axios.get('message-types');
  return response.data;
};

export const fetchOne = async (id: string | undefined): Promise<MessageType | null> => {
  try {
    const response = await axios.get(`message-types/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const updateOne = async (data: MessageType): Promise<AxiosResponse | ServerError> => {
  try {
    const response = await axios.put(`message-types/${data.id}`, data);
    return response;
  } catch (error) {
    return error as ServerError;
  }
};

export const insertOne = async (data: MessageType) => {
  try {
    const response = await axios.post(`message-types`, data);
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};
