import axios from '../utils/axios';
import { Channel } from '../@types/channel';

export type { Channel } from '../@types/channel';

export const fetchAll = async (): Promise<Channel[]> => {
  const response = await axios.get<Channel[]>('/channels');
  return response.data;
};

export const fetchOne = async (id: number): Promise<{ channel: Channel | null }> => {
  try {
    const response = await axios.get(`/channels/${id}`);
    return { channel: response.data };
  } catch (error) {
    console.error(error);
    return { channel: null };
  }
};

export const updateOne = async (data: Channel) => {
  try {
    const response = await axios.put(`/channels/${data.id}`, data);
    return { channel: response.data };
  } catch (error) {
    console.error(error);
    return { channel: null };
  }
};

export const insertOne = async (data: Channel) => {
  try {
    const response = await axios.post(`/channels`, { name: data.name });
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};
