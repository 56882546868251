import { useEffect, useState } from 'react';
import { Button, Form, Alert, InputGroup } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Spinner from '../../components/Spinner';
import { Channel, createChannel, fetchChannel, updateChannel } from '../../api';

const EditChannel = () => {
  const { id } = useParams();
  const creating = id === 'add';

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [data, setData] = useState<{ channel: Channel | null }>();
  const [saving, setSaving] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    if (creating) {
      // Create an object with empty values
      // NOTE(Jacques): Id does not exist yet - set it to undefined
      setData({channel: {id: undefined, name: "", configurations: []}})
      return;
    }

    // Or fetch existing object
    if (id) {
      const idAsNumber = parseInt(id);
      if (idAsNumber) {
        fetchChannel(idAsNumber).then((channelData) => {
          if (channelData) {
            setData(channelData)
          }
        });
      }
    }
  }, []);

  const onSubmit = (formData: any) => {
    if (!data) return;
    setSaving(true);

    // Merge new data with old data
    data.channel = { ...data.channel, ...formData };

    const save = creating ? createChannel : updateChannel;
    if (data && data.channel) {
      save(data.channel)
        .then((response) => {
          if (response === null) {
            setError('Something went wrong.');
            setSaving(false);
          } else { 
            // Success!
            navigate('/channels');
          }
        })
        .catch((error: any) => {
          setSaving(false);
          setError('Error: ' + error.message);
        });
    }
  };

  return (
    <div className="ms-content-wrapper">
      <div className="ms-panel col-xl-4 col-lg-6 col-md-8 col-sm-12">
        <div className="ms-panel-header">
          <h6>Channel | {creating ? 'Add' : 'Edit'}</h6>
        </div>
        <div className="ms-panel-body">
          {!data && <Spinner />}
          {data && (
            <Form onSubmit={handleSubmit(onSubmit)}>

              <Form.Group className="mb-3" controlId="channelID">
                <Form.Label>ID</Form.Label>
                <Form.Control type="number" disabled defaultValue={data.channel?.id} />
              </Form.Group>

              <Form.Group className="mb-3" controlId="channelEmail">
                <Form.Label>Name</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    type="text"
                    placeholder="Channel Name"
                    defaultValue={data.channel?.name}
                    isInvalid={errors.name}
                    {...register('name', { required: true })}
                  />
                  <Form.Control.Feedback type="invalid">Name is required</Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Alert variant="danger" show={error != null}>
                {error}
              </Alert>
              <div className="d-flex justify-content-end">
                <Link to="/channels">
                  <Button variant="outline-secondary" className="me-2">
                    Back
                  </Button>
                </Link>
                <Button type="submit" disabled={saving}>
                  {saving ? <Spinner size="sm" /> : 'Save'}
                </Button>
              </div>
            </Form>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditChannel;
