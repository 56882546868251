import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useState } from 'react';
import { Link } from 'react-router-dom';

interface OverflowMenuProps {
  id: number | undefined;
  type: string;
}

const OverflowMenu = ({ id, type }: OverflowMenuProps) => {
  const [visible, setVisible] = useState<boolean>(false);

  return (
    <div className="table-cell-menu" tabIndex={0} onFocus={handleFocus} onBlur={handleBlur}>
      <FontAwesomeIcon icon={solid('ellipsis-vertical')} />
      <div className="overflow-menu" style={{ display: visible ? 'block' : 'none' }}>
        <ul>
          <Link to={`/${type}/${id}`}>
            <li>Edit</li>
          </Link>
          {type === 'configuration' ? (
            <Link to={`/${type}/${id}/products`}>
              <li>Products</li>
            </Link>
          ) : null}
        </ul>
      </div>
    </div>
  );

  function handleFocus() {
    setVisible(true);
  }

  function handleBlur(event: any) {
    if (event.relatedTarget === null) setVisible(false);
    else if (event.relatedTarget.localName !== 'a') setVisible(false);
  }
};

export default OverflowMenu;
