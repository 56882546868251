import { useEffect, useState } from 'react';
import { Alert, InputGroup } from 'react-bootstrap';
import { Button, Form } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Spinner from '../../components/Spinner';
import { createProduct, fetchProduct, fetchTransactionTypes, updateProduct } from '../../api';
import { Product } from '../../@types/product';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../firebase';
import { TransactionType } from '../../@types/transaction_type';

const EditProduct = () => {
  const { id } = useParams();
  const creating = id === 'add';

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [data, setData] = useState<Product | null>(null);
  const [saving, setSaving] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [user, userError] = useAuthState(auth);
  const [transactionTypes, setTransactionTypes] = useState<Array<TransactionType> | null>(null);

  useEffect(() => {
    fetchTransactionTypes().then((data) => {
      if (data != null) {
        setTransactionTypes(data);
      }
    });

    if (creating) {
      // Create an object with empty values
      // setData({
      //   id: undefined,
      //   display_name: '',
      //   transaction_type_id: 0,
      //   prefix_code: 0,
      //   reference_code_legth: 0,
      //   cdv_start_position: 0,
      //   check_digit_number: 0,
      //   min_card_value: 0,
      //   max_card_value: 0,
      //   max_cash_value: 0,
      //   print_barcode_on_slip: false,
      //   print_airline_till_slip: false,
      //   mandatory: false,
      //   partial_payment_allowed: false,
      //   reference_label: null,
      //   created: new Date(),
      //   last_updated_at: new Date(),
      //   created_username: user!.email,
      //   updated_username: null,
      //   configurationProduct: [],
      //   transactions: null
      // });
      return;
    }

    fetchProduct(+id!).then((data) => {
      if (data) {
        console.log(data);
        setData(data);
      }
    });
  }, []);

  const onSubmit = (formData: any) => {
    if (!data) return;
    setSaving(true);

    // Merge new data with old data
    const newData = { ...data, ...formData, updated_username: user!.email };

    const save = creating ? createProduct : updateProduct;
    save(newData)
      .then((response) => {
        // if (response === null || response.data === undefined) {
        //   setError('Something went wrong...');
        //   setSaving(false);
        // } else if (response.status !== 200) {
        //   setError('Error: ' + response.data.message);
        //   setSaving(false);
        // } else {
        //   // Success!
        //   navigate('/products');
        // }
      })
      .catch((error: any) => {
        setSaving(false);
        setError('Error: ' + error.message);
      });
  };

  return (
    <div className="ms-content-wrapper">
      {/* <div className="ms-panel col-xl-4 col-lg-6 col-md-8 col-sm-12">
        <div className="ms-panel-header">
          <h6>Product | {creating ? 'Add' : 'Edit'}</h6>
        </div>
        <div className="ms-panel-body">
          {(!data || !transactionTypes) && <Spinner />}
          {data && transactionTypes && (
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group className="mb-3" controlId="formID">
                <Form.Label>ID</Form.Label>
                <Form.Control type="number" disabled defaultValue={data.id} />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formName">
                <Form.Label>Name</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    type="text"
                    placeholder="Product Name"
                    defaultValue={data.display_name ?? ""}
                    isInvalid={errors.name}
                    {...register('display_name', { required: true })}
                  />
                  <Form.Control.Feedback type="invalid">Name is required</Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formTransactionType">
                <Form.Label>Transaction Type</Form.Label>
                <Form.Select
                  aria-label="Select transaction type"
                  defaultValue={data.transaction_type_id ?? 0}
                  {...register('transaction_type_id', { required: true, valueAsNumber: true })}
                >
                  {
                    transactionTypes.map((v, index) => (<option key={index} value={v.id}>{v.name}</option>))
                  }
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formName">
                <Form.Label>Prefix Code</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    type="text"
                    placeholder="Prefix Code"
                    defaultValue={data.prefix_code ?? ""}
                    isInvalid={errors.prefix_code}
                    {...register('prefix_code', { required: true, valueAsNumber: true })}
                  />
                  <Form.Control.Feedback type="invalid">Prefix Code is required</Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formName">
                <Form.Label>Reference Code Length</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    type="text"
                    placeholder="Reference Code Length"
                    defaultValue={data.reference_code_legth ?? ""}
                    isInvalid={errors.reference_code_legth}
                    {...register('reference_code_legth', { required: true, valueAsNumber: true })}
                  />
                  <Form.Control.Feedback type="invalid">Reference Code Length is required</Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formName">
                <Form.Label>CDV Start Position</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    type="text"
                    placeholder="CDV Start Position"
                    defaultValue={data.cdv_start_position ?? ""}
                    isInvalid={errors.cdv_start_position}
                    {...register('cdv_start_position', { required: true, valueAsNumber: true })}
                  />
                  <Form.Control.Feedback type="invalid">CDV Start Position is required</Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formName">
                <Form.Label>Check Digit Number</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    type="text"
                    placeholder="Check Digit Number"
                    defaultValue={data.check_digit_number ?? ""}
                    isInvalid={errors.check_digit_number}
                    {...register('check_digit_number', { required: true, valueAsNumber: true })}
                  />
                  <Form.Control.Feedback type="invalid">Check Digit Number is required</Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formName">
                <Form.Label>Min Card Value</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    type="text"
                    placeholder="Min Card Value"
                    defaultValue={data.min_card_value ?? ""}
                    isInvalid={errors.min_card_value}
                    {...register('min_card_value', { required: true, valueAsNumber: true })}
                  />
                  <Form.Control.Feedback type="invalid">Min Card Value is required</Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formName">
                <Form.Label>Max Card Value</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    type="text"
                    placeholder="Max Card Value"
                    defaultValue={data.max_card_value ?? ""}
                    isInvalid={errors.max_card_value}
                    {...register('max_card_value', { required: true, valueAsNumber: true })}
                  />
                  <Form.Control.Feedback type="invalid">Max Card Value is required</Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formName">
                <Form.Label>Max Cash Value</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    type="text"
                    placeholder="Max Cash Value"
                    defaultValue={data.max_cash_value ?? ""}
                    isInvalid={errors.max_cash_value}
                    {...register('max_cash_value', { required: true, valueAsNumber: true })}
                  />
                  <Form.Control.Feedback type="invalid">Max Cash Value is required</Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formName">
                <Form.Label>Reference Label</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    type="text"
                    placeholder="Reference Label"
                    defaultValue={data.reference_label ?? ""}
                    isInvalid={errors.reference_label}
                    {...register('reference_label', { required: true })}
                  />
                  <Form.Control.Feedback type="invalid">Reference Label is required</Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              
              <ul className="d-flex flex-column p-0">
                <li className="p-1">
                  <label className="ms-switch">
                    <input {...register("print_barcode_on_slip")} type="checkbox" defaultChecked={data.print_barcode_on_slip} />
                    <span className="ms-switch-slider round" />
                  </label>
                  <span> Print barcode on slip </span>
                </li>
                <li className="p-1">
                  <label className="ms-switch">
                    <input {...register("print_airline_till_slip")} type="checkbox" defaultChecked={data.print_airline_till_slip} />
                    <span className="ms-switch-slider round" />
                  </label>
                  <span> Print airline till slip </span>
                </li>
                <li className="p-1">
                  <label className="ms-switch">
                    <input {...register("mandatory")} type="checkbox" defaultChecked={data.mandatory} />
                    <span className="ms-switch-slider round" />
                  </label>
                  <span> Mandatory </span>
                </li>
                <li className="p-1">
                  <label className="ms-switch">
                    <input {...register("partial_payment_allowed")} type="checkbox" defaultChecked={data.partial_payment_allowed} />
                    <span className="ms-switch-slider round" />
                  </label>
                  <span> Partial payment allowed </span>
                </li>
              </ul>

              <Alert variant="danger" show={error != null}>
                {error}
              </Alert>
              <div className="d-flex justify-content-end">
                <Link to="/products">
                  <Button variant="outline-secondary" className="me-2">
                    Back
                  </Button>
                </Link>
                <Button type="submit" disabled={saving}>
                  {saving ? <Spinner size="sm" /> : 'Save'}
                </Button>
              </div>
            </Form>
          )}
        </div>
      </div> */}
    </div>
  );
};

export default EditProduct;
