import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import OverflowMenu from '../../components/OverflowMenu';
import Spinner from '../../components/Spinner';
import { fetchAllProviders } from '../../api';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { Provider } from '../../@types/provider';

const Providers = () => {
  const [data, setData] = useState<Array<Provider> | null>(null);

  useEffect(() => {
    fetchAllProviders().then((data: Array<Provider> | null) => {
      setData(data);
    });
  }, []);

  const columns = [
    { name: 'ID', selector: (row: Provider) => row.id ?? '', sortable: true, maxWidth: '40px' },
    { name: 'Name', selector: (row: Provider) => row.display_name, sortable: true, maxWidth: '180px' },

    { sortable: false, button: true, cell: (row: Provider) => <OverflowMenu type="provider" id={row.id} /> },
  ];

  return (
    <div className="ms-content-wrapper">
      <div className="ms-panel">
        <div className="ms-panel-header d-flex justify-content-between align-items-center">
          <h6>Providers</h6>
          <Link to={`/provider/add`}>
            <Button variant="primary">Add</Button>
          </Link>
        </div>
        <div className="ms-panel-body">
          {!data && <Spinner />}
          {data && (
            <div className="table-responsive datatables row">
              <DataTableExtensions {...{ columns, data }} export={false} print={false}>
                <DataTable columns={columns} data={data} pagination responsive={true} highlightOnHover noHeader />
              </DataTableExtensions>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Providers;
