import axios from '../utils/axios';
import { Institution } from '../@types/institution';
import { AxiosResponse } from 'axios';

export type { Institution } from '../@types/institution';

export const fetchAll = async (): Promise<Institution[]> => {
  const response = await axios.get('/institutions');
  return response.data;
};

export const fetchOne = async (id: number): Promise<Institution> => {
  const response = await axios.get(`/institutions/${id}`);
  return response.data;
};

export const updateOne = async (data: Institution): Promise<AxiosResponse<Institution>> => {
  const response = await axios.put(`/institutions/${data.id}`, data);
  return response;
};

export const insertOne = async (data: Institution) => {
  try {
    const response = await axios.post(`/institutions`, data);
    return response;
  } catch (error) {
    console.log(error);
    return null;
  }
};
