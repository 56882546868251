import axios from '../utils/axios';

import { Validation, ValidationInput } from '../@types/validation';

export const fetchValidationByProductId = async (product_id: number): Promise<Validation> => {
  const response = await axios.get<Validation>(`validation/product/${product_id}`);
  return response.data;
};

export const updateValidation = async (validation: ValidationInput): Promise<Validation> => {
  const response = await axios.put<Validation>(`validation/${validation.id}`, validation);
  return response.data;
};
