import { useEffect, useState } from 'react';
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import toastr from 'toastr';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { instance } from '../../../events/event-objects';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchProduct, fetchValidationByProductId, updateValidation } from '../../../api';
import Spinner from '../../../components/Spinner';
import { errorMessageTextStyleOverride } from '../../../@types/errorMessages';
import { Validation, ValidationInput } from '../../../@types/validation';
import { messageService } from '../../../events/create.events';

const schema = yup
  .object({
    product_id: yup.number().required('Product Id is required'),
    prefix_code: yup.number().required('Prefix Code is required'),
    reference_code_length: yup.number().required('Reference Code Length is required'),
    cdv_start_position: yup.number().required('CDV Start Position is required'),
    check_digit_number: yup.number().required('Check Digit Number is required'),
  })
  .required();

const ValidationStep = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState<Validation>();
  const [configuration_id, setConfigurationId] = useState<number>();
  const [saving, setSaving] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = useForm<ValidationInput>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      product_id: +id!,
    },
  });

  useEffect(() => {
    console.log(errors);
  }, [errors]);

  useEffect(() => {
    if (id) {
      fetchValidationByProductId(+id)
        .then((validation) => {
          setData(validation);
        })
        .catch((error) => console.log(error));

      fetchProduct(+id)
        .then((product) => {
          setConfigurationId(product.configuration_id);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  const backEvent = () => {
    const event = {
      key: 'back',
    };

    instance().back(event);
  };

  toastr.options = {
    closeButton: false,
    debug: false,
    newestOnTop: false,
    progressBar: true,
    positionClass: 'toast-top-left',
    preventDuplicates: false,
    toastClass: 'success',
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
  };

  const onSubmit = (formData: ValidationInput) => {
    if (!isDirty) {
      navigate(`/configuration/${configuration_id}/products`);
      return;
    }

    if (formData) {
      formData = { ...data, ...formData };
      formData.id = data!.id;

      console.log('to save', formData);

      setSaving(true);

      updateValidation(formData)
        .then(() => {
          toastr.success('Validation Updated Successfully!');
          messageService.sendMessage('validationUpdated');
          navigate(`/configuration/${configuration_id}/products`);
        })
        .catch((error) => {
          setSaving(false);
          console.log(error);
        });
    }
  };

  return (
    <div className="ms-wizard-step">
      <h4>Validation</h4>
      {!data ? <Spinner /> : null}
      {data ? (
        <div className="row">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="col mb-3" controlId="id">
              <Form.Label>Id</Form.Label>
              <InputGroup>
                <Form.Control
                  disabled={true}
                  required
                  type="text"
                  defaultValue={data.id}
                  {...register('id', { required: true })}
                />
              </InputGroup>
            </Form.Group>

            <Row className="mb-3">
              <Form.Group as={Col} md="12" className="col" controlId="prefix_code">
                <Form.Label>Prefix Code</Form.Label>
                <InputGroup>
                  <Form.Control
                    className={errors.prefix_code?.message ? 'is-invalid' : ''}
                    style={{ textAlign: 'left' }}
                    required
                    type="number"
                    defaultValue={data.prefix_code}
                    {...register('prefix_code', { required: true })}
                  />
                </InputGroup>
                <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                  {errors.prefix_code?.message ? 'Prefix Code is required' : null}
                </div>
              </Form.Group>

              <Form.Group className="col mb-3" controlId="reference_code_length">
                <Form.Label>Reference Code Length</Form.Label>
                <InputGroup>
                  <Form.Control
                    className={errors.reference_code_length?.message ? 'is-invalid' : ''}
                    required
                    type="number"
                    defaultValue={data.reference_code_length}
                    {...register('reference_code_length')}
                  />
                </InputGroup>
                <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                  {errors.reference_code_length?.message ? 'Reference Code Length is required' : null}
                </div>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} md="12" className="col" controlId="cdv_start_position">
                <Form.Label>CDV Start Position</Form.Label>
                <InputGroup>
                  <Form.Control
                    className={errors.cdv_start_position?.message ? 'is-invalid' : ''}
                    style={{ textAlign: 'left' }}
                    required
                    type="number"
                    defaultValue={data.cdv_start_position}
                    {...register('cdv_start_position', { required: true })}
                  />
                </InputGroup>
                <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                  {errors.cdv_start_position?.message ? 'CDV Start Position is required' : null}
                </div>
              </Form.Group>

              <Form.Group className="col mb-3" controlId="check_digit_number">
                <Form.Label>Check Digit Number</Form.Label>
                <InputGroup>
                  <Form.Control
                    className={errors.check_digit_number?.message ? 'is-invalid' : ''}
                    required
                    type="number"
                    defaultValue={data.check_digit_number}
                    {...register('check_digit_number')}
                  />
                </InputGroup>
                <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                  {errors.check_digit_number?.message ? 'Check Digit Number is required' : null}
                </div>
              </Form.Group>
            </Row>

            <div className="d-flex justify-content-end">
              <Button onClick={backEvent} variant="outline-secondary" className="me-2">
                Back
              </Button>

              <Button type="submit" disabled={saving || !isValid}>
                {saving ? <Spinner size="sm" /> : 'Complete And Continue'}
              </Button>
            </div>
          </Form>
        </div>
      ) : null}
    </div>
  );
};

export default ValidationStep;
