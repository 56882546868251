import { useEffect, useState, CSSProperties } from 'react';
import { Button, Form, Alert, InputGroup } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Spinner from '../../components/Spinner';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Institution,
  InstitutionCategory,
  Country,
  createInstitution,
  fetchInstitution,
  fetchAllInstitutionTypes,
  fetchAllCountries,
  updateInstitution,
  fetchAllCategories,
} from '../../api';
import { InstitutionType } from '../../@types/institution';

const EditInstitution = () => {
  const { id } = useParams();
  const creating = id === 'add';

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    institution_category_id: Yup.number().required('Category is required').moreThan(0, 'Category is required'),
    institution_type_id: Yup.number()
      .required('Institution Type is required')
      .moreThan(0, 'Institution Type is required'),
    country_id: Yup.number().required('Country is required').moreThan(0, 'Country is required'),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions);

  const [categories, setCategories] = useState<InstitutionCategory[] | null>();
  const [types, setTypes] = useState<InstitutionType[] | null>();
  const [countries, setCountries] = useState<Country[] | null>();
  const [data, setData] = useState<Institution | null>();
  const [processing, setProcessing] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    fetchAllCategories().then((allCategories) => {
      setCategories(allCategories);
    });
    fetchAllInstitutionTypes().then((allTypes) => {
      setTypes(allTypes);
    });
    fetchAllCountries().then((allCountries) => {
      setCountries(allCountries);
    });

    if (creating) {
      // Create an object with empty values
      // NOTE(Jacques): Id does not exist yet - set it to undefined
      // setData({
      //   id: undefined,
      //   display_name: '',

      //   institution_category_id: undefined,
      //   institutionCategory: null,
      //   country_id: undefined,
      //   country: null,
      //   institution_type_id: undefined,
      //   institutionType: null,
      //   balance_enabled: false,
      //   balance_prompt_ind: false,
      //   configuration_product: [],
      // });
      return;
    }

    // Or fetch existing object
    if (id) {
      const idAsNumber = parseInt(id);
      fetchInstitution(idAsNumber).then((institutionData) => {
        if (institutionData) {
          setData(institutionData);
        }
      });
    }
  }, []);

  const onSubmit = (formData: any) => {
    if (!data) return;
    setProcessing(true);

    // Merge new data with old data
    const newData = { ...data, ...formData };
    setData(newData);

    const save = creating ? createInstitution : updateInstitution;
    if (newData) {
      save(newData)
        .then((response) => {
          if (response === null) {
            setError('Something went wrong.');
            setProcessing(false);
          } else {
            // Success!
            navigate('/institutions');
          }
        })
        .catch((error: any) => {
          setProcessing(false);
          setError('Error: ' + error.message);
        });
    }
  };

  const errorMessageTextStyleOverride: CSSProperties = {
    position: 'relative',
    textAlign: 'left',
  };

  return (
    <div className="ms-content-wrapper">
      <div className="ms-panel col-xl-4 col-lg-6 col-md-8 col-sm-12">
        <div className="ms-panel-header">
          <h6>Institution | {creating ? 'Add' : 'Edit'}</h6>
        </div>
        <div className="ms-panel-body">
          {!data && <Spinner />}
          {data && (
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group className="mb-3" controlId="institutionId">
                <Form.Label>ID</Form.Label>
                <Form.Control type="number" disabled defaultValue={data?.id} />
              </Form.Group>

              <Form.Group className="col mb-3" controlId="name">
                <Form.Label>Name</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    className={errors.name?.message ? 'is-invalid' : ''}
                    type="text"
                    placeholder="Institution Name"
                    defaultValue={data?.display_name}
                    {...register('name', { required: true })}
                  />
                  <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                    {errors.name?.message}
                  </div>
                </InputGroup>
              </Form.Group>

              <div>
                <Form.Label>Category</Form.Label>
                <Form.Group className="col mb-3" controlId="institution_category_id">
                  <Form.Select
                    className={errors.institution_category_id?.message ? 'is-invalid' : ''}
                    aria-label="Select category"
                    defaultValue={data.institution_category_id}
                    {...register('institution_category_id', { required: true, valueAsNumber: true })}
                  >
                    <option defaultValue={0} value={0}>
                      Please select an option
                    </option>
                    {categories?.map((category, index) => (
                      <option key={index} value={category.id}>
                        {category.display_name}{' '}
                      </option>
                    ))}
                  </Form.Select>
                  <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                    {errors.institution_category_id?.message}
                  </div>
                </Form.Group>
              </div>

              <div>
                <Form.Label>Type</Form.Label>
                <Form.Group className="col mb-3" controlId="institution_type_id">
                  <Form.Select
                    className={errors.institution_type_id?.message ? 'is-invalid' : ''}
                    aria-label="Select type"
                    defaultValue={data.institution_type_id}
                    {...register('institution_type_id', { required: true, valueAsNumber: true })}
                  >
                    <option defaultValue={0} value={0}>
                      Please select an option
                    </option>
                    {types?.map((type, index) => (
                      <option key={index} value={type.id}>
                        {type.name}{' '}
                      </option>
                    ))}
                  </Form.Select>
                  <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                    {errors.institution_type_id?.message}
                  </div>
                </Form.Group>
              </div>

              <div>
                <Form.Label>Country</Form.Label>
                <Form.Group className="col mb-3" controlId="country_id">
                  <Form.Select
                    className={errors.country_id?.message ? 'is-invalid' : ''}
                    aria-label="Select country"
                    defaultValue={data.country_id}
                    {...register('country_id', { required: true, valueAsNumber: true })}
                  >
                    <option defaultValue={0} value={0}>
                      Please select an option
                    </option>
                    {countries?.map((country, index) => (
                      <option key={index} value={country.id}>
                        {country.name}{' '}
                      </option>
                    ))}
                  </Form.Select>
                  <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                    {errors.country_id?.message}
                  </div>
                </Form.Group>
              </div>

              <Form.Group className="pt-3 pb-3">
                <label className="ms-switch">
                  <input type="checkbox" defaultChecked={data.balance_enabled} {...register('balance_enabled')} />
                  <span className="ms-switch-slider ms-switch-primary round" />
                </label>
                <span className="text-dark p-2">Balance Enabled</span>
              </Form.Group>
              <Form.Group>
                <label className="ms-switch">
                  <input type="checkbox" defaultChecked={data.balance_prompt_ind} {...register('balance_prompt_ind')} />
                  <span className="ms-switch-slider ms-switch-primary round" />
                </label>
                <span className="text-dark p-2">Balance Prompt</span>
              </Form.Group>
              <Alert variant="danger" show={error != null}>
                {error}
              </Alert>
              <div className="d-flex justify-content-end">
                <Link to="/institutions">
                  <Button variant="outline-secondary" className="me-2">
                    Back
                  </Button>
                </Link>
                <Button type="submit" disabled={processing}>
                  {processing ? <Spinner size="sm" /> : 'Save'}
                </Button>
              </div>
            </Form>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditInstitution;
