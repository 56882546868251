import { CSSProperties, useContext, useEffect, useState } from 'react';
import { Button, Form, Alert, InputGroup } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Spinner from '../../components/Spinner';
import { Configuration, Country, updateConfiguration } from '../../api';
import { fetchOne } from '../../api/Configurations';
import { fetchAll as getCountries } from '../../api/country';
import { Channel, fetchAll as getChannels } from '../../api/Channels';
import { Company, fetchAll as getCompanies } from '../../api/Companies';
import { AuthContext } from '../../context/JWTContext';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

type ConfigurationEditInput = {
  country_id: number;
  company_id: number;
  channel_id: number;
  updated_username: string;
};

const schema = yup
  .object({
    country_id: yup.number().notOneOf([0]).required('Please Select a Country'),
    company_id: yup.number().notOneOf([0]).required('Please Select a Company'),
    channel_id: yup.number().notOneOf([0]).required('Please Select a Channel'),
  })
  .required();

const ConfigurationEdit = () => {
  const { configurationId } = useParams();

  const userContext = useContext(AuthContext);

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = useForm<ConfigurationEditInput>({
    mode: 'onChange',

    resolver: yupResolver(schema),
  });

  const [data, setData] = useState<Configuration>();
  const [countries, setCountries] = useState<Country[]>([]);
  const [channels, setChannels] = useState<Channel[]>([]);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [saving, setSaving] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    getCountries()
      .then((countries) => setCountries(countries))
      .catch((error) => setError(error.message));
    getChannels()
      .then((channels) => setChannels(channels))
      .catch((error) => setError(error.message));
    getCompanies()
      .then((companies) => setCompanies(companies))
      .catch((error) => setError(error.message));

    // Or fetch existing object
    if (configurationId) {
      const idAsNumber = parseInt(configurationId);

      if (idAsNumber) {
        fetchOne(idAsNumber).then((configuration) => {
          if (configuration) {
            setData(configuration);
          }
        });
      }
    }
  }, []);

  const onSubmit = (formData: ConfigurationEditInput) => {
    setSaving(true);

    if (formData && userContext?.user?.email) {
      formData = { ...data, ...formData };
      formData.updated_username = userContext.user.email;
      updateConfiguration(formData)
        .then((response) => {
          if (response === null) {
            setError('Something went wrong.');
            setSaving(false);
          } else {
            // Success!
            navigate('/configurations');
          }
        })
        .catch((error: any) => {
          setSaving(false);
          setError('Error: ' + error.message);
        });
    }
  };

  const errorMessageTextStyleOverride: CSSProperties = {
    position: 'relative',
    textAlign: 'left',
  };

  return (
    <div className="ms-content-wrapper">
      <div className="ms-panel col-xl-4 col-lg-6 col-md-8 col-sm-12">
        <div className="ms-panel-header">
          <h6>Configuration | Edit</h6>
        </div>
        <div className="ms-panel-body">
          {/* not creating but no data loaded yet, show spinner */}
          {!data && (!countries.length || !channels.length || !companies.length) ? <Spinner /> : null}
          {/* data has been loaded */}
          {data && countries.length && channels.length && companies.length ? (
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group className="mb-3" controlId="configuration_id">
                <Form.Label>ID</Form.Label>
                <Form.Control type="number" disabled defaultValue={data.id} />
              </Form.Group>

              <Form.Group className="col mb-3" controlId="country_id">
                <Form.Select
                  className={errors.country_id?.message ? 'is-invalid' : ''}
                  aria-label="Select Country"
                  defaultChecked={true}
                  defaultValue={data.country_id}
                  {...register('country_id', { required: true, valueAsNumber: true })}
                >
                  <option defaultValue={data.country_id} value={0}>
                    Please select an option
                  </option>
                  {countries?.map((country, index) => (
                    <option key={index} value={country.id}>
                      {country.name}{' '}
                    </option>
                  ))}
                </Form.Select>
                {
                  <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                    {errors.country_id ? 'please select a country' : ''}
                  </div>
                }
              </Form.Group>
              <Form.Group className="col mb-3" controlId="company_id">
                <Form.Select
                  className={errors.company_id?.message ? 'is-invalid' : ''}
                  aria-label="Select Company"
                  defaultChecked={true}
                  defaultValue={data.company_id}
                  {...register('company_id', { required: true, valueAsNumber: true })}
                >
                  <option defaultValue={data.company_id} value={0}>
                    Please select an option
                  </option>
                  {companies?.map((company, index) => (
                    <option key={index} value={company.id}>
                      {company.name}{' '}
                    </option>
                  ))}
                </Form.Select>
                {
                  <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                    {errors.company_id ? 'please select company' : ''}
                  </div>
                }
              </Form.Group>

              <Form.Group className="col mb-3" controlId="channel_id">
                <Form.Select
                  className={errors.channel_id?.message ? 'is-invalid' : ''}
                  aria-label="Select Channel"
                  defaultChecked={true}
                  defaultValue={data.channel_id}
                  {...register('channel_id', { required: true, valueAsNumber: true })}
                >
                  <option defaultValue={data.channel_id} value={0}>
                    Please select an option
                  </option>
                  {channels?.map((channel, index) => (
                    <option key={index} value={channel.id}>
                      {channel.name}{' '}
                    </option>
                  ))}
                </Form.Select>
                {
                  <div className="-feedbackinvalid" style={errorMessageTextStyleOverride}>
                    {errors.channel_id ? 'please select a channel' : ''}
                  </div>
                }
              </Form.Group>

              <Alert variant="danger" show={!!error}>
                {error}
              </Alert>
              <div className="d-flex justify-content-end">
                <Link to="/configurations">
                  <Button variant="outline-secondary" className="me-2">
                    Back
                  </Button>
                </Link>
                <Button type="submit" disabled={saving || !isValid || !isDirty}>
                  {saving ? <Spinner size="sm" /> : 'Save'}
                </Button>
              </div>
            </Form>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ConfigurationEdit;
