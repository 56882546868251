import { Dispatch, SetStateAction, useState } from 'react';

import { Form, Alert, InputGroup, Modal, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Spinner from '../../components/Spinner';
import * as yup from 'yup';
import { createInstitutionType, updateInstitution, updateInstitutionType } from '../../api';
import { yupResolver } from '@hookform/resolvers/yup';
import { messageService } from '../../events/create.events';
import toastr from 'toastr';
import { InstitutionTypeCreateInput } from '../../@types/institution';

toastr.options = {
  closeButton: false,
  debug: false,
  newestOnTop: false,
  progressBar: true,
  positionClass: 'toast-top-left',
  preventDuplicates: false,
  toastClass: 'success',
  showEasing: 'swing',
  hideEasing: 'linear',
  showMethod: 'fadeIn',
  hideMethod: 'fadeOut',
};

const schema = yup
  .object({
    name: yup.string().required('Name is a required field'),
  })
  .required();

const InstitutionTypeModal = (props: {
  showInstitutionType: boolean;
  setShowInstitutionType: Dispatch<SetStateAction<boolean>>;
}) => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<InstitutionTypeCreateInput>({
    resolver: yupResolver(schema),
  });

  const [saving, setSaving] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);

  const fireEvent = () => {
    messageService.sendMessage('newInstitutionType');
  };

  const onCreateInstitutionType = (formData: InstitutionTypeCreateInput) => {
    setSaving(true);

    createInstitutionType(formData)
      .then((response) => {
        if (!response) {
          setError('Something went wrong.');
          setSaving(false);
        } else if (response.status !== 200) {
          setError('Error: ' + response.status);
          setSaving(false);
        } else {
          // Close Modal!
          toastr.info('Institution Type Created');
          setSaving(false);
          reset({ name: '', code: '' });
          props.setShowInstitutionType(false);
          fireEvent();
        }
      })
      .catch((error: any) => {
        setSaving(false);
        setError('Error: ' + error.message);
      });
  };

  return (
    <Modal
      show={props.showInstitutionType}
      onHide={() => {
        reset({ name: '', code: '' }, { keepValues: false });
        props.setShowInstitutionType(false);
        setError(null);
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="bg-primary">
        <h3 className="modal-title has-icon text-white">Add New Institution Type</h3>

        <button
          type="button"
          className="close"
          onClick={() => {
            reset({ name: '', code: '' }, { keepValues: false });
            props.setShowInstitutionType(false);
            setError(null);
          }}
        >
          <span>×</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="name">
            <Form.Label>Name</Form.Label>
            <InputGroup>
              <Form.Control type="text" placeholder="InstitutionType Name" {...register('name', { required: true })} />
              <Form.Control.Feedback type="invalid">Name is required</Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3" controlId="code">
            <Form.Label>Code</Form.Label>
            <InputGroup>
              <Form.Control type="text" placeholder="Code" {...register('code', { required: false })} />
            </InputGroup>
          </Form.Group>
          <Alert variant="danger" show={error != null}>
            {error}
          </Alert>
          <div className="d-flex justify-content-end">
            <Button
              type="button"
              className="btn btn-light"
              onClick={() => {
                reset({ name: '', code: '' }, { keepValues: false });
                props.setShowInstitutionType(false);
                setError(null);
              }}
            >
              Cancel
            </Button>
            <Button onClick={handleSubmit(onCreateInstitutionType)} className="btn btn-primary shadow-none">
              {saving ? <Spinner size="sm" /> : 'Save'}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default InstitutionTypeModal;
