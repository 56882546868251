import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, first, map, take } from 'rxjs/operators';

export type stepEventType = {
  key: string;
};

const subject = new BehaviorSubject<stepEventType>({ key: '' });
export const messageService = {
  sendMessage: (message: string) => subject.next({ key: message }),
  clearMessages: () => subject.next({ key: '' }),
  getMessage: () => subject.asObservable(),
};

export const createEvents = () => {
  const subject = new Subject<stepEventType>();

  const dataCreated = (e: stepEventType) => {
    subject.next(e);
  };

  const listen = (key: string): Observable<stepEventType> =>
    subject.pipe(
      first(),
      filter((e: stepEventType) => e.key === key)
    );

  const complete = () => subject.complete();

  return { listen, dataCreated, complete };
};
