import { Pie } from 'react-chartjs-2';
import { Chart, ArcElement } from 'chart.js';
import { fetchProviderProducts } from '../api';
import { useEffect, useState } from 'react';
import { ProviderProducts } from '../@types/reports';
import Spinner from '../components/Spinner';
import { useNavigate } from 'react-router-dom';
Chart.register(ArcElement);

type dataSetTypes = {
  label: string;
  data: number[];
  backgroundColor: string[];
  borderColor: string[];
  borderWidth: number;
};

type dataTypes = {
  labels: string[];
  datasets: dataSetTypes[];
};

const chartData: dataTypes = {
  labels: [],
  datasets: [
    {
      label: '# of Products',
      data: [],
      backgroundColor: [],
      borderColor: [],
      borderWidth: 1,
    },
  ],
};

const Home = () => {
  const navigate = useNavigate();
  const [reportData, setReportData] = useState<ProviderProducts[]>([]);
  const randomBetween = (min: number, max: number) => min + Math.floor(Math.random() * (max - min + 1));

  const options = {
    // This chart will not respond to mousemove, etc
    onClick: (e: any) => {
      navigate(`/configuration/25001/products`, {
        state: { institutionId: reportData[e.chart.tooltip.dataPoints[0].dataIndex].id },
      });
    },
  };
  useEffect(() => {
    fetchProviderProducts()
      .then((res) => {
        const data = res.filter((inst) => inst._count.product > 0);
        setReportData(data);
        chartData.labels.length = 0;
        chartData.datasets[0].data.length = 0;
        chartData.datasets[0].backgroundColor.length = 0;
        chartData.datasets[0].borderColor.length = 0;
        data.map((provider) => {
          const r = randomBetween(0, 255);
          const g = randomBetween(0, 255);
          const b = randomBetween(0, 255);

          chartData.labels.push(provider.display_name);
          chartData.datasets[0].data.push(provider._count.product);
          chartData.datasets[0].backgroundColor.push(`rgb(${r},${g},${b}, 0.2)`);
          chartData.datasets[0].borderColor.push(`rgb(${r},${g},${b}, 1)`);
        });
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="container vh-100 d-flex justify-content-center align-items-center">
      <div className="col-xl-6 col-md-8">
        {!reportData.length && <Spinner />}
        {reportData?.length ? (
          <div className="ms-panel ">
            <div className="ms-panel-header">
              <h6>Institution Products</h6>
            </div>
            <div className="ms-panel-body">
              <Pie data={chartData} options={options} />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Home;
