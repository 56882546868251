import { useContext, useEffect, useState } from 'react';
import { Alert, Button, Form, InputGroup } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { AuthContext } from '../../../context/JWTContext';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Institution,
  Product,
  Switch,
  Provider,
  fetchProduct,
  fetchAllSwitches,
  fetchAllInstitutions,
  fetchAllProviders,
  VatTransactionType,
  fetchTransactionTypes,
  fetchAllVatTransactionTypes,
  fetchAllDenominationTypes,
  updateProduct,
} from '../../../api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import toastr from 'toastr';
import { instance } from '../../../events/event-objects';
import SwitchModal from '../../switches/SwitchModal';
import InstitutionModal from '../../institutions/InstitutionModal';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';
import { TransactionType } from '../../../@types/transaction_type';
import { DenominationType } from '../../../@types/denomination_type';
import Spinner from '../../../components/Spinner';
import { errorMessageTextStyleOverride } from '../../../@types/errorMessages';
import SwitchModalEdit from '../../switches/SwitchModalEdit';
import InstitutionModalEdit from '../../institutions/InstitutionModalEdit';
import { messageService } from '../../../events/create.events';
import { ProductEditInput } from '../../../@types/product';
import ProviderModal from '../../providers/ProviderModal';
import ProviderModalEdit from '../../providers/ProviderModalEdit';

const schema = yup
  .object({
    display_name: yup.string().required('Display Name is required'),
    provider_id: yup.number().required('Please Select a Provider'),
    provider_product_code: yup.string().required('Provider Product Code is required'),
    switch_id: yup.number().required('Please Select a Switch'),
    institution_id: yup.number().required('Please Select a Institution'),
    transaction_type_id: yup.number().required('Please Select a Transaction Type'),
    vat_transaction_type_id: yup.number().required('Please Select a Vat Transaction Type'),
    denomination_type_id: yup.number().required('Please Select a Denomination Type'),
  })
  .required();

const ProductStep = () => {
  const { id } = useParams();
  const { state } = useLocation();

  const userContext = useContext(AuthContext);

  const [data, setData] = useState<Product>();
  const [error, setError] = useState<any>(null);
  const [showProvider, setShowProvider] = useState<boolean>(false);
  const [showEditProvider, setShowEditProvider] = useState<boolean>(false);
  const [providerToEditId, setProviderToEditId] = useState<number>();
  const [showSwitch, setShowSwitch] = useState<boolean>(false);
  const [showEditSwitch, setShowEditSwitch] = useState<boolean>(false);
  const [switchToEditId, setSwitchToEditId] = useState<number>();
  const [showInstitution, setShowInstitution] = useState<boolean>(false);
  const [showInstitutionEdit, setShowInstitutionEdit] = useState<boolean>(false);
  const [institutionToEditId, setInstitutionToEditId] = useState<number>();
  const [saving, setSaving] = useState<boolean>(false);
  const [providers, setProviders] = useState<Provider[]>([]);
  const [switchData, setSwitches] = useState<Switch[]>([]);
  const [institutionData, setInstitutions] = useState<Institution[]>([]);
  const [transactionTypes, setTransactionTypes] = useState<TransactionType[]>([]);
  const [vatTransactionTypes, setVatTransactionTypes] = useState<VatTransactionType[]>([]);
  const [denominationTypes, setDenominationTypes] = useState<DenominationType[]>([]);
  const [effectiveStartDate, setEffectiveStartDate] = useState<Date>();
  const [effectiveEndDate, setEffectiveEndDate] = useState<Date>();

  const {
    control,
    register,
    getValues,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = useForm<ProductEditInput>({
    mode: 'onChange',

    resolver: yupResolver(schema),
  });

  //if state then we create
  useEffect(() => {
    if (state) {
      fireEvent();
    }
  }, [state]);

  //get all data
  useEffect(() => {
    if (id) {
      getAllProviders();

      getAllSwitches();

      getAllInstitutions();

      getAllTransactionTypes();

      getAllVatTransactionTypes();

      getAllDenominationTypes();
      getAllProducts(+id);
    }
  }, [id]);

  const getAllProducts = (id: number) => {
    fetchProduct(+id)
      .then((product) => {
        console.log('the product: ', product);
        setSwitchToEditId(product.switch_id);
        setInstitutionToEditId(product.institution_id);
        setProviderToEditId(product.provider_id);
        setEffectiveStartDate(new Date(product.effective_start!));
        setEffectiveEndDate(new Date(product.effective_end!));
        setData(product);
      })
      .catch((err) => console.log(err));
  };

  const getAllProviders = async () => {
    setProviders(await fetchAllProviders());
  };

  const getAllSwitches = async () => {
    setSwitches(await fetchAllSwitches());
  };

  const getAllInstitutions = async () => {
    setInstitutions(await fetchAllInstitutions());
  };

  const getAllTransactionTypes = async () => {
    setTransactionTypes(await fetchTransactionTypes());
  };

  const getAllVatTransactionTypes = async () => {
    setVatTransactionTypes(await fetchAllVatTransactionTypes());
  };

  const getAllDenominationTypes = async () => {
    setDenominationTypes(await fetchAllDenominationTypes());
  };

  const fireEvent = () => {
    const event = {
      key: 'next',
    };

    instance().next(event);
  };

  useEffect(() => {
    const dataSubscription = messageService.getMessage().subscribe({
      next: (val) => {
        switch (val.key) {
          case 'newProvider':
            fetchAllProviders()
              .then((providers) => {
                setProviders(providers);
              })

              .catch((err) => console.log(err));
            break;
          case 'providerUpdated':
            fetchAllProviders()
              .then((providers) => {
                setProviders(providers);
              })
              .catch((err) => console.log(err));
            fetchProduct(+id!)
              .then((product) => {
                setData(product);
                setEffectiveStartDate(new Date(product.effective_start!));
                setEffectiveEndDate(new Date(product.effective_end!));
              })
              .catch((err) => console.log(err));
            break;
          case 'newSwitch':
            fetchAllSwitches()
              .then((switches) => {
                setSwitches(switches);
              })

              .catch((err) => console.log(err));
            break;
          case 'switchUpdated':
            fetchAllSwitches()
              .then((switches) => {
                setSwitches(switches);
              })
              .catch((err) => console.log(err));
            fetchProduct(+id!)
              .then((product) => {
                setData(product);
                setEffectiveStartDate(new Date(product.effective_start!));
                setEffectiveEndDate(new Date(product.effective_end!));
              })
              .catch((err) => console.log(err));
            break;
          case 'newInstitution':
            fetchAllInstitutions()
              .then((instatutions) => {
                setInstitutions(instatutions);
                toastr.info('Institution created successfully');
              })

              .catch((err) => console.log(err));
            break;
          case 'institutionUpdated':
            fetchAllInstitutions()
              .then((institutions) => setInstitutions(institutions))
              .catch((err) => console.log(err));
            fetchProduct(+id!)
              .then((product) => {
                setData(product);
                setEffectiveStartDate(new Date(product.effective_start!));
                setEffectiveEndDate(new Date(product.effective_end!));
              })
              .catch((err) => console.log(err));
            break;

          default:
            break;
        }
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => {
        messageService.clearMessages();
      },
    });

    return () => {
      dataSubscription.unsubscribe();
    };
  }, []);

  const storeProduct = (formData: ProductEditInput) => {
    setError(null);
    if (!isDirty) {
      fireEvent();
      return;
    }

    if (formData && userContext?.user?.email) {
      // formData = { ...data, ...formData };
      formData.updated_username = userContext.user.email;
      formData.configuration_id = data!.configuration_id!;
      formData.created_username = data!.created_username!;
      formData.id = data!.id!;
      formData.effective_start = effectiveStartDate!;
      formData.effective_end = effectiveEndDate!;
      formData.provider_product_code.toString();

      setSaving(true);

      updateProduct(formData)
        .then((response) => {
          // Success!
          toastr.success('Product Updated Successfully!');
          fireEvent();
          messageService.sendMessage('productUpdated');
        })
        .catch((error: any) => {
          setSaving(false);
          setError(error);
          console.log(error);
        });
    }
  };

  toastr.options = {
    closeButton: false,
    debug: false,
    newestOnTop: false,
    progressBar: true,
    positionClass: 'toast-top-left',
    preventDuplicates: false,
    toastClass: 'success',
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
  };

  const hasAllData = () => {
    if (
      providers.length &&
      switchData.length &&
      institutionData.length &&
      transactionTypes.length &&
      vatTransactionTypes.length &&
      denominationTypes.length
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="ms-wizard-step">
      <h4>Product</h4>

      {!data && !hasAllData() ? <Spinner /> : null}
      {data && hasAllData() ? (
        <div className="row">
          <Form onSubmit={handleSubmit(storeProduct)}>
            <div>
              <Form.Label>Id</Form.Label>
              <Form.Group className="col mb-3" controlId="id">
                <InputGroup>
                  <Form.Control
                    disabled={true}
                    required
                    type="text"
                    defaultValue={data.id}
                    {...register('id', { required: true })}
                  />
                </InputGroup>
              </Form.Group>
            </div>

            <div>
              <Form.Label>Product Name</Form.Label>
              <Form.Group className="col mb-3" controlId="display_name">
                <InputGroup>
                  <Form.Control
                    required
                    type="text"
                    defaultValue={data.display_name}
                    placeholder="Display Name"
                    {...register('display_name', { required: true })}
                  />
                  <Form.Control.Feedback type="invalid">{errors.display_name?.message}</Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </div>

            <div>
              <Form.Group className="col mb-3" controlId="provider_id">
                <Form.Label>Provider</Form.Label>
                <div className="d-flex justify-content-between">
                  <Form.Select
                    className={errors.provider_id?.message ? 'is-invalid' : ''}
                    aria-label="Select provider"
                    defaultChecked={true}
                    defaultValue={data.provider_id}
                    {...register('provider_id', {
                      required: true,
                      valueAsNumber: true,
                      onChange(event) {
                        setProviderToEditId(event.target.value);
                      },
                    })}
                  >
                    <option defaultValue={0} value={0}>
                      Please select an option
                    </option>
                    {providers?.map((provider, index) => (
                      <option key={index} value={provider.id}>
                        {provider.display_name}{' '}
                      </option>
                    ))}
                  </Form.Select>
                  <div className="col-sm-2 d-flex justify-content-center">
                    {providerToEditId ? (
                      <button
                        onClick={() => {
                          setShowEditProvider(true);
                        }}
                        type="button"
                        className="ms-btn-icon-outline btn-pill btn-light"
                        style={{ marginLeft: '10px' }}
                      >
                        <FontAwesomeIcon icon={solid('edit')} />
                      </button>
                    ) : null}

                    <button
                      onClick={() => setShowProvider(true)}
                      type="button"
                      className="ms-btn-icon-outline btn-pill btn-light"
                      style={{ marginLeft: '10px' }}
                    >
                      <FontAwesomeIcon icon={solid('add')} />
                    </button>
                  </div>
                </div>
                <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                  {errors.display_name?.message}
                </div>
              </Form.Group>
            </div>
            <ProviderModal
              configuration_id={data.configuration_id!}
              showProvider={showProvider}
              setShowProvider={setShowProvider}
            />
            <ProviderModalEdit
              provider_id={providerToEditId!}
              showProvider={showEditProvider}
              setShowEditProvider={setShowEditProvider}
            />

            <div>
              <Form.Label>Provider Product Code</Form.Label>
              <Form.Group className="col mb-3" controlId="provider_product_code">
                <InputGroup>
                  <Form.Control
                    required
                    type="text"
                    className={errors.provider_product_code?.message ? 'is-invalid col-sm-10' : 'col-sm-10'}
                    defaultValue={data.provider_product_code}
                    placeholder="Provider Product Code"
                    {...register('provider_product_code', { required: true })}
                  />
                  <Form.Control.Feedback type="invalid">{errors.provider_product_code?.message}</Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </div>

            <div>
              <Form.Label>Reference Label</Form.Label>
              <Form.Group className="col mb-3" controlId="reference_label">
                <InputGroup>
                  <Form.Control
                    type="text"
                    defaultValue={data.reference_label}
                    placeholder="Reference Label"
                    {...register('reference_label', { required: false })}
                  />
                  <Form.Control.Feedback type="invalid">{errors.reference_label?.message}</Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </div>

            <div>
              <Form.Label>Switch</Form.Label>
              <Form.Group className="col mb-3" controlId="switch_id">
                <div className="d-flex justify-content-between">
                  <Form.Select
                    className={errors.switch_id?.message ? 'is-invalid col-sm-10' : 'col-sm-10'}
                    aria-label="Select switch"
                    defaultChecked={true}
                    defaultValue={data.switch_id}
                    {...register('switch_id', {
                      required: true,
                      valueAsNumber: true,
                      onChange(event) {
                        console.log();
                        setSwitchToEditId(event.target.value);
                      },
                    })}
                  >
                    <option defaultValue={0} value={0}>
                      Please select an option
                    </option>
                    {switchData?.map((confiSwitch, index) => (
                      <option key={index} value={confiSwitch.id}>
                        {confiSwitch.name}{' '}
                      </option>
                    ))}
                  </Form.Select>
                  <div className="col-sm-2 d-flex justify-content-center">
                    {switchToEditId ? (
                      <button
                        onClick={() => {
                          setShowEditSwitch(true);
                        }}
                        type="button"
                        className="ms-btn-icon-outline btn-pill btn-light"
                        style={{ marginLeft: '10px' }}
                      >
                        <FontAwesomeIcon icon={solid('edit')} />
                      </button>
                    ) : null}

                    <button
                      onClick={() => setShowSwitch(true)}
                      type="button"
                      className="ms-btn-icon-outline btn-pill btn-light"
                      style={{ marginLeft: '10px' }}
                    >
                      <FontAwesomeIcon icon={solid('add')} />
                    </button>
                  </div>
                </div>
                <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                  {errors.switch_id?.message}
                </div>
              </Form.Group>
            </div>
            <SwitchModalEdit
              switchId={switchToEditId!}
              showSwitch={showEditSwitch}
              setShowEditSwitch={setShowEditSwitch}
            />
            <SwitchModal showSwitch={showSwitch} setShowSwitch={setShowSwitch} />

            <div>
              <Form.Label>Institution</Form.Label>
              <Form.Group className="col mb-3" controlId="institution_id">
                <div className="d-flex justify-content-between">
                  <Form.Select
                    className={errors.institution_id?.message ? 'is-invalid col-sm-10' : 'col-sm-10'}
                    aria-label="Select switch"
                    defaultChecked={true}
                    defaultValue={data.institution_id}
                    {...register('institution_id', {
                      required: true,
                      valueAsNumber: true,
                      onChange(event) {
                        console.log();
                        setInstitutionToEditId(event.target.value);
                      },
                    })}
                  >
                    <option defaultValue={0} value={0}>
                      Please select an option
                    </option>
                    {institutionData?.map((institution, index) => (
                      <option key={index} value={institution.id}>
                        {institution.display_name}{' '}
                      </option>
                    ))}
                  </Form.Select>
                  <div className="col-sm-2 d-flex justify-content-center">
                    {institutionToEditId ? (
                      <button
                        onClick={() => setShowInstitutionEdit(true)}
                        type="button"
                        className="ms-btn-icon-outline btn-pill btn-light"
                        style={{ marginLeft: '10px' }}
                      >
                        <FontAwesomeIcon icon={solid('edit')} />
                      </button>
                    ) : null}

                    <button
                      onClick={() => setShowInstitution(true)}
                      type="button"
                      className="ms-btn-icon-outline btn-pill btn-light "
                      style={{ marginLeft: '10px' }}
                    >
                      <FontAwesomeIcon icon={solid('add')} />
                    </button>
                  </div>
                  <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                    {errors.institution_id?.message}
                  </div>
                </div>
              </Form.Group>
            </div>
            <InstitutionModalEdit
              institutionId={institutionToEditId!}
              showInstitutionEdit={showInstitutionEdit}
              setShowInstitutionEdit={setShowInstitutionEdit}
            />
            <InstitutionModal showInstitution={showInstitution} setShowInstitution={setShowInstitution} />

            <div>
              <Form.Label>Transaction Type</Form.Label>
              <Form.Group className="col mb-3" controlId="transaction_type_id">
                <Form.Select
                  className={errors.transaction_type_id?.message ? 'is-invalid' : ''}
                  aria-label="Select Transaction Type"
                  defaultChecked={true}
                  defaultValue={data.transaction_type_id}
                  {...register('transaction_type_id', { required: true, valueAsNumber: true })}
                >
                  <option defaultValue={0} value={0}>
                    Please select an option
                  </option>
                  {transactionTypes?.map((transactionTypes, index) => (
                    <option key={index} value={transactionTypes.id}>
                      {transactionTypes.name}{' '}
                    </option>
                  ))}
                </Form.Select>
                <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                  {errors.transaction_type_id?.message}
                </div>
              </Form.Group>
            </div>

            <div>
              <Form.Label>Vat Transaction Type</Form.Label>
              <Form.Group className="col mb-3" controlId="vat_transaction_type_id">
                <Form.Select
                  className={errors.vat_transaction_type_id?.message ? 'is-invalid' : ''}
                  aria-label="Select Vat Transaction Type"
                  defaultChecked={true}
                  defaultValue={data.vat_transaction_type_id}
                  {...register('vat_transaction_type_id', { required: true, valueAsNumber: true })}
                >
                  <option defaultValue={0} value={0}>
                    Please select an option
                  </option>
                  {vatTransactionTypes?.map((vatTransactionTypes, index) => (
                    <option key={index} value={vatTransactionTypes.id}>
                      {vatTransactionTypes.code}{' '}
                    </option>
                  ))}
                </Form.Select>
                <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                  {errors.vat_transaction_type_id?.message}
                </div>
              </Form.Group>
            </div>

            <div>
              <Form.Group className="col mb-3" controlId="denomination_type_id">
                <Form.Label>Denomination Type</Form.Label>
                <Form.Select
                  className={errors.denomination_type_id?.message ? 'is-invalid' : ''}
                  aria-label="Select Vat Transaction Type"
                  defaultChecked={true}
                  defaultValue={data.denomination_type_id}
                  {...register('denomination_type_id', { required: true, valueAsNumber: true })}
                >
                  <option defaultValue={0} value={0}>
                    Please select an option
                  </option>
                  {denominationTypes?.map((denominationTypes, index) => (
                    <option key={index} value={denominationTypes.id}>
                      {denominationTypes.name}{' '}
                    </option>
                  ))}
                </Form.Select>
                <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                  {errors.denomination_type_id?.message}
                </div>
              </Form.Group>
            </div>

            <div>
              <Form.Group className="col mb-3" controlId="effective_start">
                <Form.Label>Effective start date</Form.Label>
                <Controller
                  control={control}
                  name="effective_start"
                  render={({ field }) => (
                    <DatePicker
                      selected={effectiveStartDate}
                      placeholderText="Select date"
                      onChange={(date) => {
                        setEffectiveStartDate(date!);
                        field.onChange(date);
                      }}
                      timeInputLabel="Time:"
                      dateFormat="yyyy/MM/dd h:mm aa"
                      showTimeInput
                      className={errors.effective_start?.message ? 'is-invalid no-border' : 'no-border'}
                    />
                  )}
                />
                <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                  {errors.effective_start?.message}
                </div>
              </Form.Group>
            </div>

            <div>
              <Form.Group className="col mb-3" controlId="effective_end">
                <Form.Label>Effective end date</Form.Label>
                <Controller
                  control={control}
                  name="effective_end"
                  render={({ field }) => (
                    <DatePicker
                      selected={effectiveEndDate}
                      placeholderText="Select date"
                      onChange={(date) => {
                        setEffectiveEndDate(date!);
                        field.onChange(date);
                      }}
                      timeInputLabel="Time:"
                      dateFormat="yyyy/MM/dd h:mm aa"
                      showTimeInput
                      className={errors.effective_end?.message ? 'is-invalid no-border' : 'no-border'}
                    />
                  )}
                />
                <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                  {errors.effective_end?.message}
                </div>
              </Form.Group>
            </div>

            <Form.Group className="pt-3 pb-3" controlId="active_ind">
              <label className="ms-switch">
                <input type="checkbox" defaultChecked={!!data.active_ind} {...register('active_ind')} />
                <span className="ms-switch-slider ms-switch-primary round" />
              </label>
              <span className="text-dark p-2">Active</span>
            </Form.Group>

            <Form.Group className="pb-3" controlId="visible_ind">
              <label className="ms-switch">
                <input type="checkbox" defaultChecked={!!data.visible_ind} {...register('visible_ind')} />
                <span className="ms-switch-slider ms-switch-primary round" />
              </label>
              <span className="text-dark p-2">Visible</span>
            </Form.Group>

            <Form.Group className="pb-3" controlId="pilot_ind">
              <label className="ms-switch">
                <input type="checkbox" defaultChecked={!!data.pilot_ind} {...register('pilot_ind')} />
                <span className="ms-switch-slider ms-switch-primary round" />
              </label>
              <span className="text-dark p-2">Pilot</span>
            </Form.Group>

            <Form.Group className="pb-3" controlId="print_barcode_on_slip">
              <label className="ms-switch">
                <input
                  type="checkbox"
                  defaultChecked={!!data.print_barcode_on_slip}
                  {...register('print_barcode_on_slip')}
                />
                <span className="ms-switch-slider ms-switch-primary round" />
              </label>
              <span className="text-dark p-2">Print Barcode On Slip</span>
            </Form.Group>

            <Form.Group className="pb-3" controlId="print_airline_till_slip">
              <label className="ms-switch">
                <input
                  type="checkbox"
                  defaultChecked={!!data.print_airline_till_slip}
                  {...register('print_airline_till_slip')}
                />
                <span className="ms-switch-slider ms-switch-primary round" />
              </label>
              <span className="text-dark p-2">Print Airline Till Slip</span>
            </Form.Group>

            <Form.Group className="pb-3" controlId="partial_payment_allowed">
              <label className="ms-switch">
                <input
                  type="checkbox"
                  defaultChecked={!!data.partial_payment_allowed}
                  {...register('partial_payment_allowed')}
                />
                <span className="ms-switch-slider ms-switch-primary round" />
              </label>
              <span className="text-dark p-2">Partial Payment Allowed</span>
            </Form.Group>
            <Alert variant="danger" show={!!error}>
              {error}
            </Alert>

            <div className="d-flex justify-content-end">
              <Button type="submit" disabled={saving || !isValid}>
                {saving ? <Spinner size="sm" /> : 'Continue'}
              </Button>
            </div>
          </Form>
        </div>
      ) : null}
    </div>
  );
};

export default ProductStep;
