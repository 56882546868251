import { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { LoadingProvider } from './context/LoadingContext';
import './App.scss';
import React from 'react';
import Wrapper from './layouts/Wrapper';
import { AuthProvider } from './context/JWTContext';

// Preloader
//const Preloader = React.lazy(() => import('./layouts/Preloader'));

const Login = React.lazy(() => import('./pages/auth/Login'));

function App() {

  return (
    <AuthProvider>
      <LoadingProvider>
        <Router>
          <Suspense fallback={<div></div>}>
            <Routes>
              <Route path="*" element={<Wrapper />} />
              <Route path="/login" element={<Login />} />
            </Routes>
          </Suspense>
        </Router>
      </LoadingProvider>
    </AuthProvider>
  );
}

export default App;
