import axios from '../utils/axios';

import { TransactionLimit, TransactionLimitInput } from '../@types/transaction-limits';

export const fetchTransactionLimitsByProductId = async (product_id: number): Promise<TransactionLimit> => {
  const response = await axios.get<TransactionLimit>(`transaction-limits/product/${product_id}`);
  return response.data;
};

export const createTransactionLimits = async (transactionLimit: TransactionLimitInput): Promise<TransactionLimit> => {
  const response = await axios.post<TransactionLimit>(`transaction-limits/`, transactionLimit);
  return response.data;
};

export const updateTransactionLimits = async (transactionLimit: TransactionLimitInput): Promise<TransactionLimit> => {
  const response = await axios.put<TransactionLimit>(`transaction-limits/${transactionLimit.id}`, transactionLimit);
  return response.data;
};
