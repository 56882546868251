import React, { Component } from 'react';

import MultiStep from '../../components/MultiStep';
import ProductCreateStep from './steps/ProductCreateStep';

const steps = [{ component: <ProductCreateStep />, text: 'Product' }];

const prevStyle = {};
const nextStyle = {};
class StepsCreate extends Component {
  //handleStepChange = (currentStep) => {};
  render() {
    return <MultiStep steps={steps} prevStyle={prevStyle} nextStyle={nextStyle} />;
  }
}

export default StepsCreate;
