import { CSSProperties, Dispatch, SetStateAction, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';

import { Form, Alert, InputGroup, Modal, Button } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import Spinner from '../../components/Spinner';
import * as yup from 'yup';
import {
  Country,
  createInstitution,
  fetchAllCategories,
  fetchAllCountries,
  fetchAllInstitutionTypes,
  fetchInstitution,
  updateInstitution,
} from '../../api';
import { Institution, InstitutionCategory, InstitutionType } from '../../@types/institution';
import { yupResolver } from '@hookform/resolvers/yup';
import { messageService } from '../../events/create.events';
import toastr from 'toastr';
import { take } from 'rxjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import InstitutionTypeModal from '../institution-types/InstitutionTypeModal';
import InstitutionTypeModalEdit from '../institution-types/InstitutionTypeModalEdit';

toastr.options = {
  closeButton: false,
  debug: false,
  newestOnTop: false,
  progressBar: true,
  positionClass: 'toast-top-left',
  preventDuplicates: false,
  toastClass: 'success',
  showEasing: 'swing',
  hideEasing: 'linear',
  showMethod: 'fadeIn',
  hideMethod: 'fadeOut',
};

type InstitutionCreateInput = Pick<
  Institution,
  | 'id'
  | 'display_name'
  | 'external_code'
  | 'institution_category_id'
  | 'country_id'
  | 'institution_type_id'
  | 'balance_enabled'
  | 'balance_prompt_ind'
  | 'effective_start'
  | 'effective_end'
>;

const schema = yup
  .object({
    display_name: yup.string().required('Name is a required field'),
    external_code: yup.string().required('External Code is a required field'),
    institution_category_id: yup.number().required('Please select a category'),
    country_id: yup.number().required('Please select a country'),
    institution_type_id: yup.number().required('Please select a type'),
    balance_enabled: yup.boolean(),
    balance_prompt_ind: yup.boolean(),
  })
  .required();

const InstitutionModalEdit = (props: {
  showInstitutionEdit: boolean;
  setShowInstitutionEdit: Dispatch<SetStateAction<boolean>>;
  institutionId: number;
}) => {
  const [saving, setSaving] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);

  const [categories, setCategories] = useState<InstitutionCategory[] | null>();
  const [types, setTypes] = useState<InstitutionType[] | null>();
  const [countries, setCountries] = useState<Country[] | null>();
  const [data, setData] = useState<Institution>();
  const [effectiveStartDate, setEffectiveStartDate] = useState<Date>();
  const [effectiveEndDate, setEffectiveEndDate] = useState<Date>();
  const [showInstitutionType, setShowInstitutionType] = useState<boolean>(false);
  const [showInstitutionTypeEdit, setShowInstitutionTypeEdit] = useState<boolean>(false);
  const [institutionTypeToEditId, setInstitutionTypeToEditId] = useState<number>();

  useEffect(() => {
    fetchAllCategories().then((allCategories) => {
      setCategories(allCategories);
    });
    fetchAllInstitutionTypes().then((allTypes) => {
      setTypes(allTypes);
    });

    fetchAllCountries().then((allCountries) => {
      setCountries(allCountries);
    });
  }, []);

  useEffect(() => {
    const institutionTypeSubscription = messageService.getMessage().subscribe({
      next: (val) => {
        switch (val.key) {
          case 'newInstitutionType':
            fetchAllInstitutionTypes()
              .then((institutionTypes) => {
                setTypes(institutionTypes);
              })

              .catch((err) => console.log(err));
            break;
          case 'institutionTypeUpdated':
            fetchAllInstitutionTypes()
              .then((institutionTypes) => {
                setTypes(institutionTypes);
              })

              .catch((err) => console.log(err));
            break;

          default:
            break;
        }
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => {
        messageService.clearMessages();
      },
    });

    return () => {
      institutionTypeSubscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (props.showInstitutionEdit) {
      fetchInstitution(props.institutionId).then((institution) => {
        setData(institution);
        setEffectiveStartDate(new Date(institution.effective_start));
        setEffectiveEndDate(new Date(institution.effective_end));
      });
    }
  }, [props.showInstitutionEdit, props.institutionId]);

  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<InstitutionCreateInput>({
    resolver: yupResolver(schema),
  });

  const errorMessageTextStyleOverride: CSSProperties = {
    position: 'relative',
    textAlign: 'left',
  };

  const onInstitutionUpdate = (formData: InstitutionCreateInput) => {
    setSaving(true);

    console.log(formData);
    formData.effective_start = effectiveStartDate!.toString();
    formData.effective_end = effectiveEndDate!.toString();
    formData.id = props.institutionId;
    console.log(formData);
    updateInstitution(formData)
      .then((response) => {
        if (!response) {
          setError('Something went wrong.');
          setSaving(false);
        } else if (response.status !== 200) {
          setError('Error: ' + response.status);
          setSaving(false);
        } else {
          // Close Modal!
          setSaving(false);
          toastr.info('Institution Updated');
          reset({}, { keepDefaultValues: false, keepValues: false, keepErrors: false });
          setData(undefined);
          props.setShowInstitutionEdit(false);
          setError(null);
          messageService.sendMessage('institutionUpdated');
        }
      })
      .catch((error: any) => {
        setSaving(false);
        console.log('I get EHre', error);
        setError('Error: ' + error.message);
      });
  };

  return (
    <Modal
      show={props.showInstitutionEdit}
      onHide={() => {
        reset({}, { keepDefaultValues: false, keepValues: false, keepErrors: false });
        setData(undefined);
        setError(null);
        props.setShowInstitutionEdit(false);
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <Modal.Header className="bg-primary">
        <h3 className="modal-title has-icon text-white">Update Institution</h3>

        <button
          type="button"
          className="close"
          onClick={() => {
            reset({}, { keepDefaultValues: false, keepValues: false, keepErrors: false });
            setData(undefined);
            setError(null);
            props.setShowInstitutionEdit(false);
          }}
        >
          <span>×</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        {!data ? <Spinner /> : null}
        {data ? (
          <Form>
            <Form.Group className="mb-3" controlId="id">
              <Form.Label>Id</Form.Label>
              <InputGroup>
                <Form.Control
                  defaultValue={props.institutionId}
                  disabled={true}
                  type="number"
                  {...register('id', { required: true })}
                />
                <Form.Control.Feedback type="invalid">Id is required</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>

            <Form.Group className="mb-3" controlId="external_code">
              <Form.Label>External Code</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  className={errors.external_code?.message ? 'is-invalid' : ''}
                  type="text"
                  placeholder="External Code"
                  defaultValue={data.external_code}
                  {...register('external_code', { required: true })}
                />
                <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                  {errors.external_code?.message}
                </div>
              </InputGroup>
            </Form.Group>

            <Form.Group className="col mb-3" controlId="display_name">
              <Form.Label>Name</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  defaultValue={data.display_name}
                  className={errors.display_name?.message ? 'is-invalid' : ''}
                  type="text"
                  placeholder="Institution Name"
                  {...register('display_name', { required: true })}
                />
                <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                  {errors.display_name?.message}
                </div>
              </InputGroup>
            </Form.Group>

            <div>
              <Form.Label>Category</Form.Label>
              <Form.Group className="col mb-3" controlId="institution_category_id">
                <InputGroup hasValidation>
                  <Form.Select
                    defaultValue={data.institution_category_id}
                    className={errors.institution_category_id?.message ? 'is-invalid' : ''}
                    aria-label="Select category"
                    {...register('institution_category_id', { required: true, valueAsNumber: true })}
                  >
                    <option defaultValue={0} value={0}>
                      Please select an option
                    </option>
                    {categories?.map((category, index) => (
                      <option key={index} value={category.id}>
                        {category.display_name}{' '}
                      </option>
                    ))}
                  </Form.Select>
                  <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                    {errors.institution_category_id?.message}
                  </div>
                </InputGroup>
              </Form.Group>
            </div>

            <div className="d-flex justify-content-between">
              <Form.Group className="col-sm-8 mb-3" controlId="institution_type_id">
                <Form.Label>Institution Type</Form.Label>
                <Form.Select
                  className={errors.institution_type_id?.message ? 'is-invalid' : ''}
                  aria-label="Select type"
                  {...register('institution_type_id', {
                    required: true,
                    valueAsNumber: true,
                    onChange(event) {
                      setInstitutionTypeToEditId(event.target.value);
                    },
                  })}
                >
                  <option defaultValue={0} value={0}>
                    Please select an option
                  </option>
                  {types?.map((type, index) => (
                    <option key={index} value={type.id}>
                      {type.name}{' '}
                    </option>
                  ))}
                </Form.Select>
                <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                  {errors.institution_type_id?.message}
                </div>
              </Form.Group>
              <div className="col-sm-2 d-flex justify-content-center" style={{ alignItems: 'center' }}>
                {institutionTypeToEditId ? (
                  <button
                    onClick={() => {
                      setShowInstitutionTypeEdit(true);
                    }}
                    type="button"
                    className="ms-btn-icon-outline btn-pill btn-light"
                    style={{ marginLeft: '10px' }}
                  >
                    <FontAwesomeIcon icon={solid('edit')} />
                  </button>
                ) : null}

                <button
                  onClick={() => setShowInstitutionType(true)}
                  type="button"
                  className="ms-btn-icon-outline btn-pill btn-light"
                  style={{ marginLeft: '10px' }}
                >
                  <FontAwesomeIcon icon={solid('add')} />
                </button>
              </div>
            </div>
            <InstitutionTypeModal
              setShowInstitutionType={setShowInstitutionType}
              showInstitutionType={showInstitutionType}
            />
            <InstitutionTypeModalEdit
              institutionTypeId={institutionTypeToEditId!}
              setShowEditInstitutionType={setShowInstitutionTypeEdit}
              showInstitutionTypeEdit={showInstitutionTypeEdit}
            />

            <div>
              <Form.Label>Country</Form.Label>
              <Form.Group className="col mb-3" controlId="country_id">
                <Form.Select
                  className={errors.country_id?.message ? 'is-invalid' : ''}
                  aria-label="Select country"
                  defaultValue={data.country_id}
                  {...register('country_id', { required: true, valueAsNumber: true })}
                >
                  <option defaultValue={0} value={0}>
                    Please select an option
                  </option>
                  {countries?.map((country, index) => (
                    <option key={index} value={country.id}>
                      {country.name}{' '}
                    </option>
                  ))}
                </Form.Select>
                <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                  {errors.country_id?.message}
                </div>
              </Form.Group>
            </div>

            <div>
              <Form.Group className="col mb-3" controlId="effective_start">
                <Form.Label>Effective start date</Form.Label>
                <Controller
                  control={control}
                  name="effective_start"
                  render={({ field }) => (
                    <DatePicker
                      selected={effectiveStartDate}
                      placeholderText="Select date"
                      onChange={(date) => {
                        setEffectiveStartDate(date!);
                        field.onChange(date);
                      }}
                      timeInputLabel="Time:"
                      dateFormat="yyyy/MM/dd h:mm aa"
                      showTimeInput
                      className={errors.effective_start?.message ? 'is-invalid no-border' : 'no-border'}
                    />
                  )}
                />
                <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                  {errors.effective_start?.message}
                </div>
              </Form.Group>
            </div>

            <div>
              <Form.Group className="col mb-3" controlId="effective_end">
                <Form.Label>Effective end date</Form.Label>
                <Controller
                  control={control}
                  name="effective_end"
                  render={({ field }) => (
                    <DatePicker
                      selected={effectiveEndDate}
                      placeholderText="Select date"
                      onChange={(date) => {
                        setEffectiveEndDate(date!);
                        field.onChange(date);
                      }}
                      timeInputLabel="Time:"
                      dateFormat="yyyy/MM/dd h:mm aa"
                      showTimeInput
                      className={errors.effective_end?.message ? 'is-invalid no-border' : 'no-border'}
                    />
                  )}
                />
                <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                  {errors.effective_end?.message}
                </div>
              </Form.Group>
            </div>

            <Form.Group className="pt-3 pb-3">
              <label className="ms-switch">
                <input type="checkbox" defaultChecked={!!data.balance_enabled} {...register('balance_enabled')} />
                <span className="ms-switch-slider ms-switch-primary round" />
              </label>
              <span className="text-dark p-2">Balance Enabled</span>
            </Form.Group>
            <Form.Group>
              <label className="ms-switch">
                <input defaultChecked={!!data.balance_prompt_ind} type="checkbox" {...register('balance_prompt_ind')} />
                <span className="ms-switch-slider ms-switch-primary round" />
              </label>
              <span className="text-dark p-2">Balance Prompt</span>
            </Form.Group>
            <Alert variant="danger" show={!!error}>
              {error}
            </Alert>
            <div className="d-flex justify-content-end">
              <Button
                type="button"
                className="btn btn-light"
                onClick={() => {
                  reset({}, { keepDefaultValues: false, keepValues: false, keepErrors: false });
                  setData(undefined);
                  setError(null);
                  props.setShowInstitutionEdit(false);
                }}
              >
                Cancel
              </Button>
              <Button onClick={handleSubmit(onInstitutionUpdate)} className="btn btn-primary shadow-none">
                {saving ? <Spinner size="sm" /> : 'Save'}
              </Button>
            </div>
          </Form>
        ) : null}
      </Modal.Body>
    </Modal>
  );
};

export default InstitutionModalEdit;
