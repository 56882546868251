import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Form, Alert, InputGroup, Modal, Button, Col, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import Spinner from '../../components/Spinner';
import * as yup from 'yup';
import { fetchProvider, Provider, updateProvider } from '../../api';
import { yupResolver } from '@hookform/resolvers/yup';
import toastr from 'toastr';
import { messageService } from '../../events/create.events';
import { errorMessageTextStyleOverride } from '../../@types/errorMessages';

toastr.options = {
  closeButton: false,
  debug: false,
  newestOnTop: false,
  progressBar: true,
  positionClass: 'toast-top-left',
  preventDuplicates: false,
  toastClass: 'success',
  showEasing: 'swing',
  hideEasing: 'linear',
  showMethod: 'fadeIn',
  hideMethod: 'fadeOut',
};

const schema = yup
  .object({
    display_name: yup.string().required('Display Name is a required field'),
    external_code: yup.string().required('External Code is a required field'),
    effective_start: yup.string().required('Start Date is a required field'),
    effective_end: yup.string().required('End Date is a required field'),
  })
  .required();

const ProviderModalEdit = (props: {
  showProvider: boolean;
  setShowEditProvider: Dispatch<SetStateAction<boolean>>;
  provider_id: number;
}) => {
  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<Provider>({
    resolver: yupResolver(schema),
  });

  const [saving, setSaving] = useState<boolean>(false);
  const [providerData, setProviderData] = useState<Provider>();
  const [error, setError] = useState<any>(null);
  const [effectiveStartDate, setEffectiveStartDate] = useState<Date>();
  const [effectiveEndDate, setEffectiveEndDate] = useState<Date>();

  useEffect(() => {
    if (props.showProvider) {
      fetchProvider(+props.provider_id)
        .then((providerData) => {
          setProviderData(providerData);
          setEffectiveStartDate(new Date(providerData.effective_start!));
          setEffectiveEndDate(new Date(providerData.effective_end!));
        })
        .catch((error) => setError(error));
    }
  }, [props.showProvider, props.provider_id]);

  const onEditProvider = (formData: Provider) => {
    setSaving(true);
    formData.id = providerData!.id!;
    formData.configuration_id = providerData!.configuration_id!;
    formData.effective_start = effectiveStartDate;
    formData.effective_end = effectiveEndDate;
    console.log(formData);
    updateProvider(formData)
      .then((response) => {
        if (!response) {
          setError('Something went wrong.');
          setSaving(false);
        } else if (response.status !== 200) {
          setError('Error: ' + response.status);
          setSaving(false);
        } else {
          // Close Modal!
          setSaving(false);
          console.log('send message here');
          messageService.sendMessage('providerUpdated');
          toastr.info('Provider Updated');
          reset({}, { keepDefaultValues: false, keepValues: false });
          setProviderData(undefined);
          props.setShowEditProvider(false);
        }
      })
      .catch((error: any) => {
        setSaving(false);
        setError('Error: ' + error.message);
      });
  };

  return (
    <Modal
      show={props.showProvider}
      onHide={() => {
        reset({}, { keepDefaultValues: false, keepValues: false });
        setProviderData(undefined);
        setError(null);
        props.setShowEditProvider(false);
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="bg-primary">
        <h3 className="modal-title has-icon text-white">Update Provider</h3>

        <button
          type="button"
          className="close"
          onClick={() => {
            reset({}, { keepDefaultValues: false, keepValues: false });
            setProviderData(undefined);
            setError(null);
            props.setShowEditProvider(false);
          }}
        >
          <span>×</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        {!providerData && <Spinner />}
        {providerData ? (
          <Form>
            <Row>
              <Form.Group as={Col} md="12" className="col" controlId="id">
                <Form.Label>Id</Form.Label>
                <InputGroup>
                  <Form.Control
                    disabled
                    defaultValue={providerData.id}
                    type="text"
                    {...register('id', { required: true })}
                  />
                </InputGroup>
              </Form.Group>
            </Row>

            <Row>
              <Form.Group as={Col} md="12" className="col" controlId="display_name">
                <Form.Label>Display Name</Form.Label>
                <InputGroup>
                  <Form.Control
                    defaultValue={providerData.display_name}
                    className={errors.display_name?.message ? 'is-invalid' : ''}
                    type="text"
                    placeholder="Display Name"
                    {...register('display_name', { required: true })}
                  />
                  <Form.Control.Feedback type="invalid">Display Name is required</Form.Control.Feedback>
                  <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                    {errors.display_name?.message ? 'Display Name is required' : null}
                  </div>
                </InputGroup>
              </Form.Group>
            </Row>

            <Row>
              <Form.Group as={Col} md="12" className="col" controlId="external_code">
                <Form.Label>External Code</Form.Label>
                <InputGroup>
                  <Form.Control
                    placeholder="External Code"
                    defaultValue={providerData.external_code}
                    className={errors.external_code?.message ? 'is-invalid' : ''}
                    required
                    type="text"
                    {...register('external_code', { required: true })}
                  />
                  <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                    {errors.external_code?.message ? 'External Code is required' : null}
                  </div>
                </InputGroup>
              </Form.Group>
            </Row>

            <Row>
              <Form.Group className="col mb-3" controlId="effective_start">
                <Form.Label>Effective start date</Form.Label>
                <Controller
                  control={control}
                  name={'effective_start'}
                  defaultValue={effectiveStartDate}
                  render={({ field }) => (
                    <DatePicker
                      selected={effectiveStartDate}
                      placeholderText="Select date"
                      onChange={(date) => {
                        setEffectiveStartDate(date!);
                        field.onChange(date);
                      }}
                      timeInputLabel="Time:"
                      dateFormat="yyyy/MM/dd h:mm aa"
                      showTimeInput
                      className={errors.effective_start?.message ? 'is-invalid no-border' : 'no-border'}
                    />
                  )}
                />
                <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                  {errors.effective_start?.message ? 'Effective start date is required' : null}
                </div>
              </Form.Group>
            </Row>

            <Row>
              <Form.Group className="col mb-3" controlId="effective_end">
                <Form.Label>Effective end date</Form.Label>
                <Controller
                  control={control}
                  name={'effective_end'}
                  defaultValue={effectiveEndDate}
                  render={({ field }) => (
                    <DatePicker
                      selected={effectiveEndDate}
                      placeholderText="Select date"
                      onChange={(date) => {
                        setEffectiveEndDate(date!);
                        field.onChange(date);
                      }}
                      timeInputLabel="Time:"
                      dateFormat="yyyy/MM/dd h:mm aa"
                      showTimeInput
                      className={errors.effective_end?.message ? 'is-invalid no-border' : 'no-border'}
                    />
                  )}
                />
                <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                  {errors.effective_start?.message ? 'Effective start date is required' : null}
                </div>
              </Form.Group>
            </Row>

            <Form.Group className="pt-3 pb-3" controlId="exclusive_prefix_ind">
              <label className="ms-switch">
                <input
                  defaultChecked={providerData.exclusive_prefix_ind}
                  type="checkbox"
                  {...register('exclusive_prefix_ind')}
                />
                <span className="ms-switch-slider ms-switch-primary round" />
              </label>
              <span className="text-dark p-2">Exclusive Prefix</span>
            </Form.Group>

            <Form.Group className="pb-3" controlId="allow_balance_enquiry_ind">
              <label className="ms-switch">
                <input
                  defaultChecked={providerData.allow_balance_enquiry_ind}
                  type="checkbox"
                  {...register('allow_balance_enquiry_ind')}
                />
                <span className="ms-switch-slider ms-switch-primary round" />
              </label>
              <span className="text-dark p-2">Allow Balance Enquiry</span>
            </Form.Group>

            <Form.Group className="pb-3" controlId="reference_switching_ind">
              <label className="ms-switch">
                <input
                  defaultChecked={providerData.reference_switching_ind}
                  type="checkbox"
                  {...register('reference_switching_ind')}
                />
                <span className="ms-switch-slider ms-switch-primary round" />
              </label>
              <span className="text-dark p-2">Reference Switching</span>
            </Form.Group>

            <Alert variant="danger" show={error != null}>
              {error}
            </Alert>
            <div className="d-flex justify-content-end">
              <Button
                type="button"
                className="btn btn-light"
                onClick={() => {
                  setError(null);
                  reset({}, { keepValues: false, keepErrors: false });
                  props.setShowEditProvider(false);
                }}
              >
                Cancel
              </Button>
              <Button
                disabled={saving || !isValid}
                onClick={handleSubmit(onEditProvider)}
                className="btn btn-primary shadow-none"
              >
                {saving ? <Spinner size="sm" /> : 'Save'}
              </Button>
            </div>
          </Form>
        ) : null}
      </Modal.Body>
    </Modal>
  );
};

export default ProviderModalEdit;
