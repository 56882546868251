import { CSSProperties, Dispatch, SetStateAction, useEffect, useState } from 'react';
import DateTimePicker from 'react-datetime-picker';

import { Form, Alert, InputGroup, Modal, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Spinner from '../../components/Spinner';
import * as yup from 'yup';
import { Country, createInstitution, fetchAllCategories, fetchAllCountries, fetchAllInstitutionTypes } from '../../api';
import { Institution, InstitutionCategory, InstitutionType } from '../../@types/institution';
import { yupResolver } from '@hookform/resolvers/yup';
import { messageService } from '../../events/create.events';
import toastr from 'toastr';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import InstitutionTypeModal from '../institution-types/InstitutionTypeModal';
import InstitutionModalEdit from './InstitutionModalEdit';
import { take } from 'rxjs';
import InstitutionTypeModalEdit from '../institution-types/InstitutionTypeModalEdit';

toastr.options = {
  closeButton: false,
  debug: false,
  newestOnTop: false,
  progressBar: true,
  positionClass: 'toast-top-left',
  preventDuplicates: false,
  toastClass: 'success',
  showEasing: 'swing',
  hideEasing: 'linear',
  showMethod: 'fadeIn',
  hideMethod: 'fadeOut',
};

type InstitutionCreateInput = Pick<
  Institution,
  | 'display_name'
  | 'external_code'
  | 'institution_category_id'
  | 'country_id'
  | 'institution_type_id'
  | 'balance_enabled'
  | 'balance_prompt_ind'
  | 'effective_start'
  | 'effective_end'
>;

const schema = yup
  .object({
    display_name: yup.string().required('Name is a required field'),
    external_code: yup.string().required('External Code is a required field'),
    institution_category_id: yup.number().required('Please select a category'),
    country_id: yup.number().required('Please select a country'),
    institution_type_id: yup.number().required('Please select a type'),
    balance_enabled: yup.boolean(),
    balance_prompt_ind: yup.boolean(),
    effective_start: yup.string().required('Start Date is a required field'),
    effective_end: yup.string().required('End Date is a required field'),
  })
  .required();

const InstitutionModal = (props: {
  showInstitution: boolean;
  setShowInstitution: Dispatch<SetStateAction<boolean>>;
}) => {
  const [saving, setSaving] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);

  const [categories, setCategories] = useState<InstitutionCategory[] | null>();
  const [types, setTypes] = useState<InstitutionType[] | null>();
  const [countries, setCountries] = useState<Country[] | null>();
  const [showInstitutionType, setShowInstitutionType] = useState<boolean>(false);
  const [showInstitutionTypeEdit, setShowInstitutionTypeEdit] = useState<boolean>(false);
  const [institutionTypeToEditId, setInstitutionTypeToEditId] = useState<number>();

  useEffect(() => {
    fetchAllCategories().then((allCategories) => {
      setCategories(allCategories);
    });
    fetchAllInstitutionTypes().then((allTypes) => {
      setTypes(allTypes);
    });

    fetchAllCountries().then((allCountries) => {
      setCountries(allCountries);
    });
  }, []);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<InstitutionCreateInput>({
    defaultValues: {
      external_code: '',
      display_name: '',
      effective_start: '',
      effective_end: '',
    },
    resolver: yupResolver(schema),
  });

  const errorMessageTextStyleOverride: CSSProperties = {
    position: 'relative',
    textAlign: 'left',
  };

  const fireEvent = () => {
    messageService.sendMessage('newInstitution');
  };

  useEffect(() => {
    const institutionTypeSubscription = messageService.getMessage().subscribe({
      next: (val) => {
        switch (val.key) {
          case 'newInstitutionType':
            fetchAllInstitutionTypes()
              .then((institutionTypes) => {
                setTypes(institutionTypes);
              })

              .catch((err) => console.log(err));
            break;
          case 'institutionTypeUpdated':
            fetchAllInstitutionTypes()
              .then((institutionTypes) => {
                setTypes(institutionTypes);
              })

              .catch((err) => console.log(err));
            break;

          default:
            break;
        }
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => {
        messageService.clearMessages();
      },
    });

    return () => {
      institutionTypeSubscription.unsubscribe();
    };
  }, []);

  const onInstitutionCreate = (formData: InstitutionCreateInput) => {
    setSaving(true);

    // Merge new data with old data
    const newData = { ...formData };

    createInstitution(newData)
      .then((response) => {
        if (!response) {
          setError('Something went wrong.');
          setSaving(false);
        } else if (response.status !== 200) {
          setError('Error: ' + response.status);
          setSaving(false);
        } else {
          // Close Modal!
          setSaving(false);
          reset({ display_name: '' });
          toastr.info('Institution Created Successfully');
          props.setShowInstitution(false);
          fireEvent();
        }
      })
      .catch((error: any) => {
        setSaving(false);
        setError('Error: ' + error.message);
      });
  };

  return (
    <Modal
      show={props.showInstitution}
      onHide={() => props.setShowInstitution(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable={true}
      autoFocus={true}
    >
      <Modal.Header className="bg-primary">
        <h3 className="modal-title has-icon text-white">Add New Institution</h3>

        <button type="button" className="close" onClick={() => props.setShowInstitution(false)}>
          <span>×</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        {!categories && <Spinner />}
        {categories && (
          <Form>
            <Form.Group className="mb-3" controlId="external_code">
              <Form.Label>External Code</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  className={errors.external_code?.message ? 'is-invalid' : ''}
                  type="text"
                  placeholder="External Code"
                  {...register('external_code', { required: true })}
                />
                <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                  {errors.external_code?.message}
                </div>
              </InputGroup>
            </Form.Group>

            <Form.Group className="col mb-3" controlId="display_name">
              <Form.Label>Name</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  className={errors.display_name?.message ? 'is-invalid' : ''}
                  type="text"
                  placeholder="Institution Name"
                  {...register('display_name', { required: true })}
                />
                <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                  {errors.display_name?.message}
                </div>
              </InputGroup>
            </Form.Group>

            <div>
              <Form.Label>Category</Form.Label>
              <Form.Group className="col mb-3" controlId="institution_category_id">
                <InputGroup hasValidation>
                  <Form.Select
                    className={errors.institution_category_id?.message ? 'is-invalid' : ''}
                    aria-label="Select category"
                    {...register('institution_category_id', { required: true, valueAsNumber: true })}
                  >
                    <option defaultValue={0} value={0}>
                      Please select an option
                    </option>
                    {categories?.map((category, index) => (
                      <option key={index} value={category.id}>
                        {category.display_name}{' '}
                      </option>
                    ))}
                  </Form.Select>
                  <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                    {errors.institution_category_id?.message}
                  </div>
                </InputGroup>
              </Form.Group>
            </div>

            <div className="d-flex justify-content-between">
              <Form.Group className="col-sm-8 mb-3" controlId="institution_type_id">
                <Form.Label>Institution Type</Form.Label>
                <Form.Select
                  className={errors.institution_type_id?.message ? 'is-invalid' : ''}
                  aria-label="Select type"
                  {...register('institution_type_id', {
                    required: true,
                    valueAsNumber: true,
                    onChange(event) {
                      setInstitutionTypeToEditId(event.target.value);
                    },
                  })}
                >
                  <option defaultValue={0} value={0}>
                    Please select an option
                  </option>
                  {types?.map((type, index) => (
                    <option key={index} value={type.id}>
                      {type.name}{' '}
                    </option>
                  ))}
                </Form.Select>
                <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                  {errors.institution_type_id?.message}
                </div>
              </Form.Group>
              <div className="col-sm-2 d-flex justify-content-center" style={{ alignItems: 'center' }}>
                {institutionTypeToEditId ? (
                  <button
                    onClick={() => {
                      setShowInstitutionTypeEdit(true);
                    }}
                    type="button"
                    className="ms-btn-icon-outline btn-pill btn-light"
                    style={{ marginLeft: '10px' }}
                  >
                    <FontAwesomeIcon icon={solid('edit')} />
                  </button>
                ) : null}

                <button
                  onClick={() => setShowInstitutionType(true)}
                  type="button"
                  className="ms-btn-icon-outline btn-pill btn-light"
                  style={{ marginLeft: '10px' }}
                >
                  <FontAwesomeIcon icon={solid('add')} />
                </button>
              </div>
            </div>
            <InstitutionTypeModal
              setShowInstitutionType={setShowInstitutionType}
              showInstitutionType={showInstitutionType}
            />
            <InstitutionTypeModalEdit
              institutionTypeId={institutionTypeToEditId!}
              setShowEditInstitutionType={setShowInstitutionTypeEdit}
              showInstitutionTypeEdit={showInstitutionTypeEdit}
            />

            <div>
              <Form.Label>Country</Form.Label>
              <Form.Group className="col mb-3" controlId="country_id">
                <Form.Select
                  className={errors.country_id?.message ? 'is-invalid' : ''}
                  aria-label="Select country"
                  {...register('country_id', { required: true, valueAsNumber: true })}
                >
                  <option defaultValue={0} value={0}>
                    Please select an option
                  </option>
                  {countries?.map((country, index) => (
                    <option key={index} value={country.id}>
                      {country.name}{' '}
                    </option>
                  ))}
                </Form.Select>
                <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                  {errors.country_id?.message}
                </div>
              </Form.Group>
            </div>

            <div>
              <Form.Label>Effective start date</Form.Label>
              <Form.Group className="col mb-3" controlId="effective_start">
                <Form.Control
                  className={errors.effective_start?.message ? 'is-invalid' : ''}
                  {...register('effective_start', { required: true })}
                  type="date"
                />
                <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                  {errors.effective_start?.message}
                </div>
              </Form.Group>
            </div>

            <div>
              <Form.Label>Effective end date</Form.Label>
              <Form.Group className="col mb-3" controlId="effective_end">
                <Form.Control
                  className={errors.effective_end?.message ? 'is-invalid' : ''}
                  {...register('effective_end', { required: true })}
                  type="date"
                />
                <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                  {errors.effective_end?.message}
                </div>
              </Form.Group>
            </div>

            <Form.Group className="pt-3 pb-3">
              <label className="ms-switch">
                <input type="checkbox" {...register('balance_enabled')} />
                <span className="ms-switch-slider ms-switch-primary round" />
              </label>
              <span className="text-dark p-2">Balance Enabled</span>
            </Form.Group>
            <Form.Group>
              <label className="ms-switch">
                <input type="checkbox" {...register('balance_prompt_ind')} />
                <span className="ms-switch-slider ms-switch-primary round" />
              </label>
              <span className="text-dark p-2">Balance Prompt</span>
            </Form.Group>
            <Alert variant="danger" show={error != null}>
              {error}
            </Alert>
            <div className="d-flex justify-content-end">
              <Button type="button" className="btn btn-light" onClick={() => props.setShowInstitution(false)}>
                Cancel
              </Button>
              <Button onClick={handleSubmit(onInstitutionCreate)} className="btn btn-primary shadow-none">
                {saving ? <Spinner size="sm" /> : 'Save'}
              </Button>
            </div>
          </Form>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default InstitutionModal;
