import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { Form, Alert, InputGroup, Modal, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Spinner from '../../components/Spinner';
import * as yup from 'yup';
import { fetchInstitutionTypeById, InstitutionType, updateInstitutionType } from '../../api';
import { yupResolver } from '@hookform/resolvers/yup';
import toastr from 'toastr';
import { messageService } from '../../events/create.events';

toastr.options = {
  closeButton: false,
  debug: false,
  newestOnTop: false,
  progressBar: true,
  positionClass: 'toast-top-left',
  preventDuplicates: false,
  toastClass: 'success',
  showEasing: 'swing',
  hideEasing: 'linear',
  showMethod: 'fadeIn',
  hideMethod: 'fadeOut',
};

const schema = yup
  .object({
    name: yup.string().required('Name is a required field'),
  })
  .required();

const InstitutionTypeModalEdit = (props: {
  showInstitutionTypeEdit: boolean;
  setShowEditInstitutionType: Dispatch<SetStateAction<boolean>>;
  institutionTypeId: number;
}) => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<InstitutionType>({
    resolver: yupResolver(schema),
  });

  const [saving, setSaving] = useState<boolean>(false);
  const [institutionTypeData, setInstitutionTypeData] = useState<InstitutionType>();
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    if (props.showInstitutionTypeEdit) {
      console.log('hallo');
      fetchInstitutionTypeById(props.institutionTypeId)
        .then((institutionTypeData) => setInstitutionTypeData(institutionTypeData))
        .catch((error) => setError(error));
    }
  }, [props.showInstitutionTypeEdit, props.institutionTypeId]);

  const onEditInstitutionType = (formData: InstitutionType) => {
    setSaving(true);
    formData.id = props.institutionTypeId;
    console.log(formData);
    updateInstitutionType(formData)
      .then((response) => {
        if (!response) {
          setError('Something went wrong.');
          setSaving(false);
        } else if (response.status !== 200) {
          setError('Error: ' + response.status);
          setSaving(false);
        } else {
          // Close Modal!
          setSaving(false);
          messageService.sendMessage('institutionTypeUpdated');
          toastr.info('InstitutionType Updated');
          reset({}, { keepDefaultValues: false, keepValues: false });
          setInstitutionTypeData(undefined);
          props.setShowEditInstitutionType(false);
        }
      })
      .catch((error: any) => {
        setSaving(false);
        setError('Error: ' + error.message);
      });
  };

  return (
    <Modal
      show={props.showInstitutionTypeEdit}
      onHide={() => {
        reset({}, { keepDefaultValues: false, keepValues: false });
        setInstitutionTypeData(undefined);
        props.setShowEditInstitutionType(false);
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="bg-primary">
        <h3 className="modal-title has-icon text-white">Update Institution Type</h3>

        <button
          type="button"
          className="close"
          onClick={() => {
            reset({}, { keepDefaultValues: false, keepValues: false });
            setInstitutionTypeData(undefined);
            props.setShowEditInstitutionType(false);
          }}
        >
          <span>×</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        {!institutionTypeData && <Spinner />}
        {institutionTypeData ? (
          <Form>
            <Form.Group className="mb-3" controlId="id">
              <Form.Label>Id</Form.Label>
              <InputGroup>
                <Form.Control
                  defaultValue={props.institutionTypeId}
                  disabled={true}
                  type="number"
                  {...register('id', { required: true })}
                />
                <Form.Control.Feedback type="invalid">Id is required</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3" controlId="name">
              <Form.Label>Name</Form.Label>
              <InputGroup>
                <Form.Control
                  defaultValue={institutionTypeData?.name}
                  type="text"
                  placeholder="InstitutionType Name"
                  {...register('name', { required: true })}
                />
                <Form.Control.Feedback type="invalid">Name is required</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3" controlId="code">
              <Form.Label>Code</Form.Label>
              <InputGroup>
                <Form.Control
                  defaultValue={institutionTypeData?.code}
                  type="text"
                  placeholder="InstitutionType Code"
                  {...register('code', { required: false })}
                />
              </InputGroup>
            </Form.Group>
            <Alert variant="danger" show={error != null}>
              {error}
            </Alert>
            <div className="d-flex justify-content-end">
              <Button
                type="button"
                className="btn btn-light"
                onClick={() => {
                  reset({}, { keepDefaultValues: false, keepValues: false });
                  setInstitutionTypeData(undefined);
                  props.setShowEditInstitutionType(false);
                }}
              >
                Cancel
              </Button>
              <Button onClick={handleSubmit(onEditInstitutionType)} className="btn btn-primary shadow-none">
                {saving ? <Spinner size="sm" /> : 'Save'}
              </Button>
            </div>
          </Form>
        ) : null}
      </Modal.Body>
    </Modal>
  );
};

export default InstitutionTypeModalEdit;
