import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import OverflowMenu from '../../components/OverflowMenu';
import { fetchAllInstitutions, Institution } from '../../api';
import Spinner from '../../components/Spinner';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Institutions = () => {
  const [data, setData] = useState<Institution[] | null>(null);

  useEffect(() => {
    fetchAllInstitutions().then((institutions) => {
      setData(institutions);
    });
  }, []);

  const boolSort = (rowA: Institution, rowB: Institution) => {
    const a = rowA.balance_enabled;
    const b = rowB.balance_enabled;

    if (a && !b) {
      return 1;
    }

    if (b && !a) {
      return -1;
    }

    return 0;
  };

  const columns = [
    { name: 'ID', selector: (row: Institution) => row.id ?? '', sortable: true, maxWidth: '40px' },
    { name: 'Name', selector: (row: Institution) => row.display_name, sortable: true, maxWidth: '180px' },
    {
      name: 'Type',
      selector: (row: Institution) => row.institution_type?.name ?? '',
      sortable: true,
      maxWidth: '150px',
    },
    {
      name: 'Category',
      selector: (row: Institution) => row.institutionCategory?.display_name ?? '',
      sortable: true,
      maxWidth: '240px',
    },
    { name: 'Country', selector: (row: Institution) => row.country?.name ?? '', sortable: true, maxWidth: '120px' },
    {
      name: 'Balance Enabled',
      cell: (row: Institution) => (
        <div className="d-flex w-100 justify-content-center">
          <input type="checkbox" onChange={() => void 0} checked={row.balance_enabled} />
        </div>
      ),
      sortable: true,
      sortFunction: boolSort,
      maxWidth: '150px',
    },
    { sortable: false, button: true, cell: (row: Institution) => <OverflowMenu type="institution" id={row.id} /> },
  ];

  return (
    <div className="ms-content-wrapper">
      <div className="ms-panel">
        <div className="ms-panel-header d-flex justify-content-between align-items-center">
          <h6>Institutions</h6>
          <Link to={`/institution/add`}>
            <Button variant="primary">Add</Button>
          </Link>
        </div>
        <div className="ms-panel-body">
          {!data && <Spinner />}
          {data && (
            <div className="table-responsive datatables row">
              <DataTableExtensions {...{ columns, data }} export={false} print={false}>
                <DataTable columns={columns} data={data} pagination responsive={true} highlightOnHover noHeader />
              </DataTableExtensions>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Institutions;
