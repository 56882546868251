import { useEffect, useState } from 'react';
import { Button, Form, Alert, InputGroup } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Spinner from '../../components/Spinner';
import { createCategory, fetchCategory, updateCategory } from '../../api';
import { InstitutionCategory } from '../../@types/institution';

const EditCategory = () => {
  const { id } = useParams();
  const creating = id === 'add';

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [data, setData] = useState<InstitutionCategory | null>(null);
  const [saving, setSaving] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    if (creating) {
      // Create an object with empty values
      setData(null);
      return;
    }

    fetchCategory(id).then((data) => {
      if (data != null) {
        setData(data);
      }
    });
  }, []);

  const onSubmit = (formData: any) => {
    if (!data) return;
    setSaving(true);

    // Merge new data with old data
    const newData = { ...data, ...formData };

    const save = creating ? createCategory : updateCategory;

    save(newData)
      .then((response) => {
        if (response === null) {
          setError('Something went wrong.');
          setSaving(false);
        } else if (response.status !== 200) {
          setError('Error: ' + response.status);
          setSaving(false);
        } else {
          // Success!
          navigate('/categories');
        }
      })
      .catch((error: any) => {
        setSaving(false);
        setError('Error: ' + error.message);
      });
  };

  return (
    <div className="ms-content-wrapper">
      <div className="ms-panel col-xl-4 col-lg-6 col-md-8 col-sm-12">
        <div className="ms-panel-header">
          <h6>Category | {creating ? 'Add' : 'Edit'}</h6>
        </div>
        <div className="ms-panel-body">
          {!data && <Spinner />}
          {data && (
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>ID</Form.Label>
                <Form.Control type="number" disabled value={data.id} />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Name</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="text"
                    placeholder="Category Name"
                    defaultValue={data.display_name}
                    isInvalid={errors.name}
                    {...register('name', { required: true })}
                  />
                  <Form.Control.Feedback type="invalid">Name is required</Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Alert variant="danger" show={error != null}>
                {error}
              </Alert>
              <div className="d-flex justify-content-end">
                <Link to="/categories">
                  <Button variant="outline-secondary" className="me-2">
                    Back
                  </Button>
                </Link>
                <Button type="submit" disabled={saving}>
                  {saving ? <Spinner size="sm" /> : 'Save'}
                </Button>
              </div>
            </Form>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditCategory;
