import { Link, Route, Routes, useParams } from 'react-router-dom';
import { Dropdown, NavLink } from 'react-bootstrap';

import logo from '../assets/img/pepkor_logo_white.png';
import people5 from '../assets/img/people/people-5.jpg';
import useAuth from '../hooks/useAuth';

interface NavBarProps {
  title?: string;
}

const ContextualTitle = (props: { type: string }) => {
  const { id } = useParams();
  const creating = id === 'add';
  return (
    <>
      {props.type} | {creating ? 'Add' : 'Edit'}
    </>
  );
};

const Navbar = ({ title }: NavBarProps) => {
  const { user, logout } = useAuth();
  const navToggle = () => {
    document.getElementById('body')?.classList.toggle('ms-aside-left-open');
    document.getElementById('ms-side-nav')?.classList.toggle('ms-aside-open');
    document.getElementById('overlayleft')?.classList.toggle('d-block');
  };

  const optionsToggle = () => {
    console.log('slide');
    document.getElementById('ms-nav-options')?.classList.toggle('ms-slide-down');
  };

  const logoutUser = () => {
    logout();
  };

  return (
    <nav className="navbar ms-navbar sticky-top">
      <div className="row align-items-center justify-content-left w-75">
        <div
          className="ms-aside-toggler ms-toggler pl-0"
          style={{ maxWidth: '48px' }}
          data-target="#ms-side-nav"
          data-toggle="slideLeft"
          onClick={navToggle}
        >
          <span className="ms-toggler-bar bg-primary" />
          <span className="ms-toggler-bar bg-primary" />
          <span className="ms-toggler-bar bg-primary" />
        </div>
        <div className="col p-3 m-0">
          <h2>
            <Routes>
              <Route path="/" element={'Home'} />
              {/* <Route path="/dashboard" element={'Dashboard'} /> */}
              <Route path="/configuration-products" element={'Configurations'} />
              <Route path="/configuration-products/:id" element={'Configurations'} />
              <Route path="/companies" element={'Companies'} />
              <Route path="/company/:id" element={'Company | Edit'} />
              <Route path="/channels" element={'Channels'} />
              <Route path="/channel/:id" element={<ContextualTitle type="Channel" />} />
              <Route path="/categories" element={'Categories'} />
              <Route path="/category/:id" element={<ContextualTitle type="Category" />} />
              <Route path="/products" element={'Products'} />
              <Route path="/product/:id" element={<ContextualTitle type="Product" />} />
              <Route path="/institutions" element={'Institutions'} />
              <Route path="/institution/:id" element={<ContextualTitle type="Institution" />} />
              <Route path="/providers" element={'Providers'} />
              <Route path="/provider/:id" element={<ContextualTitle type="Provider" />} />
              <Route path="/switches" element={'Switches'} />
              <Route path="/switch/:id" element={<ContextualTitle type="Switch" />} />
              <Route path="/configuration-institution-categories" element={'Configuration Institution Categories'} />
            </Routes>
          </h2>
        </div>
      </div>

      <ul className="ms-nav-list ms-inline mb-0 align-items-center">
        <span className="text-disabled" style={{ fontSize: 'smaller' }}>
          Hi, {user?.email}
        </span>
        <button
          type="button"
          style={{ backgroundColor: 'transparent', marginLeft: '6px', height: '28px', width: '28px' }}
          className="ms-btn-icon-outline btn-pill btn-danger"
          onClick={logoutUser}
        >
          <i className="material-icons fs-16" style={{ color: '#dc3545' }}>
            lock
          </i>
        </button>
      </ul>

      <div
        className="ms-toggler ms-d-block-sm pr-0 ms-nav-toggler"
        data-toggle="slideDown"
        data-target="#ms-nav-options"
        onClick={optionsToggle}
      >
        <span className="ms-toggler-bar bg-primary" />
        <span className="ms-toggler-bar bg-primary" />
        <span className="ms-toggler-bar bg-primary" />
      </div>
    </nav>
  );
};

export default Navbar;
