import axios from '../utils/axios';
import { Configuration } from '../@types/configuration';

export const fetchAll = async (queryString: string): Promise<Configuration[]> => {
  try {
    const response = await axios.get<Configuration[]>(`/configurations?queryString=${queryString}`);
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const fetchOne = async (id: number): Promise<Configuration | null> => {
  try {
    const response = await axios.get(`/configurations/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const updateOne = async (data: Configuration) => {
  try {
    const response = await axios.put(`/configurations/${data.id}`, data);
    return response.data;
  } catch (error) {
    return null;
  }
};

export const insertOne = async (data: Configuration) => {
  return await axios.post('/configurations', data);
};
