import { stepEvents } from './steps.events';
const _events = () => {
  let instance: any = null;

  const getInstance = () => {
    if (!instance) {
      instance = stepEvents();
    }
    return instance;
  };

  return { getInstance };
};

export const instance = _events().getInstance;
