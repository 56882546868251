import axios from '../utils/axios';

import { Message, MessageCreateInput } from '../@types/message';
import { AxiosResponse } from 'axios';

export const fetchMessagesByProductId = async (product_id: number): Promise<Message[]> => {
  const response = await axios.get<Message[]>(`messages/product/${product_id}`);
  return response.data;
};

export const updateMessage = async (message: Message): Promise<AxiosResponse<Message>> => {
  const response = await axios.put<Message>(`messages/${message.id}`, message);
  return response;
};

export const updateMessageSequence = async (messages: Message[]): Promise<AxiosResponse<Message[]>> => {
  const response = await axios.put<Message[]>(`messages`, messages);
  return response;
};

export const createMessage = async (message: MessageCreateInput): Promise<AxiosResponse<Message>> => {
  const response = await axios.post<Message>(`messages`, message);
  return response;
};

export const fetchMessage = async (message_id: number): Promise<Message> => {
  const response = await axios.get<Message>(`messages/${message_id}`);
  return response.data;
};
