import { Switch, SwitchCreateDTO } from '../@types/switch';
import axios from '../utils/axios';

export type { Switch } from '../@types/switch';

export const fetchAll = async (): Promise<Switch[]> => {
  const response = await axios.get('switches');
  return response.data;
};

export const fetchOne = async (id: number): Promise<Switch> => {
  const response = await axios.get(`switches/${id}`);
  return response.data;
};

export const updateOne = async (data: Switch) => {
  try {
    const response = await axios.put(`switches/${data.id}`, data);
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const insertOne = async (data: SwitchCreateDTO) => {
  try {
    const response = await axios.post(`switches`, data);
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};
