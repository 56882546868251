import axios from '../utils/axios';
import { Company } from '../@types/company';

export type { Company } from '../@types/company';

export const fetchAll = async (): Promise<Company[]> => {
  const response = await axios.get<Company[]>('/companies');
  return response.data;
};

export const fetchOne = async (id: string): Promise<{ company: Company | null }> => {
  try {
    const response = await axios.get(`/companies/${id}`);
    return { company: response.data };
  } catch (error) {
    console.error(error);
    return { company: null };
  }
};

export const updateOne = async (data: Company) => {
  try {
    const response = await axios.put(`/companies/${data.id}`, data);
    return { company: response.data };
  } catch (error) {
    console.error(error);
    return { company: null };
  }
};

export const insertOne = async (data: Company) => {
  try {
    const response = await axios.post(`/companies`, { name: data.name });
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};
