import axios from '../utils/axios';
import { InstitutionType, InstitutionTypeCreateInput } from '../@types/institution';
import { AxiosResponse } from 'axios';

export type { InstitutionType } from '../@types/institution';

export const fetchAll = async (): Promise<InstitutionType[] | null> => {
  try {
    const response = await axios.get('/institution-types');
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const fetchOne = async (id: number): Promise<InstitutionType> => {
  const response = await axios.get(`/institution-types/${id}`);
  return response.data;
};

export const updateOne = async (data: InstitutionType): Promise<AxiosResponse<InstitutionType>> => {
  const response = await axios.put(`/institution-types/${data.id}`, data);
  return response;
};

export const insertOne = async (data: InstitutionTypeCreateInput): Promise<AxiosResponse<InstitutionType>> => {
  const response = await axios.post<InstitutionType>(`/institution-types`, data);
  return response;
};
