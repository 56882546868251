import { useContext, useEffect, useState } from 'react';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import { Button } from 'react-bootstrap';
import toastr from 'toastr';
import { AuthContext } from '../../../context/JWTContext';
import * as yup from 'yup';
import { instance } from '../../../events/event-objects';
import { Message } from '../../../@types/message';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchMessagesByProductId, updateMessageSequence } from '../../../api';
import Spinner from '../../../components/Spinner';
import { SortableItem } from '../../../components/SortableItem';
import MessageModal from '../../messages/MessageModal';
import { messageService } from '../../../events/create.events';
import { take } from 'rxjs';

const MessageStep = () => {
  const { id } = useParams();

  const [data, setData] = useState<Message[]>();
  const [showMessageCreateModal, setShowMessageCreateModal] = useState<boolean>(false);

  const [saving, setSaving] = useState<boolean>(false);
  const [activeItem, setActiveItem] = useState<Message | null>(null);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useEffect(() => {
    if (id) {
      fetchMessagesByProductId(+id)
        .then((messages) => {
          setData(messages);
        })
        .catch((error) => console.log(error));
    }
  }, [id]);

  useEffect(() => {
    const newMessageSubscription = messageService.getMessage().subscribe({
      next: (val) => {
        switch (val.key) {
          case 'newMessage':
            fetchMessagesByProductId(+id!)
              .then((messages) => {
                setData(messages);
              })
              .catch((error) => console.log(error));
            break;
          case 'updatedMessage':
            fetchMessagesByProductId(+id!)
              .then((messages) => {
                setData(messages);
              })
              .catch((error) => console.log(error));
            break;
          default:
            break;
        }
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => {
        messageService.clearMessages();
      },
    });

    return () => {
      newMessageSubscription.unsubscribe();
    };
  }, []);

  const handleDragEnd = (event: any) => {
    console.log(data);
    const { active, over } = event;

    if (active.id !== over.id) {
      setData((items) => {
        const oldIndex = items!.findIndex((v) => v.id === active.id);
        const newIndex = items!.findIndex((v) => v.id === over.id);

        const updatedArray = arrayMove(items!, oldIndex, newIndex);
        updatedArray.map((item, index) => (item.sequence = index += 1));

        return updatedArray;
      });
    }

    setActiveItem(null);
  };

  const handleDragStart = (event: any) => {
    console.log(data);
    const item = data?.find((v) => v.id === event.active.id);
    if (item) setActiveItem(item);
  };

  const storeSequence = () => {
    console.log(data);
    console.log('update the sequence');
    updateMessageSequence(data!)
      .then((res) => {
        setData(res.data);
        messageService.sendMessage('messagesUpdated');
        nextEvent();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const backEvent = () => {
    const event = {
      key: 'back',
    };

    instance().back(event);
  };

  const nextEvent = () => {
    const event = {
      key: 'next',
    };

    instance().next(event);
  };

  toastr.options = {
    closeButton: false,
    debug: false,
    newestOnTop: false,
    progressBar: true,
    positionClass: 'toast-top-left',
    preventDuplicates: false,
    toastClass: 'success',
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
  };

  return (
    <div className="ms-wizard-step">
      <h4>Messages</h4>
      {!data ? <Spinner /> : null}
      {data?.length ? (
        <div className="row">
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
          >
            <SortableContext items={data} strategy={verticalListSortingStrategy}>
              {data.map((item) => (
                <SortableItem
                  key={item.id}
                  overlay={false}
                  id={item.id}
                  name={item.message_type ? item.message_type.name : 'Edit This Message'}
                  effectiveTo={item.effective_end.toString()}
                  showSelect={true}
                />
              ))}
            </SortableContext>
            <DragOverlay>
              {activeItem ? (
                <SortableItem
                  key={activeItem.id}
                  id={activeItem.id}
                  name={activeItem.message_type ? activeItem.message_type.name : 'Edit This Message'}
                  overlay={true}
                  effectiveTo={activeItem.effective_end.toString()}
                  showSelect={true}
                />
              ) : null}
            </DragOverlay>
          </DndContext>
          <div className="text-center mb-5">
            <Button
              onClick={() => setShowMessageCreateModal(true)}
              style={{ width: '100%' }}
              type="button"
              className="btn btn-dark"
            >
              Add Message
            </Button>
          </div>

          <div className="d-flex justify-content-end">
            <Button onClick={backEvent} variant="outline-secondary" className="me-2">
              Back
            </Button>

            <Button type="submit" disabled={saving} onClick={storeSequence}>
              {saving ? <Spinner size="sm" /> : 'Continue'}
            </Button>
          </div>
          <MessageModal
            product_id={+id!}
            showMessage={showMessageCreateModal}
            setShowMessage={setShowMessageCreateModal}
          />
        </div>
      ) : null}
    </div>
  );
};

export default MessageStep;
