import { Dispatch, SetStateAction, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Form, Alert, InputGroup, Modal, Button, Col, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import Spinner from '../../components/Spinner';
import * as yup from 'yup';
import { createProvider } from '../../api';
import { yupResolver } from '@hookform/resolvers/yup';
import { messageService } from '../../events/create.events';
import toastr from 'toastr';
import { ProviderCreateInput } from '../../@types/provider';
import { errorMessageTextStyleOverride } from '../../@types/errorMessages';

toastr.options = {
  closeButton: false,
  debug: false,
  newestOnTop: false,
  progressBar: true,
  positionClass: 'toast-top-left',
  preventDuplicates: false,
  toastClass: 'success',
  showEasing: 'swing',
  hideEasing: 'linear',
  showMethod: 'fadeIn',
  hideMethod: 'fadeOut',
};

const schema = yup
  .object({
    display_name: yup.string().required('Display Name is a required field'),
    external_code: yup.string().required('External Code is a required field'),
    effective_start: yup.string().required('Start Date is a required field'),
    effective_end: yup.string().required('End Date is a required field'),
  })
  .required();

const ProviderModal = (props: {
  showProvider: boolean;
  setShowProvider: Dispatch<SetStateAction<boolean>>;
  configuration_id: number;
}) => {
  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ProviderCreateInput>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const [saving, setSaving] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [effectiveStartDate, setEffectiveStartDate] = useState<Date>();
  const [effectiveEndDate, setEffectiveEndDate] = useState<Date>();

  const onCreateProvider = (formData: ProviderCreateInput) => {
    setSaving(true);

    formData.configuration_id = props.configuration_id;
    formData.effective_start = effectiveStartDate!;
    formData.effective_end = effectiveEndDate!;
    console.log(formData);

    createProvider(formData)
      .then((response) => {
        if (!response) {
          setError('Something went wrong.');
          setSaving(false);
        } else if (response.status !== 200) {
          setError('Error: ' + response.status);
          setSaving(false);
        } else {
          // Close Modal!
          toastr.info('Provider Created');
          setSaving(false);
          setError(null);
          reset(
            {
              display_name: '',
              external_code: '',
              exclusive_prefix_ind: false,
              allow_balance_enquiry_ind: false,
              reference_switching_ind: false,
              effective_start: undefined,
              effective_end: undefined,
            },
            { keepValues: false, keepErrors: false }
          );
          props.setShowProvider(false);
          messageService.sendMessage('newProvider');
        }
      })
      .catch((error: any) => {
        setSaving(false);
        setError('Error: ' + error.message);
      });
  };

  return (
    <Modal
      show={props.showProvider}
      onHide={() => {
        reset(
          {
            display_name: '',
            external_code: '',
            exclusive_prefix_ind: false,
            allow_balance_enquiry_ind: false,
            reference_switching_ind: false,
            effective_start: undefined,
            effective_end: undefined,
          },
          { keepValues: false, keepErrors: false }
        );
        props.setShowProvider(false);
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="bg-primary">
        <h3 className="modal-title has-icon text-white">Add New Provider</h3>

        <button
          type="button"
          className="close"
          onClick={() => {
            reset(
              {
                display_name: '',
                external_code: '',
                exclusive_prefix_ind: false,
                allow_balance_enquiry_ind: false,
                reference_switching_ind: false,
                effective_start: undefined,
                effective_end: undefined,
              },
              { keepValues: false, keepErrors: false }
            );
            props.setShowProvider(false);
          }}
        >
          <span>×</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            <Form.Group as={Col} md="12" className="col" controlId="display_name">
              <Form.Label>Display Name</Form.Label>
              <InputGroup>
                <Form.Control
                  className={errors.display_name?.message ? 'is-invalid' : ''}
                  type="text"
                  placeholder="Display Name"
                  {...register('display_name', { required: true })}
                />
                <Form.Control.Feedback type="invalid">Display Name is required</Form.Control.Feedback>
                <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                  {errors.display_name?.message ? 'Display Name is required' : null}
                </div>
              </InputGroup>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} md="12" className="col" controlId="external_code">
              <Form.Label>External Code</Form.Label>
              <InputGroup>
                <Form.Control
                  placeholder="External Code"
                  className={errors.external_code?.message ? 'is-invalid' : ''}
                  required
                  type="text"
                  {...register('external_code', { required: true })}
                />
                <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                  {errors.external_code?.message ? 'External Code is required' : null}
                </div>
              </InputGroup>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group className="col mb-3" controlId="effective_start">
              <Form.Label>Effective start date</Form.Label>
              <Controller
                control={control}
                name={'effective_start'}
                defaultValue={effectiveStartDate}
                render={({ field }) => (
                  <DatePicker
                    selected={effectiveStartDate}
                    placeholderText="Select date"
                    onChange={(date) => {
                      setEffectiveStartDate(date!);
                      field.onChange(date);
                    }}
                    timeInputLabel="Time:"
                    dateFormat="yyyy/MM/dd h:mm aa"
                    showTimeInput
                    className={errors.effective_start?.message ? 'is-invalid no-border' : 'no-border'}
                  />
                )}
              />
              <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                {errors.effective_start?.message ? 'Effective start date is required' : null}
              </div>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group className="col mb-3" controlId="effective_end">
              <Form.Label>Effective end date</Form.Label>
              <Controller
                control={control}
                name={'effective_end'}
                defaultValue={effectiveEndDate}
                render={({ field }) => (
                  <DatePicker
                    selected={effectiveEndDate}
                    placeholderText="Select date"
                    onChange={(date) => {
                      setEffectiveEndDate(date!);
                      field.onChange(date);
                    }}
                    timeInputLabel="Time:"
                    dateFormat="yyyy/MM/dd h:mm aa"
                    showTimeInput
                    className={errors.effective_end?.message ? 'is-invalid no-border' : 'no-border'}
                  />
                )}
              />
              <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                {errors.effective_start?.message ? 'Effective start date is required' : null}
              </div>
            </Form.Group>
          </Row>

          <Form.Group className="pt-3 pb-3" controlId="active_ind">
            <label className="ms-switch">
              <input type="checkbox" {...register('exclusive_prefix_ind')} />
              <span className="ms-switch-slider ms-switch-primary round" />
            </label>
            <span className="text-dark p-2">Exclusive Prefix</span>
          </Form.Group>

          <Form.Group className="pb-3" controlId="visible_ind">
            <label className="ms-switch">
              <input type="checkbox" {...register('allow_balance_enquiry_ind')} />
              <span className="ms-switch-slider ms-switch-primary round" />
            </label>
            <span className="text-dark p-2">Allow Balance Enquiry</span>
          </Form.Group>

          <Form.Group className="pb-3" controlId="pilot_ind">
            <label className="ms-switch">
              <input type="checkbox" {...register('reference_switching_ind')} />
              <span className="ms-switch-slider ms-switch-primary round" />
            </label>
            <span className="text-dark p-2">Reference Switching</span>
          </Form.Group>

          <Alert variant="danger" show={error != null}>
            {error}
          </Alert>
          <div className="d-flex justify-content-end">
            <Button
              type="button"
              className="btn btn-light"
              onClick={() => {
                reset(
                  {
                    display_name: '',
                    external_code: '',
                    exclusive_prefix_ind: false,
                    allow_balance_enquiry_ind: false,
                    reference_switching_ind: false,
                    effective_start: undefined,
                    effective_end: undefined,
                  },
                  { keepValues: false, keepErrors: false }
                );
                props.setShowProvider(false);
              }}
            >
              Cancel
            </Button>
            <Button disabled={saving} onClick={handleSubmit(onCreateProvider)} className="btn btn-primary shadow-none">
              {saving ? <Spinner size="sm" /> : 'Save'}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ProviderModal;
