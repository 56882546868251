import { useContext, useEffect, useState } from 'react';
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import toastr from 'toastr';
import { AuthContext } from '../../../context/JWTContext';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { instance } from '../../../events/event-objects';
import { ProductFees, ProductFeesInput } from '../../../@types/product-fees';
import { Controller, useForm } from 'react-hook-form';
import { fetchAllCustomerServiceTypeFees, fetchFeesByProductId, updateProductFees } from '../../../api';
import { useParams } from 'react-router-dom';
import Spinner from '../../../components/Spinner';
import { CustomerServiceTypeFeeType } from '../../../@types/customer_service_type_fee';
import { errorMessageTextStyleOverride } from '../../../@types/errorMessages';
import { messageService } from '../../../events/create.events';

const schema = yup
  .object({
    lex_fee_amount: yup.string().required('Display Name is required'),
    cash_handling_fee_amount: yup.string().required('Cash Handling Fee is required'),
    card_handling_fee_amount: yup.string().required('Card Handling Fee is required'),
    third_party_cash_handling_fee_amount: yup.string().required('Third Party Cash Handling Fee is required'),
    third_party_card_handling_fee_amount: yup.string().required('Third Party Card Handling Fee is required'),
    local_min_fee_amount: yup.string().required('Local Min Fee is required'),
    local_max_fee_amount: yup.string().required('Local Max Fee is required'),
    local_fee_percentage: yup.string().required('Local Fee is required'),
    cash_handling_fee_percentage: yup.string().required('Cash Handling Fee Percentage is required'),
    card_handling_fee_percentage: yup.string().required('Card Handling Fee Percentage is required'),
    cash_handling_3rd_party_fee_percentage: yup
      .string()
      .required('Third Party Cash Handling Fee Percentage is required'),
    card_handling_3rd_party_fee_percentage: yup
      .string()
      .required('Third Party Card Handling Fee Percentage is required'),
    customer_service_fee_type_id: yup.number().required('Customer Service Type is required'),
    customer_service_fee_value: yup.string().required('Customer Service Fee is required'),
    commission_percentage: yup.string().required('Commission is required'),
    effective_start: yup.date().required('Effective Start is required'),
    effective_end: yup.date().required('Effective End is required'),
  })
  .required();

const FeesStep = () => {
  const { id } = useParams();
  const userContext = useContext(AuthContext);

  const [data, setData] = useState<ProductFees>();
  const [effectiveStartDate, setEffectiveStartDate] = useState<Date>(new Date());
  const [effectiveEndDate, setEffectiveEndDate] = useState<Date>(new Date());
  const [saving, setSaving] = useState<boolean>(false);
  const [customerServiceTypeFees, setCustomerServiceTypeFees] = useState<CustomerServiceTypeFeeType[]>();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = useForm<ProductFeesInput>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    console.log(errors);
  }, [errors]);

  useEffect(() => {
    if (id) {
      fetchFeesByProductId(+id)
        .then((product_fees) => {
          console.log(product_fees);
          setData(product_fees);
          setEffectiveStartDate(new Date(product_fees.effective_start!));
          setEffectiveEndDate(new Date(product_fees.effective_end!));
        })
        .catch((error) => console.log(error));

      fetchAllCustomerServiceTypeFees()
        .then((customerServiceTypeFees) => setCustomerServiceTypeFees(customerServiceTypeFees))
        .catch((error) => console.log(error));
    }
  }, [id]);

  const backEvent = () => {
    const event = {
      key: 'back',
    };

    instance().back(event);
  };

  const nextEvent = () => {
    const event = {
      key: 'next',
    };

    instance().next(event);
  };

  toastr.options = {
    closeButton: false,
    debug: false,
    newestOnTop: false,
    progressBar: true,
    positionClass: 'toast-top-left',
    preventDuplicates: false,
    toastClass: 'success',
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
  };

  const onSubmit = (formData: ProductFeesInput) => {
    if (!isDirty) {
      nextEvent();
      return;
    }

    if (formData && userContext?.user?.email) {
      formData = { ...data, ...formData };
      formData.id = data!.id;
      formData.effective_start = effectiveStartDate!;
      formData.effective_end = effectiveEndDate!;

      setSaving(true);

      updateProductFees(formData)
        .then(() => {
          toastr.success('Product Fees Updated Successfully!');
          messageService.sendMessage('feesUpdated');
          nextEvent();
        })
        .catch((error) => {
          setSaving(false);
          console.log(error);
        });
    }
  };

  return (
    <div className="ms-wizard-step">
      <h4>Product Fees</h4>
      {!data ? <Spinner /> : null}
      {data ? (
        <div className="row">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="col mb-3" controlId="id">
              <Form.Label>Id</Form.Label>
              <InputGroup>
                <Form.Control
                  disabled={true}
                  required
                  type="text"
                  defaultValue={data.id}
                  {...register('id', { required: true })}
                />
              </InputGroup>
            </Form.Group>

            <Row className="mb-3">
              <Form.Group as={Col} md="6" className="col" controlId="lex_fee_amount">
                <Form.Label>Lex Fee Amount</Form.Label>
                <InputGroup>
                  <div className="input-group-append">
                    <span className="input-group-text">R</span>
                  </div>
                  <Form.Control
                    className={errors.lex_fee_amount?.message ? 'is-invalid' : ''}
                    style={{ textAlign: 'right' }}
                    required
                    type="text"
                    defaultValue={Number(data.lex_fee_amount).toFixed(2)}
                    {...register('lex_fee_amount', { required: true })}
                  />
                </InputGroup>
                <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                  {errors.lex_fee_amount?.message ? 'Lex Fee Amount is required' : null}
                </div>
              </Form.Group>

              <Form.Group as={Col} md="6" className="col" controlId="cash_handling_fee_amount">
                <Form.Label>Cash Handling Fee Amount</Form.Label>
                <InputGroup>
                  <div className="input-group-append">
                    <span className="input-group-text">R</span>
                  </div>
                  <Form.Control
                    style={{ textAlign: 'right' }}
                    className={errors.cash_handling_fee_amount?.message ? 'is-invalid' : ''}
                    required
                    type="text"
                    defaultValue={Number(data.cash_handling_fee_amount).toFixed(2)}
                    {...register('cash_handling_fee_amount', { required: true })}
                  />
                </InputGroup>
                <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                  {errors.cash_handling_fee_amount?.message ? 'Cash Handling Fee is required' : null}
                </div>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} md="6" className="col" controlId="card_handling_fee_amount">
                <Form.Label>Card Handling Fee Amount</Form.Label>
                <InputGroup>
                  <div className="input-group-append">
                    <span className="input-group-text">R</span>
                  </div>
                  <Form.Control
                    style={{ textAlign: 'right' }}
                    className={errors.card_handling_fee_amount?.message ? 'is-invalid' : ''}
                    required
                    type="text"
                    defaultValue={Number(data.card_handling_fee_amount).toFixed(2)}
                    {...register('card_handling_fee_amount', { required: true })}
                  />
                </InputGroup>
                <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                  {errors.card_handling_fee_amount?.message ? 'Card Handling Fee is required' : null}
                </div>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} md="6" className="col" controlId="third_party_cash_handling_fee_amount">
                <Form.Label>Third Party Cash Handling Fee Amount</Form.Label>
                <InputGroup>
                  <div className="input-group-append">
                    <span className="input-group-text">R</span>
                  </div>
                  <Form.Control
                    style={{ textAlign: 'right' }}
                    className={errors.third_party_cash_handling_fee_amount?.message ? 'is-invalid' : ''}
                    required
                    type="text"
                    defaultValue={Number(data.third_party_cash_handling_fee_amount).toFixed(2)}
                    {...register('third_party_cash_handling_fee_amount', { required: true })}
                  />
                </InputGroup>
                <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                  {errors.third_party_cash_handling_fee_amount?.message
                    ? 'Third Party Cash Handling Fee is required'
                    : null}
                </div>
              </Form.Group>

              <Form.Group as={Col} md="6" className="col" controlId="third_party_card_handling_fee_amount">
                <Form.Label>Third Party Card Handling Fee Amount</Form.Label>
                <InputGroup>
                  <div className="input-group-append">
                    <span className="input-group-text">R</span>
                  </div>
                  <Form.Control
                    style={{ textAlign: 'right' }}
                    className={errors.third_party_card_handling_fee_amount?.message ? 'is-invalid' : ''}
                    required
                    type="text"
                    defaultValue={Number(data.third_party_card_handling_fee_amount).toFixed(2)}
                    {...register('third_party_card_handling_fee_amount', { required: true })}
                  />
                </InputGroup>
                <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                  {errors.third_party_card_handling_fee_amount?.message
                    ? 'Third Party Card Handling Fee is required'
                    : null}
                </div>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} md="6" className="col" controlId="local_min_fee_amount">
                <Form.Label>Local Min Fee Amount</Form.Label>
                <InputGroup>
                  <div className="input-group-append">
                    <span className="input-group-text">R</span>
                  </div>
                  <Form.Control
                    style={{ textAlign: 'right' }}
                    className={errors.local_min_fee_amount?.message ? 'is-invalid' : ''}
                    required
                    type="text"
                    defaultValue={Number(data.local_min_fee_amount).toFixed(2)}
                    {...register('local_min_fee_amount', { required: true })}
                  />
                </InputGroup>
                <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                  {errors.local_min_fee_amount?.message ? 'Local Min Fee is required' : null}
                </div>
              </Form.Group>

              <Form.Group as={Col} md="6" className="col" controlId="local_max_fee_amount">
                <Form.Label>Local Max Fee Amount</Form.Label>
                <InputGroup>
                  <div className="input-group-append">
                    <span className="input-group-text">R</span>
                  </div>
                  <Form.Control
                    style={{ textAlign: 'right' }}
                    className={errors.local_max_fee_amount?.message ? 'is-invalid' : ''}
                    required
                    type="text"
                    defaultValue={Number(data.local_max_fee_amount).toFixed(2)}
                    {...register('local_max_fee_amount', { required: true })}
                  />
                </InputGroup>
                <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                  {errors.local_max_fee_amount?.message ? 'Local Max Fee is required' : null}
                </div>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} md="6" className="col" controlId="local_fee_percentage">
                <Form.Label>Local Fee Percentage</Form.Label>
                <InputGroup>
                  <Form.Control
                    style={{ textAlign: 'right' }}
                    className={errors.local_fee_percentage?.message ? 'is-invalid' : ''}
                    required
                    type="text"
                    defaultValue={Number(data.local_fee_percentage).toFixed(2)}
                    {...register('local_fee_percentage', { required: true })}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">%</span>
                  </div>
                </InputGroup>
                <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                  {errors.local_fee_percentage?.message ? 'Local Fee Percentagem is required' : null}
                </div>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} md="6" className="col" controlId="cash_handling_fee_percentage">
                <Form.Label>Cash Handling Fee Percentage</Form.Label>
                <InputGroup>
                  <Form.Control
                    style={{ textAlign: 'right' }}
                    className={errors.cash_handling_fee_percentage?.message ? 'is-invalid' : ''}
                    required
                    type="text"
                    defaultValue={Number(data.cash_handling_fee_percentage).toFixed(2)}
                    {...register('cash_handling_fee_percentage', { required: true })}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">%</span>
                  </div>
                </InputGroup>
                <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                  {errors.cash_handling_fee_percentage?.message ? 'Cash Handling Fee Percentage is required' : null}
                </div>
              </Form.Group>

              <Form.Group as={Col} md="6" className="col" controlId="card_handling_fee_percentage">
                <Form.Label>Card Handling Fee Percentage</Form.Label>
                <InputGroup>
                  <Form.Control
                    style={{ textAlign: 'right' }}
                    className={errors.card_handling_fee_percentage?.message ? 'is-invalid' : ''}
                    required
                    type="text"
                    defaultValue={Number(data.card_handling_fee_percentage).toFixed(2)}
                    {...register('card_handling_fee_percentage', { required: true })}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">%</span>
                  </div>
                </InputGroup>
                <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                  {errors.card_handling_fee_percentage?.message ? 'Card Handling Fee Percentage is required' : null}
                </div>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} md="6" className="col" controlId="cash_handling_3rd_party_fee_percentage">
                <Form.Label>Third Party Cash Handling Fee Percentage</Form.Label>
                <InputGroup>
                  <Form.Control
                    style={{ textAlign: 'right' }}
                    className={errors.cash_handling_3rd_party_fee_percentage?.message ? 'is-invalid' : ''}
                    required
                    type="text"
                    defaultValue={Number(data.cash_handling_3rd_party_fee_percentage).toFixed(2)}
                    {...register('cash_handling_3rd_party_fee_percentage', { required: true })}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">%</span>
                  </div>
                </InputGroup>
                <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                  {errors.cash_handling_3rd_party_fee_percentage?.message
                    ? 'Cash Handling Fee Percentage is required'
                    : null}
                </div>
              </Form.Group>

              <Form.Group as={Col} md="6" className="col" controlId="card_handling_3rd_party_fee_percentage">
                <Form.Label>Third Party Card Handling Fee Percentage</Form.Label>
                <InputGroup>
                  <Form.Control
                    style={{ textAlign: 'right' }}
                    className={errors.card_handling_3rd_party_fee_percentage?.message ? 'is-invalid' : ''}
                    required
                    type="text"
                    defaultValue={Number(data.card_handling_3rd_party_fee_percentage).toFixed(2)}
                    {...register('card_handling_3rd_party_fee_percentage', { required: true })}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">%</span>
                  </div>
                </InputGroup>
                <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                  {errors.card_handling_3rd_party_fee_percentage?.message
                    ? 'Card Handling 3rd Party Fee Percentage is required'
                    : null}
                </div>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} md="12" controlId="customer_service_fee_type_id">
                <Form.Label>Customer Service Fee Type</Form.Label>

                <Form.Select
                  className={errors.customer_service_fee_type_id?.message ? 'is-invalid col-sm-12' : 'col-sm-12'}
                  aria-label="Select Customer Service Fee Type"
                  defaultChecked={true}
                  defaultValue={data.customer_service_fee_type_id}
                  {...register('customer_service_fee_type_id', { required: true, valueAsNumber: true })}
                >
                  <option defaultValue={undefined} value={undefined}>
                    Please select an option
                  </option>
                  {customerServiceTypeFees?.map((customerServiceTypeFee, index) => (
                    <option key={index} value={customerServiceTypeFee.id}>
                      {customerServiceTypeFee.name}{' '}
                    </option>
                  ))}
                </Form.Select>
                <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                  {errors.customer_service_fee_type_id?.message ? 'Please Select a Service Fee Type' : null}
                </div>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} md="6" className="col" controlId="customer_service_fee_value">
                <Form.Label>Customer Service Fee Value</Form.Label>
                <InputGroup>
                  <div className="input-group-append">
                    <span className="input-group-text">R</span>
                  </div>
                  <Form.Control
                    style={{ textAlign: 'right' }}
                    className={errors.customer_service_fee_value?.message ? 'is-invalid' : ''}
                    required
                    type="text"
                    defaultValue={Number(data.customer_service_fee_value).toFixed(2)}
                    {...register('customer_service_fee_value', { required: true })}
                  />
                </InputGroup>
                <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                  {errors.customer_service_fee_value?.message ? 'Service Fee Type is required' : null}
                </div>
              </Form.Group>

              <Form.Group as={Col} md="6" className="col" controlId="commission_percentage">
                <Form.Label>Commission Percentage</Form.Label>
                <InputGroup>
                  <Form.Control
                    style={{ textAlign: 'right' }}
                    className={errors.commission_percentage?.message ? 'is-invalid' : ''}
                    required
                    type="text"
                    defaultValue={Number(data.commission_percentage).toFixed(2)}
                    {...register('commission_percentage', { required: true })}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">%</span>
                  </div>
                </InputGroup>
              </Form.Group>
            </Row>

            <Row>
              <Form.Group className="col mb-3" controlId="effective_start">
                <Form.Label>Effective start date</Form.Label>
                <Controller
                  control={control}
                  name={'effective_start'}
                  defaultValue={effectiveStartDate}
                  render={({ field }) => (
                    <DatePicker
                      selected={effectiveStartDate}
                      placeholderText="Select date"
                      onChange={(date) => {
                        setEffectiveStartDate(date!);
                        field.onChange(date);
                      }}
                      timeInputLabel="Time:"
                      dateFormat="yyyy/MM/dd h:mm aa"
                      showTimeInput
                      className={errors.effective_start?.message ? 'is-invalid no-border' : 'no-border'}
                    />
                  )}
                />
                <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                  {errors.effective_start?.message ? 'Effective start date is required' : null}
                </div>
              </Form.Group>
            </Row>

            <Row>
              <Form.Group className="col mb-3" controlId="effective_end">
                <Form.Label>Effective end date</Form.Label>
                <Controller
                  control={control}
                  name={'effective_end'}
                  defaultValue={effectiveEndDate}
                  render={({ field }) => (
                    <DatePicker
                      selected={effectiveEndDate}
                      placeholderText="Select date"
                      onChange={(date) => {
                        setEffectiveEndDate(date!);
                        field.onChange(date);
                      }}
                      timeInputLabel="Time:"
                      dateFormat="yyyy/MM/dd h:mm aa"
                      showTimeInput
                      className={errors.effective_end?.message ? 'is-invalid no-border' : 'no-border'}
                    />
                  )}
                />
                <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                  {errors.effective_start?.message ? 'Effective start date is required' : null}
                </div>
              </Form.Group>
            </Row>

            <div className="d-flex justify-content-end">
              <Button onClick={backEvent} variant="outline-secondary" className="me-2">
                Back
              </Button>

              <Button type="submit" disabled={saving || !isValid}>
                {saving ? <Spinner size="sm" /> : 'Continue'}
              </Button>
            </div>
          </Form>
        </div>
      ) : null}
    </div>
  );
};

export default FeesStep;
