/* eslint-disable indent */
import { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';

import OverflowMenu from '../../components/OverflowMenu';
import Spinner from '../../components/Spinner';
import { fetchAllProductsByInstitutionId, fetchTransactionTypes, Product } from '../../api';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { fetchAllProductsByConfigurationId, fetchAllProducts } from '../../api';
import { TransactionType } from '../../@types/transaction_type';
import { debounceTime, distinctUntilChanged, map, Subject } from 'rxjs';

interface LocationState {
  hash: string;
  key: string;
  pathname: string;
  search: string;
  state: { institutionId?: number | null };
}

const ConfigurationProducts = () => {
  const { configurationId } = useParams();
  const state = useLocation() as LocationState;

  const [data, setData] = useState<Product[] | null>(null);
  const [allData, setAllData] = useState<Product[] | null>(null);
  const [transactionTypeData, setTransactionTypeData] = useState<TransactionType[]>([]);
  const [transactionTypeId, setTransactionTypeId] = useState<number>();

  const searchString$ = new Subject<string>();

  useEffect(() => {
    if (state.state && state.state.institutionId) {
      fetchAllProductsByInstitutionId(state.state.institutionId).then((data) => {
        setData(data);
        setAllData(data);
      });
    } else if (configurationId && !state.state) {
      console.log('i get here');
      fetchAllProductsByConfigurationId(+configurationId!, '').then((data) => {
        console.log('then here');
        console.log(data);
        setData(data);
        setAllData(data);
      });
    } else if (!configurationId && !state.state) {
      fetchAllProducts('').then((data) => {
        setData(data);
        setAllData(data);
      });
    }
  }, [configurationId, state]);

  useEffect(() => {
    searchString$
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        map((val) => {
          if (configurationId) {
            fetchAllProductsByConfigurationId(+configurationId!, val).then((data) => {
              setData(data);
              setAllData(data);
            });
          } else {
            fetchAllProducts(val).then((data) => {
              setData(data);
              setAllData(data);
            });
          }
        })
      )
      .subscribe();

    return () => searchString$.complete();
  });

  const handleSearch = (searchString: string) => searchString$.next(searchString);

  const clearInstitutionFilter = () => {
    state.state.institutionId = null;
    setTransactionTypeId(undefined);
    setData(null);
    configurationId
      ? fetchAllProductsByConfigurationId(+configurationId!, '').then((data) => {
          setData(data);
          setAllData(data);
        })
      : fetchAllProducts('').then((data) => {
          setData(data);
          setAllData(data);
        });

    fetchTransactionTypes()
      .then((transactionTypeData) => setTransactionTypeData(transactionTypeData))
      .catch((error) => console.log(error));
  };

  const columns = [
    { name: 'ID', selector: (row: Product) => row.id ?? '', sortable: true, maxWidth: '40px' },
    {
      name: 'Product',
      selector: (row: Product) => row.display_name ?? '',
      sortable: true,
    },
    {
      name: 'Provider',
      selector: (row: Product) => row.provider?.display_name ?? '',
      sortable: true,
    },
    {
      name: 'Company',
      selector: (row: Product) => row.configuration?.company?.name ?? '',
      sortable: true,
    },
    {
      name: 'Channel',
      selector: (row: Product) => row.configuration?.channel?.name ?? '',
      sortable: true,
    },
    {
      name: 'Transaction Type',
      selector: (row: Product) => row.transaction_type?.name ?? '',
      sortable: true,
    },

    {
      sortable: false,
      button: true,
      cell: (row: Product) => <OverflowMenu type="configuration-products" id={row.id} />,
    },
  ];

  return (
    <div className="ms-content-wrapper">
      <div className="ms-panel">
        <div className="ms-panel-header d-flex justify-content-between align-items-center">
          <h6>Products</h6>

          {configurationId ? (
            <Link to={`/configuration-products/${configurationId}/create`}>
              <Button variant="primary">Add</Button>
            </Link>
          ) : null}
        </div>
        <div className="ms-panel-body">
          {!data && <Spinner />}
          {data ? (
            <Fragment>
              <Row className="mb-0" style={{ maxWidth: '30%' }}>
                <InputGroup>
                  <Form.Control
                    placeholder="Filter Products"
                    type="text"
                    onChange={(e) => handleSearch(e.target.value)}
                  />
                </InputGroup>
              </Row>
              <div className="table-responsive datatables row">
                <DataTableExtensions {...{ columns, data }} export={false} print={false} filter={false}>
                  <DataTable columns={columns} data={data!} pagination responsive={true} highlightOnHover noHeader />
                </DataTableExtensions>
              </div>
            </Fragment>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ConfigurationProducts;
