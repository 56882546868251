import { Product } from '../@types/product';
import axios from '../utils/axios';

export type { Product } from '../@types/product';

export const fetchAll = async (queryString: string): Promise<Product[]> => {
  const response = await axios.get(`products?queryString=${queryString}`);
  return response.data;
};

export const fetchAllByConfigId = async (configurationId: number, queryString: string): Promise<Product[]> => {
  const response = await axios.get(`products/configurations/${configurationId}?queryString=${queryString}`);
  return response.data;
};

export const fetchAllByInstitutionId = async (institutionId: number): Promise<Product[]> => {
  const response = await axios.get(`products/institutions/${institutionId}`);
  return response.data;
};

export const fetchOne = async (id: number | undefined): Promise<Product> => {
  const response = await axios.get(`products/${id}`);
  return response.data;
};

export const updateOne = async (data: Product): Promise<Product> => {
  const response = await axios.put(`products/${data.id}`, data);
  return response.data;
};

export const insertOne = async (data: Product): Promise<Product> => {
  const response = await axios.post<Product>(`products`, data);
  return response.data;
};
