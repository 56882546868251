import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import { Line as LineChart } from 'react-chartjs-2';

const data_1 = [1800, 1600, 2300, 2800, 3600, 2900, 3000, 3800, 3600];
const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'];

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend);

const data = {
  labels: labels,
  datasets: [
    {
      label: 'Volume',
      borderColor: '#357ffa',
      pointBorderColor: '#357ffa',
      pointBackgroundColor: '#357ffa',
      pointHoverBackgroundColor: '#357ffa',
      pointHoverBorderColor: '#357ffa',
      pointBorderWidth: 1,
      pointHoverRadius: 8,
      pointHoverBorderWidth: 1,
      pointRadius: 3,
      fill: true,
      backgroundColor: 'rgba(53,127,250,0.3)',
      borderWidth: 2,
      tension: 0.1,
      data: data_1,
    },
  ],
};

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Realtime switching volume',
    },
  },
};

const Dashboard = () => {
  return (
    <div className="col-xl-12 col-lg-12 col-md-12 p-5">
      <div className="ms-panel">
        <div className="ms-panel-header">
          <h6>Dashboard</h6>
        </div>
        <div className="ms-panel-body">
          <iframe
            width="100%"
            height="700"
            src="https://datastudio.google.com/embed/reporting/63c575f5-fbb7-4e0f-af6a-f6585810f1e1/page/p_lvzh912dzc"
            style={{ border: '0' }}
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
