import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import OverflowMenu from '../../components/OverflowMenu';
import { fetchAllSwitches } from '../../api';
import Spinner from '../../components/Spinner';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { Switch } from '../../@types/switch';

const Switches = () => {
  const [data, setData] = useState<Array<Switch> | null>(null);

  useEffect(() => {
    fetchAllSwitches().then((data:Array<Switch> | null) => {
      setData(data);
    });
  }, []);

  const columns = [
    { name: 'ID', selector: (row: Switch) => row.id ?? '', sortable: true, maxWidth: '40px' },
    { name: 'Name', selector: (row: Switch) => row.name, sortable: true, maxWidth: '180px' },
    { sortable: false, button: true, cell: (row: Switch) => <OverflowMenu type="switch" id={row.id} /> },
  ];

  return (
    <div className="ms-content-wrapper">
      <div className="ms-panel">
        <div className="ms-panel-header d-flex justify-content-between align-items-center">
          <h6>Switches</h6>
          <Link to={`/switch/add`}>
            <Button variant="primary">Add</Button>
          </Link>
        </div>
        <div className="ms-panel-body">
          {!data && <Spinner />}
          {data && (
            <div className="table-responsive datatables row">
              <DataTableExtensions {...{ columns, data }} export={false} print={false}>
                <DataTable columns={columns} data={data} pagination responsive={true} highlightOnHover noHeader />
              </DataTableExtensions>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Switches;
