import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Form, Alert, InputGroup, Modal, Button, Col, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import Spinner from '../../components/Spinner';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { messageService } from '../../events/create.events';
import toastr from 'toastr';
import { Message, MessageCreateInput } from '../../@types/message';
import { errorMessageTextStyleOverride } from '../../@types/errorMessages';
import { createMessage, fetchAllMessageTypes, MessageType } from '../../api';

toastr.options = {
  closeButton: false,
  debug: false,
  newestOnTop: false,
  progressBar: true,
  positionClass: 'toast-top-left',
  preventDuplicates: false,
  toastClass: 'success',
  showEasing: 'swing',
  hideEasing: 'linear',
  showMethod: 'fadeIn',
  hideMethod: 'fadeOut',
};

const schema = yup
  .object({
    message_type_id: yup.number().required('Message Type is required'),
    message: yup.string().required('Message is required'),
    effective_start: yup.date().required('Display Name is required'),
    effective_end: yup.date().required('Display Name is required'),
  })
  .required();

const MessageModal = (props: {
  showMessage: boolean;
  setShowMessage: Dispatch<SetStateAction<boolean>>;
  product_id: number;
}) => {
  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<MessageCreateInput>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const [saving, setSaving] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [effectiveStartDate, setEffectiveStartDate] = useState<Date>();
  const [effectiveEndDate, setEffectiveEndDate] = useState<Date>();
  const [messageTypes, setMessageTypes] = useState<MessageType[]>();

  useEffect(() => {
    fetchAllMessageTypes().then((messageTypes) => setMessageTypes(messageTypes));
  }, []);

  const onKeyPress = (e: any) => {
    console.log(e);
    if (e.code === 'Enter') {
      console.log('enter is pressed');
      e.stopPropagation();
      return;
    }
  };

  const onCreateMessage = (formData: MessageCreateInput) => {
    setSaving(true);

    formData.effective_start = effectiveStartDate!;
    formData.effective_end = effectiveEndDate!;
    formData.product_id = props.product_id;
    console.log(formData);

    createMessage(formData)
      .then((response) => {
        if (!response) {
          setError('Something went wrong.');
          setSaving(false);
        } else if (response.status !== 200) {
          setError('Error: ' + response.status);
          setSaving(false);
        } else {
          // Close Modal!
          toastr.info('Message Created');
          setSaving(false);
          setError(null);
          reset({ message: '', message_type_id: undefined }, { keepValues: false, keepErrors: false });
          props.setShowMessage(false);
          messageService.sendMessage('newMessage');
        }
      })
      .catch((error: any) => {
        setSaving(false);
        setError('Error: ' + error.message);
      });
  };

  return (
    <Modal
      show={props.showMessage}
      onHide={() => {
        reset({ message: '', message_type_id: undefined }, { keepValues: false, keepErrors: false });
        props.setShowMessage(false);
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="bg-primary">
        <h3 className="modal-title has-icon text-white">Add New Message</h3>

        <button
          type="button"
          className="close"
          onClick={() => {
            reset({ message: '', message_type_id: undefined }, { keepValues: false, keepErrors: false });
            props.setShowMessage(false);
          }}
        >
          <span>×</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row className="mb-3">
            <Form.Group className="col mb-3" controlId="message_type_id">
              <Form.Label>Message Type</Form.Label>
              <Form.Select
                className={errors.message_type_id?.message ? 'is-invalid' : ''}
                aria-label="Select Message Type"
                defaultChecked={true}
                {...register('message_type_id', { required: true, valueAsNumber: true })}
              >
                <option defaultValue={undefined} value={undefined}>
                  Please select an option
                </option>
                {messageTypes?.map((messageType, index) => (
                  <option key={index} value={messageType.id}>
                    {messageType.name}{' '}
                  </option>
                ))}
              </Form.Select>
              <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                {errors.message_type_id?.message}
              </div>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} md="12" className="col" controlId="message">
              <Form.Label>Message</Form.Label>
              <InputGroup>
                <Form.Control
                  onKeyDown={(e) => onKeyPress(e)}
                  as="textarea"
                  rows={6}
                  className={errors.message?.message ? 'is-invalid' : ''}
                  style={{ textAlign: 'left' }}
                  required
                  type="text"
                  {...register('message', { required: true })}
                />
              </InputGroup>
              <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                {errors.message?.message ? 'Message is required' : null}
              </div>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group className="col mb-3" controlId="effective_start">
              <Form.Label>Effective start date</Form.Label>
              <Controller
                control={control}
                name={'effective_start'}
                defaultValue={effectiveStartDate}
                render={({ field }) => (
                  <DatePicker
                    selected={effectiveStartDate}
                    placeholderText="Select date"
                    onChange={(date) => {
                      setEffectiveStartDate(date!);
                      field.onChange(date);
                    }}
                    timeInputLabel="Time:"
                    dateFormat="yyyy/MM/dd h:mm aa"
                    showTimeInput
                    className={errors.effective_start?.message ? 'is-invalid no-border' : 'no-border'}
                  />
                )}
              />
              <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                {errors.effective_start?.message ? 'Effective start date is required' : null}
              </div>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group className="col mb-3" controlId="effective_end">
              <Form.Label>Effective end date</Form.Label>
              <Controller
                control={control}
                name={'effective_end'}
                defaultValue={effectiveEndDate}
                render={({ field }) => (
                  <DatePicker
                    selected={effectiveEndDate}
                    placeholderText="Select date"
                    onChange={(date) => {
                      setEffectiveEndDate(date!);
                      field.onChange(date);
                    }}
                    timeInputLabel="Time:"
                    dateFormat="yyyy/MM/dd h:mm aa"
                    showTimeInput
                    className={errors.effective_end?.message ? 'is-invalid no-border' : 'no-border'}
                  />
                )}
              />
              <div className="invalid-feedback" style={errorMessageTextStyleOverride}>
                {errors.effective_start?.message ? 'Effective start date is required' : null}
              </div>
            </Form.Group>
          </Row>

          <div className="d-flex justify-content-end">
            <Button
              onClick={() => {
                reset({ message: '', message_type_id: undefined }, { keepValues: false, keepErrors: false });
                props.setShowMessage(false);
              }}
              variant="outline-secondary"
              className="me-2"
            >
              Back
            </Button>

            <Button onClick={handleSubmit(onCreateMessage)} disabled={saving || !isValid}>
              {saving ? <Spinner size="sm" /> : 'Continue'}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default MessageModal;
