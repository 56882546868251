import { AnimateLayoutChanges, defaultAnimateLayoutChanges, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dateFormat from 'dateformat';
import { useState } from 'react';
import { Button } from 'react-bootstrap';

import { useNavigate } from 'react-router-dom';
import MessageEditModal from '../pages/messages/MessageEditModal';

export function SortableItem(props: {
  id: number;
  name: string;
  effectiveTo: string;
  overlay: boolean;
  showSelect: boolean;
}) {
  const navigate = useNavigate();
  const [showEditMessage, setShowEditMessage] = useState<boolean>(false);
  const animateLayoutChanges: AnimateLayoutChanges = (args) =>
    defaultAnimateLayoutChanges({ ...args, wasDragging: true });
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: props.id,
    animateLayoutChanges: animateLayoutChanges,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 2 : 1,
    opacity: isDragging ? 0.3 : 1,
  };

  return (
    <div
      className={
        'sortable-config md="12" d-flex flex-column justify-content-between align-items-start' +
        (props.overlay ? ' overlay' : '')
      }
      ref={setNodeRef}
      style={style}
    >
      <h6 {...attributes} {...listeners}>
        <FontAwesomeIcon icon={solid('sort')} /> {props.name}
      </h6>
      <p style={{ color: '#3a3952' }}>Effective To: {dateFormat(props.effectiveTo)}</p>

      {props.showSelect ? (
        <Button size="sm" onClick={() => setShowEditMessage(true)} type="button">
          Edit Message
        </Button>
      ) : null}
      <MessageEditModal message_id={props.id!} showMessage={showEditMessage} setShowMessage={setShowEditMessage} />
    </div>
  );
}
