/* eslint-disable indent */
import { createContext, ReactNode, useEffect, useReducer } from 'react';
import { ActionMap, AuthState, JWTContextType } from '../@types/auth';
import { isValidToken, setSession } from '../utils/jwt';
import { User } from 'firebase/auth';
import { logout } from '../firebase';

enum Types {
  Initial = 'Initialize',
  Login = 'Login',
  Logout = 'Logout',
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: User | null;
  };
  [Types.Login]: {
    isAuthenticated: boolean;
    user: User;
  };
  [Types.Logout]: undefined;
};

export type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case Types.Initial:
      return {
        ...state,
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case Types.Login:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case Types.Logout:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

type AuthProviderProps = {
  children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          //TODO: what about the user?

          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: true,
              user: null, //TODO: we need a user to set on our state
            },
          });
        } else {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (user: User) => {
    dispatch({
      type: Types.Login,
      payload: {
        isAuthenticated: true,
        user: user,
      },
    });
  };

  const logoutCtx = async () => {
    setSession(null);
    dispatch({ type: Types.Logout });
    logout();
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout: logoutCtx,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
