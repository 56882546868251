import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

export type stepEventType = {
  key: string;
};

export const stepEvents = () => {
  const subject = new Subject<stepEventType>();

  const next = (e: stepEventType) => {
    subject.next(e);
  };

  const back = (e: stepEventType) => {
    subject.next(e);
  };

  const dataCreated = (e: stepEventType) => {
    subject.next(e);
  };

  const listen = (key: string) => subject.pipe(filter((e: stepEventType) => e.key === key));

  const complete = () => subject.complete();

  return { next, back, listen, dataCreated, complete };
};
