import React, { Fragment, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import Scrollbar from 'react-perfect-scrollbar';
import { Accordion, useAccordionButton, Nav } from 'react-bootstrap';
import logo from '../assets/img/pepkor_logo_white.png';

interface Props {
  children?: ReactNode;
  eventKey: string;
}

const CustomAccordionToggle = ({ children, eventKey }: Props) => {
  const decoratedOnClick = useAccordionButton(eventKey, () => {
    console.log('');
  });
  // TODO(Jacques): Move these styles out to stylesheet
  return (
    <div
      onClick={decoratedOnClick}
      style={{
        color: 'white',
        width: '100%',
        margin: 0,
        border: 0,
        borderColor: 'none',
        borderRadius: 0,
        backgroundColor: '#3a3952',
        textAlign: 'left',
      }}
    >
      {children}
    </div>
  );
};

const SideNav = () => {
  const navToggle = () => {
    document.getElementById('body')?.classList.toggle('ms-aside-left-open');
    document.getElementById('ms-side-nav')?.classList.toggle('ms-aside-open');
    document.getElementById('overlayleft')?.classList.toggle('d-block');
  };

  return (
    <Fragment>
      <div
        className="ms-aside-overlay ms-overlay-left ms-toggler"
        id="overlayleft"
        data-target="#ms-side-nav"
        data-toggle="slideLeft"
        onClick={navToggle}
      />
      <Scrollbar id="ms-side-nav" className="side-nav fixed ms-aside-scrollable ms-aside-left">
        <div className="logo-sn ms-d-block-lg">
          <Link className="pl-0 ml-0 text-center navbar-brand mr-0" to="/">
            <img src={logo} alt="logo" />{' '}
          </Link>
        </div>
        <Accordion defaultActiveKey="0" className="accordion ms-main-aside fs-14" id="side-nav-accordion">
          <li className="menu-item">
            <LinkContainer to="/">
              <Nav.Link>
                <i className="material-icons fs-16">home</i>Home
              </Nav.Link>
            </LinkContainer>
          </li>
          {/* <li className="menu-item">
            <LinkContainer to="/dashboard">
              <Nav.Link>
                <i className="material-icons fs-16">dashboard</i>Dashboard
              </Nav.Link>
            </LinkContainer>
          </li> */}
          <li className="menu-item">
            <Accordion defaultActiveKey="0">
              <CustomAccordionToggle eventKey="0">
                <Nav.Link className="has-chevron nav-link">
                  <i className="material-icons fs-16">tune</i>Setup
                </Nav.Link>
              </CustomAccordionToggle>
              <Accordion.Collapse eventKey="0" style={{ margin: 0, padding: 0 }}>
                <ul id="basic-elements" aria-labelledby="basic-elements" data-parent="#side-nav-accordion">
                  <li>
                    <LinkContainer to="/configurations">
                      <Nav.Link>
                        <span>Configurations</span>
                      </Nav.Link>
                    </LinkContainer>
                  </li>
                  <li>
                    <LinkContainer to="/configuration-products">
                      <Nav.Link>
                        <span>Products Config</span>{' '}
                      </Nav.Link>
                    </LinkContainer>
                  </li>
                  {/* <li>
                    <LinkContainer to="/channels">
                      <Nav.Link>
                        <span>Channel</span>
                      </Nav.Link>
                    </LinkContainer>
                  </li> */}
                  {/* <li>
                    <LinkContainer to="/products">
                      <Nav.Link>
                        <span>Product</span>
                      </Nav.Link>
                    </LinkContainer>
                  </li>
                  <li>
                    <LinkContainer to="/providers">
                      <Nav.Link>
                        <span>Provider</span>
                      </Nav.Link>
                    </LinkContainer>
                  </li>
                  <li>
                    <LinkContainer to="/switches">
                      <Nav.Link>
                        <span>Switch</span>
                      </Nav.Link>
                    </LinkContainer>
                  </li>
                  <li>
                    <LinkContainer to="/categories">
                      <Nav.Link>
                        <span>Category</span>
                      </Nav.Link>
                    </LinkContainer>
                  </li>
                  <li>
                    <LinkContainer to="/institutions">
                      <Nav.Link>
                        <span>Institution</span>
                      </Nav.Link>
                    </LinkContainer>
                  </li> */}

                  {/* <li>
                    <LinkContainer to="/configuration-institution-categories">
                      <Nav.Link><span>Configuration Institution Categories</span></Nav.Link>
                    </LinkContainer>
                  </li> */}
                </ul>
              </Accordion.Collapse>
            </Accordion>
          </li>
        </Accordion>
      </Scrollbar>
    </Fragment>
  );
};

export default SideNav;
