import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { Form, Alert, InputGroup, Modal, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Spinner from '../../components/Spinner';
import * as yup from 'yup';
import { fetchSwitch, Switch, updateSwitch } from '../../api';
import { yupResolver } from '@hookform/resolvers/yup';
import toastr from 'toastr';
import { messageService } from '../../events/create.events';

toastr.options = {
  closeButton: false,
  debug: false,
  newestOnTop: false,
  progressBar: true,
  positionClass: 'toast-top-left',
  preventDuplicates: false,
  toastClass: 'success',
  showEasing: 'swing',
  hideEasing: 'linear',
  showMethod: 'fadeIn',
  hideMethod: 'fadeOut',
};

type SwitchUpdateInput = {
  id: number;
  name: string;
};

const schema = yup
  .object({
    name: yup.string().required('Name is a required field'),
  })
  .required();

const SwitchModalEdit = (props: {
  showSwitch: boolean;
  setShowEditSwitch: Dispatch<SetStateAction<boolean>>;
  switchId: number;
}) => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<SwitchUpdateInput>({
    resolver: yupResolver(schema),
  });

  const [saving, setSaving] = useState<boolean>(false);
  const [switchData, setSwitchData] = useState<Switch>();
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    if (props.showSwitch) {
      fetchSwitch(props.switchId)
        .then((switchData) => setSwitchData(switchData))
        .catch((error) => setError(error));
    }
  }, [props.showSwitch, props.switchId]);

  const onEditSwitch = (formData: SwitchUpdateInput) => {
    setSaving(true);
    formData.id = props.switchId;
    console.log(formData);
    updateSwitch(formData)
      .then((response) => {
        if (!response) {
          setError('Something went wrong.');
          setSaving(false);
        } else if (response.status !== 200) {
          setError('Error: ' + response.status);
          setSaving(false);
        } else {
          // Close Modal!
          setSaving(false);
          messageService.sendMessage('switchUpdated');
          toastr.info('Switch Updated');
          reset({}, { keepDefaultValues: false, keepValues: false });
          setSwitchData(undefined);
          props.setShowEditSwitch(false);
        }
      })
      .catch((error: any) => {
        setSaving(false);
        setError('Error: ' + error.message);
      });
  };

  return (
    <Modal
      show={props.showSwitch}
      onHide={() => {
        reset({}, { keepDefaultValues: false, keepValues: false });
        setSwitchData(undefined);
        props.setShowEditSwitch(false);
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="bg-primary">
        <h3 className="modal-title has-icon text-white">Update Switch</h3>

        <button
          type="button"
          className="close"
          onClick={() => {
            reset({}, { keepDefaultValues: false, keepValues: false });
            setSwitchData(undefined);
            props.setShowEditSwitch(false);
          }}
        >
          <span>×</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        {!switchData && <Spinner />}
        {switchData ? (
          <Form>
            <Form.Group className="mb-3" controlId="id">
              <Form.Label>Id</Form.Label>
              <InputGroup>
                <Form.Control
                  defaultValue={props.switchId}
                  disabled={true}
                  type="number"
                  {...register('id', { required: true })}
                />
                <Form.Control.Feedback type="invalid">Id is required</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3" controlId="name">
              <Form.Label>Name</Form.Label>
              <InputGroup>
                <Form.Control
                  defaultValue={switchData?.name}
                  type="text"
                  placeholder="Switch Name"
                  {...register('name', { required: true })}
                />
                <Form.Control.Feedback type="invalid">Name is required</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Alert variant="danger" show={error != null}>
              {error}
            </Alert>
            <div className="d-flex justify-content-end">
              <Button
                type="button"
                className="btn btn-light"
                onClick={() => {
                  reset({}, { keepDefaultValues: false, keepValues: false });
                  setSwitchData(undefined);
                  props.setShowEditSwitch(false);
                }}
              >
                Cancel
              </Button>
              <Button onClick={handleSubmit(onEditSwitch)} className="btn btn-primary shadow-none">
                {saving ? <Spinner size="sm" /> : 'Save'}
              </Button>
            </div>
          </Form>
        ) : null}
      </Modal.Body>
    </Modal>
  );
};

export default SwitchModalEdit;
